import React from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { AIMessageStatus } from '../thread.constant';
import { AIMessage } from '../thread.type';
import { useTranslation } from 'react-i18next';

interface AiGenerateStatusProps {
  aiMsg: AIMessage;
  hideSuccess?: boolean;
}

const AiGenerateStatus: React.FC<AiGenerateStatusProps> = ({ aiMsg, hideSuccess }) => {
  const { t } = useTranslation();
  return (
    <>
      {
        aiMsg.status === AIMessageStatus.WAITING ? (
          <Chip
            // t:生成待ち
            label={t("thread:aiGenerateStatus.waiting")}
            color="secondary"
            size="small"
          />
        ) :
        aiMsg.status === AIMessageStatus.PROCESSING ? (
          <>
            <Chip
              // t:生成中
              label={t("thread:aiGenerateStatus.processing")}
              color="primary"
              size="small"
            />
            <CircularProgress size="1rem" />
          </>
        ) :
        aiMsg.status === AIMessageStatus.FINISHED ? (
          !hideSuccess && <Chip
            // t:生成完了
            label={t("thread:aiGenerateStatus.finished")}
            color="success"
            size="small"
          />
        ) :
        aiMsg.status === AIMessageStatus.FAILED ? (
          <Chip
            // t:生成失敗
            label={t("thread:aiGenerateStatus.failed")}
            color="error"
            size="small"
          />
        ) : (
          <Chip
            // t:不明
            label={t("thread:aiGenerateStatus.unknown")}
            color="warning"
            size="small"
          />
        )
      }
    </>
  );
};

export default AiGenerateStatus;

import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { ReqScheduleLicenseChange } from '../subscription.type';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';
import { getPlanTypeLabel } from '@/components/features/subscription/subscription.constant';

const schema = z.object({
  licenseAmount: z.number().min(0, "0以上の数値を入力してください").int(),
  applyStartedOn: z.string().refine((date) => new Date(date) >= new Date(), "明日以降の日付を選択してください"),
  memo: z.string().max(5000).optional(),
});

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: ReqScheduleLicenseChange) => void;
}

const SvSubscriptionLicenseFormModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  const {t} = useTranslation();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  const currentSub = team?.currentSubscription;
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      licenseAmount: currentSub?.latestLicense.amount || 0,
      applyStartedOn: currentSub?.latestContractTerm[1] || "",
      memo: "",
    },
  });

  if (!(team && currentSub)) {
    return null;
  }

  const onFormSubmit = async (data: ReqScheduleLicenseChange) => {
    onSubmit(data);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* t:ライセンス数変更 */}
      <DialogTitle>{t("subscription:subscriptionChange.licenseChange")}</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ mb: 5 }}>
          <Typography variant="h6" gutterBottom>
            {/* t:現在の内容 */}
            {t("subscription:subscriptionDetail.currentContent")}
          </Typography>
          <Table style={{ width: 600 }}>
            <TableHead>
              <TableRow>
                {/* t:プラン */}
                <TableCell>{t("subscription:currentSubscription.plan")}</TableCell>
                {/* t:期間 */}
                <TableCell>{t("subscription:currentSubscription.period")}</TableCell>
                {/* t:ライセンス数 */}
                <TableCell>{t("subscription:currentSubscription.licenseCount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {getPlanTypeLabel(t, currentSub.plan.type)}
                  {!!team.scheduledSubscription && (
                    <Typography variant="caption" color="error" component="span">
                      {/* t:(変更予約あり) */}
                      {t("subscription:subscriptionDetail.changeScheduledNote")}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
                </TableCell>
                <TableCell>
                  {currentSub.currentLicense?.amount}
                  {!!currentSub.scheduledLicense && (
                    <Typography variant="caption" color="error" component="span">
                      {/* t:(変更予約あり) */}
                      {t("subscription:subscriptionDetail.changeScheduledNote")}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Typography variant="h6" gutterBottom>
            {/* t:変更内容 */}
            {t("subscription:quotaCustomization.changeContent")}
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.licenseAmount)}>
            <WaTextField
              type="number"
              // t:ライセンス数
              label={t('subscription:currentSubscription.licenseCount')}
              {...register('licenseAmount', { valueAsNumber: true })}
              error={!!errors.licenseAmount}
              helperText={errors.licenseAmount?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.applyStartedOn)}>
            <WaTextField
              // t:適用開始日
              label={t('subscription:subscriptionChange.applyStartDate')}
              type="date"
              {...register('applyStartedOn')}
              error={!!errors.applyStartedOn}
              helperText={errors.applyStartedOn?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.memo)}>
            <WaTextField
              // t:メモ
              label={t('subscription:subscriptionChange.memo')}
              multiline
              rows={4}
              {...register('memo')}
              error={!!errors.memo}
              helperText={errors.memo?.message}
            />
          </FormControl>
          <DialogActions>
            {/* t:キャンセル */}
            <Button onClick={handleClose}>{t('common:button.cancel')}</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {/* t:保存 */}
              {t('common:button.save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SvSubscriptionLicenseFormModal;

import { TFunction } from 'i18next';

export enum DlpPreventType {
  REPLACE = 'replace',
  PROHIBIT = 'prohibit',
  WARN = 'warn',
}
export const getDlpPreventTypeLabel = (t: TFunction, preventType: DlpPreventType): string => {
  switch (preventType) {
    case DlpPreventType.REPLACE:
      // t:置換
      return t("dlp:preventType.replace");
    case DlpPreventType.PROHIBIT:
      // t:禁止
      return t("dlp:preventType.prohibit");
    case DlpPreventType.WARN:
      // t:警告
      return t("dlp:preventType.warn");
    default:
      return '';
  }
};

export enum DlpCheckSpeedType {
  FAST = 'fast',
  NORMAL = 'normal',
  SLOW = 'slow',
}

export const getDlpCheckSpeedTypeLabel = (t: TFunction, speedType: DlpCheckSpeedType): string => {
  switch (speedType) {
    case DlpCheckSpeedType.FAST:
      // t:高速
      return t("dlp:checkSpeedType.fast");
    case DlpCheckSpeedType.NORMAL:
      // t:標準
      return t("dlp:checkSpeedType.normal");
    case DlpCheckSpeedType.SLOW:
      // t:低速
      return t("dlp:checkSpeedType.slow");
    default:
      return '';
  }
};

import React from 'react';
import { Grid, Typography, Paper, useTheme, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ResReports } from '../report.api';
import { useTranslation } from 'react-i18next';

interface Props {
  report: ResReports;
}

const ReportSummary: React.FC<Props> = ({ report }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const sections = [
    {
      // t:利用回数
      title: t('reports:summary.usageCount.title'),
      items: [
        // t:スレッド作成回数
        { label: t('reports:summary.usageCount.threadCreation'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.threadNum, 0)), unit: t('reports:units.count') },
        // t:メッセージ投稿回数
        { label: t('reports:summary.usageCount.messagePost'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.postCount, 0)), unit: t('reports:units.count') },
        // t:AIからの回答回数
        { label: t('reports:summary.usageCount.aiResponse'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.aiGenerateCount, 0)), unit: t('reports:units.count') },
      ],
    },
    {
      // t:クレジット数
      title: t('reports:summary.credits.title'),
      items: [
        // t:合計
        { label: t('reports:common.total'), value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.totalCredit), 0) * 10) / 10), unit: '' },
        // t:入力のみ
        { label: t('reports:common.inputOnly'), value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.inputCredit), 0) * 10) / 10), unit: '' },
        // t:出力のみ
        { label: t('reports:common.outputOnly'), value: formatNumber(Math.floor(report.teamUsageReports.reduce((sum, r) => sum + Number(r.outputCredit), 0) * 10) / 10), unit: '' },
      ],
    },
    {
      // t:文字数
      title: t('reports:summary.characters.title'),
      items: [
        // t:合計
        { label: t('reports:common.total'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputCharsInView + r.outputChars, 0)), unit: t('reports:units.characters') },
        // t:入力のみ
        { label: t('reports:common.inputOnly'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputCharsInView, 0)), unit: t('reports:units.characters') },
        // t:出力のみ
        { label: t('reports:common.outputOnly'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.outputChars, 0)), unit: t('reports:units.characters') },
      ],
    },
    {
      // t:添付ファイル数
      title: t('reports:summary.attachments.title'),
      items: [
        // t:画像
        { label: t('reports:summary.attachments.image'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputImageCountInView, 0)), unit: t('reports:units.times') },
        // t:音声
        { label: t('reports:summary.attachments.audio'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputAudioCountInView, 0)), unit: t('reports:units.times') },
        // t:動画
        { label: t('reports:summary.attachments.video'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputVideoCountInView, 0)), unit: t('reports:units.times') },
        // t:PDF
        { label: t('reports:summary.attachments.pdf'), value: formatNumber(report.teamUsageReports.reduce((sum, r) => sum + r.inputDocumentCountInView, 0)), unit: t('reports:units.times') },
      ],
    },
  ];

  return (
    <>
      <Grid container spacing={4} sx={{mb: 3}}>
        <Grid item xs={12} sm={12} md={6} lg={3} >
          <Paper sx={{
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">
                  {/* t:利用メンバー数 */}
                  {t('reports:summary.activeMembers')}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'right', paddingRight:'15px'}}>
                <Typography variant="h6">
                  {/* t:{memberNum} 人 */}
                  {t('reports:summary.activeMembersCount', { memberNum: report.uniqueMembershipNum })}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {sections.map((section, sectionIndex) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={`section-${sectionIndex}`}>
            <Paper elevation={3} sx={{ padding: theme.spacing(3), height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                {section.title}
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    {section.items.map((item, itemIndex) => (
                      <TableRow key={`${sectionIndex}-${itemIndex}`}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell align="right">
                          {item.value} {item.unit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ReportSummary;

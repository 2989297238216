import { TFunction } from "i18next";

export enum PlanType {
  STARTER = 'starter',
  LITE = 'lite',
  BUSINESS = 'business',
  ENTERPRISE = 'enterprise',
  STOPPED = 'stopped',
  DELETED = 'deleted'
}

export const getPlanTypeLabel = (t: TFunction, planType: PlanType): string => {
  switch (planType) {
    case PlanType.STARTER:
      // t:スタータープラン
      return t('subscription:plan.starter');
    case PlanType.LITE:
      // t:ライトプラン
      return t('subscription:plan.lite');
    case PlanType.BUSINESS:
      // t:ビジネスプラン
      return t('subscription:plan.business');
    case PlanType.ENTERPRISE:
      // t:エンタープライズプラン
      return t('subscription:plan.enterprise');
    case PlanType.STOPPED:
      // t:利用停止
      return t('subscription:plan.stopped');
    case PlanType.DELETED:
      // t:削除
      return t('subscription:plan.deleted');
    default:
      return '';
  }
};

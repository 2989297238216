import React, { useEffect } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import LoginPresenter from '../presenter/LoginPresenter';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../auth.slice';
import { RootState } from '@/store';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';

const clientId : string = import.meta.env.VITE_GOOGLE_CLIENT_ID;

const LoginContainerCore: React.FC = () => {
  const dispatch = useDispatch();
  const { setErrorModal } = useErrorModal();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // APIサーバーに認証コードを渡してアクセス
        // このAPIは認証コードを受け取って、GoogleのAPIにアクセスしてユーザー情報を取得する
        console.debug("codeResponse:", codeResponse );
        const authCode = codeResponse.code;
        const response = await apiAxios.post('/auth/login', null, {
            params: {
                auth_code: authCode,
                language: i18n.language,
            },
        })

        console.debug("response:",response)
        dispatch(setUserInfo(response.data));
        if (response.data.language) {
          i18n.changeLanguage(response.data.language);
        }
      } catch (error) {
        captureException(error);
        setErrorModal(
          // t:ログインエラー
          t("auth:loginError"),
          // t:ログインに失敗しました。しばらく時間をおいて再度お試しください。
          t("auth:loginFailedTryAgainLater")
        );
        console.error('login error:', error);
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.error('login onError:', error);
      const errorDetails = new Error(`Error: ${error.error}, Description: ${error.error_description}, URI: ${error.error_uri}`);
      captureException(errorDetails);
      setErrorModal(
        // t:ログインエラー
        t("auth:loginError"),
        // t:ログインに失敗しました。しばらく時間をおいて再度お試しください。
        t("auth:loginFailedTryAgainLater")
      );
      setIsLoading(false);
    },
    onNonOAuthError: (error) => {
      console.error('login onNonOAuthError:', error);
      setIsLoading(false);
    },
    flow: "auth-code",
    scope: "email profile openid",
  });

  const handleLogin = () => {
    setIsLoading(true);
    login();
  };

  return (
    <LoginPresenter login={handleLogin} />
  );
};

const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const redirectUrl = useSelector((state: RootState) => state.auth.redirectUrl);
  const { setIsLoading } = useLoading();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  // ログイン済みの場合はトップページへ
  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl || '/');
    } else {
      const checkAuth = async () => {
        setIsLoading(true);
        try {
          const response = await apiAxios.get('/auth/profile');
          dispatch(setUserInfo(response.data));
          if (response.data.language) {
            i18n.changeLanguage(response.data.language);
          }
          navigate(redirectUrl || '/');
        } catch (error) {
          console.debug('/auth/profile error', error)
        } finally {
          setIsLoading(false);
        }
      };
      checkAuth();
    }
  }, [isAuthenticated, navigate]);

  // GoogleOAuthProviderを使用して、GoogleのOAuth認証を利用するための設定を行います。
  // これにより、内部のLoginContainerCoreコンポーネントでuseGoogleLoginを使用できるようになります。
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <LoginContainerCore />
    </GoogleOAuthProvider>
  );

};

export default LoginContainer;

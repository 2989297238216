import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '@/store';
import { createNews } from '../news.slice';
import SvNewsForm from '../components/SvNewsForm';
import { NewsInput } from '../news.type';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { Divider } from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { convertStrToLanguage } from '@/components/features/setting/setting.utils';

const SvNewsCreateContainer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (data: NewsInput) => {
    await dispatch(createNews(data));
    navigate('/supervisor/news');
  };

  return (
    <>
      {/* t:お知らせ新規作成 */}
      <SupervisorPageTitle title={t("news:supervisor.create")} />
      <Divider />
      <SupervisorContentsWrapper id="scrollableDiv">
        <SvNewsForm onSubmit={handleSubmit} defaultValues={{
          title: '',
          type: 'internal',
          language: convertStrToLanguage(i18n.language),
          publishedAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
          body: '',
          externalUrl: '',
          isImportant: false,
          isHidden: false,
        }}/>
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvNewsCreateContainer;

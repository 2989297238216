import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Box, Divider, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useTranslation } from 'react-i18next';
import { displayTimestamp } from '@/lib/utils';

const ProfileSetting: React.FC = () => {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  if (!loginUser || !membership) {
    return null;
  }

  return <>
    {/* t:ユーザー情報 */}
    <AdminPageTitle title={t("setting:profile.pageTitle")} />
    <Divider />
    <AdminContentsWrapper>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
        {/* t:登録されているユーザー情報を確認できます。 */}
        {t("setting:profile.pageDescription")}
      </Typography>

      <Box mb={4} ml={1.5}>
        <Typography variant="caption" gutterBottom>
          {/* t:メンバーID */}
          {t("setting:profile.column.memberId")}
        </Typography>
        <Typography variant="body1">{membership.id}</Typography>
        <br/>

        <Typography variant="caption" gutterBottom>
          {/* t:氏名 */}
          {t("setting:profile.column.userName")}
        </Typography>
        <Typography variant="body1">{loginUser.name}</Typography>
        <br/>

        <Typography variant="caption" gutterBottom>
          {/* t:メールアドレス */}
          {t("setting:profile.column.email")}
        </Typography>
        <Typography variant="body1">{loginUser.email}</Typography>
        <br/>

        <Typography variant="caption" gutterBottom>
          {/* t:登録日時 */}
          {t("setting:profile.column.createdAt")}
        </Typography>
        <Typography variant="body1">{displayTimestamp(membership.createdAt)}</Typography>
        <br/>
      </Box>
    </AdminContentsWrapper>
  </>;
}

export default ProfileSetting;
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getAllQuickPrompts, RecommendedQp } from '../quickPrompt.type';
import RecommendQpListPresenter from '../presenters/RecommendQpListPresenter';
import { getUiFlagValue } from '../../auth/auth.type';
import { RECOMMEND_QP_INFO_LIST, UI_FLAG_QP_TUTORIAL_STATE_EKY, UI_FLAG_QP_TUTORIAL_STATE_VAL_DONE } from '../quickPrompt.constant';
import { useTranslation } from 'react-i18next';
import { convertStrToLanguage } from '../../setting/setting.utils';
import { Language } from '../../setting/setting.constant';

const RecommendQpListContainer: React.FC = () => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const quickPromptSets = useSelector((state: RootState) => state.quickPrompt.quickPromptSets);
  const { t, i18n } = useTranslation();
  const lang = convertStrToLanguage(i18n.language);

  if (!(loginUser && quickPromptSets)) return <></>;

  const quickPrompts = getAllQuickPrompts(quickPromptSets);


  const recommendedQps: RecommendedQp[] = RECOMMEND_QP_INFO_LIST.reduce((acc: RecommendedQp[], item) => {
    const officialCode = item.code + (lang === Language.EN ? "_en" : "");
    const matchingQp = quickPrompts.find(qp => qp.officialCode === officialCode);
    if (matchingQp) {
      acc.push({
        qp: matchingQp,
        color: item.color,
        description: item.descT(t)
      });
    }
    return acc;
  }, []);

  const qpTutorial = getUiFlagValue(loginUser, UI_FLAG_QP_TUTORIAL_STATE_EKY);
  const qpTutorialDone = qpTutorial === UI_FLAG_QP_TUTORIAL_STATE_VAL_DONE;


  return <RecommendQpListPresenter
    isTutorialDone={qpTutorialDone}
    recommendedQpList={recommendedQps}
  />;
}

export default RecommendQpListContainer;

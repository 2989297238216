export enum Language {
  JA = 'ja',
  EN = 'en',
}

export enum AILanguage {
  JA = 'ja',
  EN = 'en',
  OTHER = 'other',
}

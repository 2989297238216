import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  open: boolean;
  title: string | ReactNode;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  buttonLabel?: string;
  onClose: () => void;
  children: React.ReactNode;
  additionalActions?: React.ReactNode;
};

const CommonModal: React.FC<ModalProps> = ({ open, title, onClose, children, maxWidth, fullWidth, buttonLabel, additionalActions}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth ?? "md"} fullWidth={fullWidth ?? false}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {additionalActions}
        {/* 閉じる */}
        <Button onClick={onClose}>{buttonLabel ?? t("common:button.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default CommonModal;

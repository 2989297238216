import { z } from '@/lib/zod';
import i18next from 'i18next';

export const newsInputSchema = z.object({
  id: z.string().nullable().optional(),
  title: z.string().min(1),
  type: z.enum(["internal", "external"]),
  language: z.enum(["en", "ja"]),
  publishedAt: z.string(),
  body: z.string().nullable().optional(),
  externalUrl: z.string().url().nullable().optional().or(z.literal('')),
  isImportant: z.boolean().optional().or(z.literal(false)),
  isHidden: z.boolean().optional().or(z.literal(false)),
}).superRefine((data, ctx) => {
  if (data.type === "internal" && !data.body) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:必須
      message: i18next.t('common:validation.required'),
      path: ["body"],
    });
  }
  if (data.type === "external" && !data.externalUrl) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:必須
      message: i18next.t('common:validation.required'),
      path: ["externalUrl"],
    });
  }
});

export type NewsInput = z.infer<typeof newsInputSchema>

export interface News extends NewsInput {
  id: string;
  createdAt: string;
}

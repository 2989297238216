import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemText} from '@mui/material';
import { useTranslation } from 'react-i18next';

const SupervisorLeftSidebar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <List component="div" disablePadding>
      <NavLink to="/supervisor/teams">
        <ListItemButton>
          {/* t:チーム管理 */}
          <ListItemText primary={t("supervisor:sidebar.teamManagement")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/supervisor/news">
        <ListItemButton>
          {/* t:お知らせ管理 */}
          <ListItemText primary={t("supervisor:sidebar.newsManagement")} />
        </ListItemButton>
      </NavLink>
    </List>
  );
};

export default SupervisorLeftSidebar;

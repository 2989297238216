import React, { useState } from 'react';
import { MessageNode } from '../thread.type';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { AIMessageStatus, MessageType } from '../thread.constant';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import theme from '@/theme';
import ThreadAiPostCompare from '../components/ThreadAiPostCompare';
import ThreadPostAiMessage from './ThreadPostAiMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  node: MessageNode;
}


const MultiAIHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: min(1000px, 100%);
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;

  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #ddd;

  ${theme.breakpoints.down('md')} {
    display: none;
  }
`;
const ThreadPostAiMessages: React.FC<Props> = ({node}) => {
  const { t } = useTranslation();
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const [ openCompare, setOpenCompare ] = useState(false);

  if (!currentThread) return <></>;
  if (node.type != MessageType.AI) return <></>;

  const totalNum = node.aiMessages.length;
  const finishedNum = node.aiMessages.filter((msg) => msg.status === AIMessageStatus.FINISHED).length;
  const failedNum = node.aiMessages.filter((msg) => msg.status === AIMessageStatus.FAILED).length;

  return (
    <>
      {node.aiMessages.length > 1 &&
        <MultiAIHeader>
          <Typography variant="body2" color="primary" sx={{ mr: 2 }}>
            {/* t:回答状況 */}
            {t("thread:threadAiPostCompare.title")}
            &nbsp;&nbsp;{finishedNum}&nbsp;&nbsp;/&nbsp;&nbsp;{totalNum}&nbsp;&nbsp;
            {/* (失敗：{{failedNum}}) */}
            {failedNum > 0 ? t('thread:threadAiPostCompare.failedCount', {failedNum}) : ''}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {setOpenCompare(true)}}
          >
            {/* t:回答を並べて見る */}
            {t("thread:threadAiPostCompare.compare")}
          </Button>
          <ThreadAiPostCompare
            open={openCompare}
            onClose={() => {setOpenCompare(false)}}
            messageNode={node}
            aiMessages={node.aiMessages}
          />
        </MultiAIHeader>
      }
      {node.aiMessages.map((aiMsg) => <ThreadPostAiMessage
        key={aiMsg.id}
        node={node}
        aiMessage={aiMsg}
      />)}
    </>
  );
}

export default ThreadPostAiMessages;

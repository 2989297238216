
// Start of Selection
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SvTeamCreate as SvTeamCreate, SvTeamUpdate as SvTeamUpdate, SvTeam as SvTeam, SvTeamDetail as SvTeamDetail } from './team.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showErrorModal, showUnknownErrorModal } from '../../generic/errorModal.slice';
import { AxiosError } from 'axios';
import { showToast } from '../../generic/toast.slice';
import i18next from 'i18next';

interface SvTeamState {
  teams: SvTeam[];
  currentTeam: SvTeamDetail | null;
}

const initialState: SvTeamState = {
  teams: [],
  currentTeam: null,
};

export const createSvTeam = createAsyncThunk(
  'svTeam/createTeam',
  async (data: SvTeamCreate, { rejectWithValue, dispatch }) => {

    try {
      const response = await apiAxios.post<SvTeam>('/supervisor/teams', data);
      // t:チーム「{{name}}」を作成しました
      dispatch(showToast({message: i18next.t("teams:teamSlice.createSuccess", { name: data.name }), severity: 'success'}));
      return response.data;
    } catch (err) {
      console.debug("/teams error", err)
      captureException(err);

      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      // t:予期せぬエラーが発生しました
      return rejectWithValue(i18next.t('teams:teamSlice.errors.unknown'));
    }
  }
);

export const updateSvTeam = createAsyncThunk(
  'svTeam/updateTeam',
  async (data: SvTeamUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put<SvTeam>(`/supervisor/teams/${data.id}`, data);
      // t:チーム情報を更新しました
      dispatch(showToast({message: i18next.t("teams:teamSlice.updateSuccess"), severity: 'success'}));
      return response.data;
    } catch (err) {
      captureException(err);
      console.debug(err)

      if (err instanceof AxiosError) {
        const code = err.response?.data.errorCode;
        let title : string | undefined = undefined;
        let message : string | undefined = undefined;
        switch (code) {
          case "team_not_found":
            // t:チームが見つかりません
            title = i18next.t("teams:teamSlice.errors.teamNotFound.title");
            // t:指定されたチームは存在しません。
            message = i18next.t("teams:teamSlice.errors.teamNotFound.message");
            break;
          default:
            break;
        }
        if (title && message) {
          dispatch(showErrorModal({title, message}));
        } else {
          dispatch(showUnknownErrorModal());
        }
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      // t:予期せぬエラーが発生しました
      return rejectWithValue(i18next.t('teams:teamSlice.errors.unknown'));
    }
  }
);

export const deleteSvTeam = createAsyncThunk(
  'svTeam/deleteTeam',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/supervisor/teams/${id}`);
      // t:チームを削除しました
      dispatch(showToast({message: i18next.t("teams:teamSlice.deleteSuccess"), severity: 'success'}));
      return id;
    } catch (err) {
      captureException(err);

      if (err instanceof AxiosError) {
        const code = err.response?.data.errorCode;
        let title : string | undefined = undefined;
        let message : string | undefined = undefined;
        switch (code) {
          case "team_not_found":
            // t:チームが見つかりません
            title = i18next.t("teams:teamSlice.errors.teamNotFound.title");
            // t:指定されたチームは存在しません。
            message = i18next.t("teams:teamSlice.errors.teamNotFound.message");
            break;
          default:
            break;
        }
        if (title && message) {
          dispatch(showErrorModal({title, message}));
        } else {
          dispatch(showUnknownErrorModal());
        }
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      // t:予期せぬエラーが発生しました
      return rejectWithValue(i18next.t('teams:teamSlice.errors.unknown'));
    }
  }
);

const svTeamSlice = createSlice({
  name: 'svTeam',
  initialState,
  reducers: {
    setSvTeams: (state, action: PayloadAction<SvTeam[]>) => {
      state.teams = action.payload;
    },
    appendSvTeams: (state, action: PayloadAction<SvTeam[]>) => {
      // idが同じものは追加しない
      action.payload.forEach((team) => {
        if (!state.teams.some((t) => t.id === team.id)) {
          state.teams.push(team);
        }
      });
    },
    setCurrentTeam: (state, action: PayloadAction<SvTeamDetail>) => {
      state.currentTeam = action.payload;
    },
    clearCurrentTeam: (state) => {
      state.currentTeam = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSvTeam.fulfilled, (state, action) => {
      const index = state.teams.findIndex(team => team.id === action.payload.id);
      if (index !== -1) {
        state.teams[index] = action.payload;
      } else {
        state.teams.unshift(action.payload);
      }
    });
    builder.addCase(updateSvTeam.fulfilled, (state, action) => {
      const index = state.teams.findIndex(team => team.id === action.payload.id);
      if (index !== -1) {
        state.teams[index] = action.payload;
      }
      if (state.currentTeam && state.currentTeam.id === action.payload.id) {
        state.currentTeam = {...state.currentTeam, ...action.payload};
      }
    });
    builder.addCase(deleteSvTeam.fulfilled, (state, action) => {
      state.teams = state.teams.filter(team => team.id !== action.payload);
    });
  },
});

export const {
  setSvTeams: setSvTeams,
  appendSvTeams: appendSvTeams,
  setCurrentTeam,
  clearCurrentTeam,
} = svTeamSlice.actions;

export default svTeamSlice.reducer;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/store';
import InfoPanel from '@/components/ui/InfoPanel';
import { useTranslation } from 'react-i18next';

const TeamNotJoined: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (loginUser) {
      // チームに所属してない場合はチーム作成へ
      if (!loginUser.memberships || loginUser.memberships.length === 0) {
        navigate('/teams/create');
      }

      // チームが複数あり、１つでもisJoined=Trueがある場合は、チーム選択へ
      if (loginUser.memberships.length > 1 && loginUser.memberships.some((m) => m.isJoined)) {
        navigate('/teams/select');
      }
    }
  }, [loginUser, navigate]);

  return (
    <InfoPanel
      // t:チームへの参加が必要です
      title={t("team:notJoined.title")}
    >
      {/* t:管理者から招待メールが届いています。そちらから参加してください。 */}
      {t("team:notJoined.message")}
    </InfoPanel>
  );
};

export default TeamNotJoined;

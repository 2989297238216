import React from "react";
import { Button, Typography, Link, List, ListItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styled from "@emotion/styled";
import { CenteredMainAreaWrapper, ContentsWrapper } from "@/components/ui/StyledComponents";
import theme from "@/theme";
import { Trans, useTranslation } from "react-i18next";
import SelectDisplayLanguage from "../../setting/containers/SelectDisplayLanguage";

const LoginPanel = styled(CenteredMainAreaWrapper)`
  justify-content: top;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

const LoginTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
`;

const LoginDescription = styled(Typography)`
  margin-bottom: 16px;
`;

const LoginButton = styled(Button)`
  margin-top: 24px;
`;

export const AlertTypography = styled(({ component, ...props }: { component: React.ElementType, [key: string]: any }) => <Typography component={component} {...props} />)`
  &&& {
    color: ${theme.palette.warning.main};
  }
`;

interface LoginContainerProps {
  login: () => void;
}

const LoginPresenter: React.FC<LoginContainerProps> = ({ login }) => {
  const { t } = useTranslation();
  const trialEntryUrl : string = t("urls:trialEntryForm");

  const dot = (
    <ListItemIcon style={{ minWidth: "24px", marginRight: "8px" }}>
      <FiberManualRecordIcon style={{ fontSize: "8px" }} />
    </ListItemIcon>
  );

  return (
    <ContentsWrapper>
      <LoginPanel>
        <LoginTitle variant="h4">{t("auth:welcomeToWorkAIzer")}</LoginTitle>
        <LoginDescription variant="body1">
          {/* t:本サービスをご利用いただくにあたり、以下の点にご留意ください。 */}
          {t("auth:pleaseNoteTheFollowing")}
        </LoginDescription>
        <List>
          <ListItem>
            { dot }
            <ListItemText primaryTypographyProps={{ variant: "body2" }}
              // t:本サービスは、原則 Google Workspace アカウントでのみご利用が可能です。
              primary={
                <>
                  <Typography component="span">{t("auth:googleWorkspaceAccountRequired")}</Typography><br/>
                </>
              }
              // t:その他、Googleアカウントをお使いの方はこちらよりお問い合わせください
              secondary={
                <Trans i18nKey="auth:googleAccountInquiry"
                  components={{l: <Link href={trialEntryUrl} target="_blank" rel="noopener"/>}}
                />
              }
            />

          </ListItem>
          <ListItem>
            { dot }
            <ListItemText
              primaryTypographyProps={{ variant: "body2" }}
              // t:トライアルのご利用には、事前にトライアルお申込みフォームでのご登録が必要です。
              primary={
                <Typography component="span">
                  <Trans i18nKey="auth:trialInquiry"
                    components={{l: <Link href={trialEntryUrl} target="_blank" rel="noopener"/>}}
                  />
                </Typography>
              }
              // t:ご登録が確認できない場合、トライアルを停止させていただく場合がございます
              secondary={t("auth:trialMayBeSuspended")}
            />
          </ListItem>
        </List>
        <LoginButton variant="contained" color="primary" size="large" onClick={login}>
          {/* t:Googleアカウントでログイン */}
          {t("auth:loginWithGoogleAccount")}
        </LoginButton>

        <Box mt={7} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="caption">
            {t("auth:displayLanguage")}
          </Typography>
          <SelectDisplayLanguage slim />
        </Box>
      </LoginPanel>
    </ContentsWrapper>
  );
};

export default LoginPresenter;

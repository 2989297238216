import React, { useState } from 'react';
import { DlpInfoType, DlpInfoTypeConfig, BulkSaveDlpInfoTypeConfig, SaveDlpInfoTypeConfigWithOptionalId } from '../dlp.type';
import {
  Button,
  Divider,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableBody,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DlpCheckSpeedType, DlpPreventType, getDlpCheckSpeedTypeLabel } from '../dlp.constant';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import styled from '@emotion/styled';
import theme from '@/theme';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { Trans, useTranslation } from 'react-i18next';

interface InfoTypeConfigListPresenterProps {
  infoTypes: DlpInfoType[];
  infoTypeConfigs: DlpInfoTypeConfig[];
  onSave: (data: BulkSaveDlpInfoTypeConfig) => void;
}

const ActionFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: solid 1px ${theme.palette.grey[400]};
  background-color: ${theme.palette.background.paper};
  padding: 15px 18px 0 0;
  justify-content: flex-end;
  display: flex;
`;


const PREVENT_TYPE_NONE = -1;
type PreventTypeValue = DlpPreventType | typeof PREVENT_TYPE_NONE;

const InfoTypeConfigListPresenter: React.FC<InfoTypeConfigListPresenterProps> = ({
  infoTypes,
  infoTypeConfigs,
  onSave,
}) => {
  const { setConfirmModal } = useConfirmModal();
  const [selectedConfigs, setSelectedConfigs] = useState<{ [key: string]: PreventTypeValue }>({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const getInitialPreventType = (infoTypeId: string) => {
    const config = infoTypeConfigs.find((config) => config.dlpInfoTypeId === infoTypeId);
    return config ? config.preventType : PREVENT_TYPE_NONE;
  };

  const handlePreventTypeChange = (infoTypeId: string, preventType: PreventTypeValue) => {
    setSelectedConfigs((prevState) => ({
      ...prevState,
      [infoTypeId]: preventType,
    }));
  };

  const handleSaveClick = () => {
    const saveConfigs: SaveDlpInfoTypeConfigWithOptionalId[] = [];
    const deleteIds: string[] = [];

    for (const [infoTypeId, preventType] of Object.entries(selectedConfigs)) {
      const existingConfig = infoTypeConfigs.find((config) => config.dlpInfoTypeId === infoTypeId);
      if (preventType !== PREVENT_TYPE_NONE) {
        saveConfigs.push({
          preventType,
          dlpInfoTypeId: infoTypeId,
          ...(existingConfig && { id: existingConfig.id }),
        });
      } else {
        if (existingConfig) {
          deleteIds.push(existingConfig.id);
        }
      }
    }
    setConfirmModal(
      // t:保存確認
      t("dlp:saveConfirmation"),
      // t:DLP設定を保存します。よろしいですか？
      t("dlp:saveConfirmationMessage"),
      () => onSave({ saveConfigs, deleteIds }),
    );
  };

  const convertSnakeToCamelCase = (str: string) => {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
  }

  const getNameTKey = (infoType: DlpInfoType) => {
    return `dlp:infoType.${convertSnakeToCamelCase(infoType.code)}.name`;
  }

  const getDescTKey = (infoType: DlpInfoType) => {
    return `dlp:infoType.${convertSnakeToCamelCase(infoType.code)}.description`;
  }

  return (
    <>
      {/* t:DLP（Data Loss Prevention）設定 */}
      <AdminPageTitle title={t("dlp:dlpSettingsTitle")} />
      <Divider />
      <AdminContentsWrapper style={{paddingBottom: 0}}>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}>
          {/*
            t:DLP設定を有効にすることで、指定したタイプの情報をブロックしたり、差し替えたりできます。<br/>
              タイプによっては処理に時間がかかることがありますので、ご留意ください。
          */}
          {t("dlp:dlpDescription")}
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph style={{ marginBottom: '10px' }}>
          {/*
            t: ※ 本機能ではGCPの<a>DLPサービス</a>を利用しております。
            そのため、文章の内容や文脈によっては、意図した情報が検出されない可能性がございます。
            この点につきまして、あらかじめご了承くださいますようお願い申し上げます。
           */}
          <Trans i18nKey="dlp:dlpServiceDescription"
            components={{l: <a href="https://cloud.google.com/security/products/dlp?hl=ja" target="_blank" rel="noopener"/>}}/>
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph style={{ marginBottom: '25px' }}>
          {/* t:保存ボタンはフッターにございます。 */}
          {t('dlp:saveButtonLocation')}
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* t:情報タイプ */}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('dlp:informationType')}</TableCell>
                {/* t:説明 */}
                {!isSmallScreen && <TableCell style={{ width: '100%' }}>{t('dlp:description')}</TableCell>}
                {/* t:検出速度 */}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('dlp:detectionSpeed')}</TableCell>
                {/* t:設定 */}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('dlp:settings')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoTypes.map((infoType) => (
                <TableRow key={infoType.id}>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {t(getNameTKey(infoType))}
                  </TableCell>
                  {!isSmallScreen && (
                    <TableCell>
                      <Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>
                        {t(getDescTKey(infoType))}
                        {
                            infoType.checkSpeedType == DlpCheckSpeedType.SLOW &&
                            "\n\n" + t("dlp:slowNotice")
                        }
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    {getDlpCheckSpeedTypeLabel(t, infoType.checkSpeedType)}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={selectedConfigs[infoType.id] ?? getInitialPreventType(infoType.id)}
                      onChange={(e) => handlePreventTypeChange(infoType.id, e.target.value as PreventTypeValue)}
                    >
                      <MenuItem value={PREVENT_TYPE_NONE}>{t('dlp:none')}</MenuItem>
                      <MenuItem value={DlpPreventType.PROHIBIT}>
                        {/* t:{{name}}が検出された場合、送信できなくなります。 */}
                        <Tooltip title={t('dlp:tooltip.infoType.prohibit', { name: t(getNameTKey(infoType)) })} placement="left">
                          {/* t:禁止 */}
                          <span>{t('dlp:prohibit')}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value={DlpPreventType.WARN}>
                        {/* t:{{name}}が検出された場合、警告を表示します。 */}
                        <Tooltip title={t('dlp:tooltip.infoType.warn', { name: t(getNameTKey(infoType)) })} placement="left">
                          {/* t:警告 */}
                          <span>{t('dlp:warn')}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value={DlpPreventType.REPLACE}>
                        {/* t:{{name}}が検出された場合、「{{replaceWord}}」というワードに置換して生成AIに送信します。 */}
                        <Tooltip title={t('dlp:tooltip.infoType.replace', { name: t(getNameTKey(infoType)), replaceWord: infoType.replaceWord })} placement="left">
                          {/* t:置換 */}
                          <span>{t('dlp:replace')}</span>
                        </Tooltip>
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ActionFooter>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSaveClick}
          >
            {/* t:設定を保存する */}
            {t('dlp:saveSettings')}
          </Button>
        </ActionFooter>
      </AdminContentsWrapper>
    </>
  );
}

export default InfoTypeConfigListPresenter;

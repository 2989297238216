import apiAxios from '@/lib/axios';
import React, { useState } from 'react';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { Box, Button, Divider, Typography } from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import HeaderLogo from '@/components/features/header/components/HeaderLogo';
import { useTranslation } from 'react-i18next';

const SvLogoCustomization = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  if (!team) {
    return null;
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"];
      const maxSize = 100_000; // 500KB

      if (!allowedTypes.includes(selectedFile.type)) {
        // t:無効なファイル形式です。PNG、JPEG、SVG形式のファイルを選択してください。
        alert(t('teams:logoCustomization.messages.invalidFileType'));
        return;
      }

      if (selectedFile.size > maxSize) {
        // t:ファイルサイズが大きすぎます。100KB以下のファイルを選択してください。
        alert(t('teams:logoCustomization.messages.fileTooLarge'));
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiAxios.put(
        `/supervisor/teams/${team.id}/logo`,
        formData
      );
      if (response.status === 200) {
        // t:[カスタマイズ成功]\n\nロゴのカスタマイズが正常に完了しました。\n画面をリロードします。
        window.alert(t('teams:logoCustomization.messages.uploadSuccess'));
        window.location.reload();
      } else {
        // t:ファイルのアップロードに失敗しました。
        alert(t('teams:logoCustomization.messages.uploadError'));
      }
    } catch (error) {
      // t:ファイルのアップロードに失敗しました。
      alert(t('teams:logoCustomization.messages.uploadError'));
    }
  };

  const handleDelete = async () => {
    // t:削除しますよろしいですか？
    const confirmDelete = window.confirm(t('teams:logoCustomization.messages.deleteConfirmation'));
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await apiAxios.delete(
        `/supervisor/teams/${team.id}/logo`
      );
      if (response.status === 200) {
        // t:[カスタマイズ解除]
        //   ロゴのカスタマイズが正常に解除されました。
        //   画面をリロードします。
        window.alert(t('teams:logoCustomization.messages.deleteSuccess'));
        window.location.reload();
      } else {
        // t:ファイルの削除に失敗しました。
        alert(t('teams:logoCustomization.messages.deleteError'));
      }
    } catch (error) {
      // t:ファイルの削除に失敗しました。
      alert(t('teams:logoCustomization.messages.deleteError'));
    }
  };

  return (
    <>
      {/* t:ロゴカスタマイズ */}
      <SupervisorPageTitle title={t("teams:logoCustomization.title")} />
      <Divider />
      <SupervisorContentsWrapper>
        <Box mb={5}>
          <Typography variant="h6" gutterBottom>
            {/* t:現在ヘッダーロゴの表示 */}
            {t("teams:logoCustomization.currentLogo")}
          </Typography>
          <Box sx={{ mb: 3 }}>
            <HeaderLogo logo={team.logo} />
          </Box>
          <Typography variant="body2" gutterBottom style={{ whiteSpace: 'pre-line' }}>
            {team.logo ?
              // t:現在カスタマイズされたロゴが表示されています。
              //   ロゴを変更する場合は、新しいロゴをアップロードしてください。
              //   通常のWorkAIzerのロゴに戻す場合は、下部の「削除する」ボタンをクリックしてください。
              t("teams:logoCustomization.customLogoInfo") :
              // t:現在ロゴはカスタマイズされていません。
              //   通常のWorkAIzerのロゴが表示されます。
              t("teams:logoCustomization.defaultLogoInfo")
            }
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          {/* t:ロゴをアップロードしてください */}
          {t("teams:logoCustomization.uploadInstructions")}
        </Typography>
        <form onSubmit={handleSubmit} style={{ marginBottom: '50px' }}>
          <Box mb={2}>
            <Box mb={2}>
              {file && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {/* t:選択されたファイル：{{fileName}} */}
                    {t("teams:logoCustomization.fileSelection.selected", { fileName: file.name })}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {/* t:ファイルサイズ：{{fileSize}} KB */}
                    {t("teams:logoCustomization.fileSelection.size", { fileSize: (file.size / 1024).toFixed(2) })}
                  </Typography>
                </Box>
              )}
            </Box>

            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="upload-button"
            />
            <label htmlFor="upload-button">
              <Button variant="outlined" size="small" component="span" color="primary">
                {/* t:ファイルを選択 */}
                {t("teams:logoCustomization.fileSelection.button")}
              </Button>
            </label>
            <Typography variant="caption" display="block" gutterBottom>
              {/* t:※ 100KBまで。推奨：縦幅52px 横幅190〜230px */}
              {t("teams:logoCustomization.fileSelection.restrictions")}
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!file}
          >
            {/* t:アップロードしてロゴを変更する */}
            {t("teams:logoCustomization.uploadButton")}
          </Button>
        </form>
        {
          team.logo && <>
            <Typography variant="h6" gutterBottom>
              {/* t:設定済みのロゴを削除する */}
              {t("teams:logoCustomization.deleteSection.title")}
            </Typography>
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="error"
            >
              {/* t:削除する */}
              {t("teams:logoCustomization.deleteSection.button")}
            </Button>
          </>
        }
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvLogoCustomization;

import { QuickPromptWithVersion } from "./quickPrompt.type";

export const getTextareaWithToolbarKey = (qp: QuickPromptWithVersion): string | undefined => {
  let textareaWithToolbarKey = '';
  const uiSchema: Record<string, any> = qp.targetVersion.uiSchema;
  for (const key in uiSchema) {
    const schema = uiSchema[key];
    if (schema['ui:widget'] === 'textareaWithToolbar') {
      textareaWithToolbarKey = key;
      break;
    }
  }
  return textareaWithToolbarKey || undefined;
};

export const getUiSchema = (qp: QuickPromptWithVersion,  initialFile: any): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const uiSchema: Record<string, any> = qp.targetVersion.uiSchema;

  if (!key) {
    return uiSchema;
  }

  const updatedUiSchema: Record<string, any> = { ...uiSchema };
  const schema = updatedUiSchema[key];
  if (schema) {
    updatedUiSchema[key as keyof typeof updatedUiSchema] = {
      ...schema,
      'ui:options': {
        ...(schema['ui:options'] as any),
        initialFile: initialFile,
      },
    };
  }
  return updatedUiSchema;
};

export const getFormSchema = (qp: QuickPromptWithVersion): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const formSchema: Record<string, any> = qp.targetVersion.formSchema;

  console.log("getFormSchema:" + key);
  if (!key) {
    return formSchema;
  }

  const updatedFormSchema: Record<string, any> = JSON.parse(JSON.stringify(formSchema));

  const requireds = updatedFormSchema['required'];

  // TextareaWithToolbarはrequiredから除外する
  if (requireds) {
    const index = requireds.indexOf(key);
    if (index !== -1) {
      requireds.splice(index, 1);
    }
  }
  updatedFormSchema['required'] = requireds;


  return updatedFormSchema;
}

export const getFormSchemaTitle = (qp: QuickPromptWithVersion, key: string): string => {
  const formSchema: Record<string, any> = qp.targetVersion.formSchema;
  const properties = formSchema['properties'];

  return properties[key]?.title;
}


import React from 'react';
import { Avatar, Box, Chip, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { AIModelConst } from '@/components/features/aiModel/aiModel.constant';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface AIModelListItemProps {
  aiModel: AIModelConst;
  isAllAiModelHasSameTags: boolean;
}

const StyledListItemText = styled(ListItemText)(() => ({
  flex: 1,
  fontSize: '14px',
  '& .MuiTypography-root': {
    fontWeight: 500,
  },
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '0.75rem',
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  fontSize: '0.75rem',
  height: '20px',
  '& .MuiChip-label': {
    padding: '0 6px',
  },
}));

const AIModelListItem: React.FC<AIModelListItemProps> = ({ aiModel, isAllAiModelHasSameTags }) => {
  const { t, i18n } = useTranslation()
  const isEnglish = i18n.language === 'en';

  const KnowledgeDate = styled(Typography)(({ theme }) => ({
    ...(isEnglish ? {} : {
      marginLeft: theme.spacing(1),
      alignSelf: 'center',
      lineHeight: 1,
    }),
  }));

  return (
    <>
      <ListItemAvatar>
        <Avatar src={aiModel.avatar} alt={aiModel.name} />
      </ListItemAvatar>
      <StyledListItemText
        primary={
          <Box component="span" display="flex" alignItems="center">
            <Typography variant="subtitle1" component="span">
              {aiModel.name}
            </Typography>
            {!isAllAiModelHasSameTags && (
              <TagsContainer>
                {aiModel.tagsT(t).map((tag, index) => (
                  <StyledChip key={index} label={tag} size="small" variant="outlined" />
                ))}
              </TagsContainer>
            )}
          </Box>
        }
        secondary={
          <Box component="span" display="flex"
            alignItems={isEnglish ? "flex-start" : "center"}
            flexDirection={isEnglish ? "column" : "row"}>
            <Box component="span" color="text.secondary">
              {aiModel.descriptionT(t)}
            </Box>
            <KnowledgeDate variant="caption" color="text.secondary">
              {/* t:知識 {{year}}/{{month}}まで */}
              ({t("aiModel:knowledgeDate",
                {
                  year: aiModel.knowledge.getFullYear(),
                  month: String(aiModel.knowledge.getMonth() + 1).padStart(2, '0'),
                }
              )})
            </KnowledgeDate>
          </Box>
        }
      />
    </>
  );
};

export default AIModelListItem;
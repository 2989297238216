import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentsWrapperWithLeftSidebar, MainAreaWrapper } from '@/components/ui/StyledComponents';
import { Box, Drawer, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SettingLeftSidebar from './SettingLeftSidebar';

// TODO AdminLayoutの内容とかなり重複しているのでもっとDRYにする

interface SettingLayoutProps {
  isLeftSidebarOpen: boolean;
  onCloseSidebar: () => void;
}

const SettingLayout: React.FC<SettingLayoutProps> = ({ isLeftSidebarOpen, onCloseSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContentsWrapperWithLeftSidebar>
      {/* 左サイドバー */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? isLeftSidebarOpen : true}
        onClose={onCloseSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.default,
            boxSizing: 'border-box',
            width: 275,
            borderRight: 'none',
          }
        }}
      >
        <Toolbar />
        <Box sx={{ paddingTop: "15px" }}>
          <SettingLeftSidebar onItemClick={onCloseSidebar} />
        </Box>
      </Drawer>

      {/* メインコンテンツ */}
      <MainAreaWrapper>
        <Outlet />
      </MainAreaWrapper>
    </ContentsWrapperWithLeftSidebar>
  );
};

export default SettingLayout;

import React from 'react';
import MessageFormContainer from '../containers/MessageFormContainer';
import { Divider, Typography } from '@mui/material';

import { Thread } from '../thread.type';
import styled from '@emotion/styled';
import ThreadPostListContainer from '../containers/ThreadPostListContainer';
import { restoreMask } from '../thread.utils';
import RecommendQpListContainer from '../../quickPrompt/containers/RecommendQpListContainer';
import { FileUpload } from '../../file/file.type';
import UploadProgressModal from '../../file/components/UploadProgressModal';
import { useTranslation } from 'react-i18next';

interface ThreadPresenterProps {
  inputMessage: object | undefined;
  inputMessageChanged: (newValue: object | undefined) => void;
  handleSendMessage: () => void;
  handleStopGeneration: () => void;
  onGenerating: boolean;
  currentThread: Thread | null;
  showRecommendQp: boolean;
  selectedFile?: File | FileUpload;
  onSelectFile: (file: File | undefined) => void;
  fileUploadProgress?: string;
}

export const ThreadTitle = styled(Typography)`
  padding-bottom: 10px;
`;

export const ThreadContentsWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const MessageAreaContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

const MessageInputContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;


const ThreadPresenter: React.FC<ThreadPresenterProps> = ({
  inputMessage,
  inputMessageChanged,
  handleSendMessage,
  handleStopGeneration,
  onGenerating,
  currentThread,
  showRecommendQp,
  selectedFile,
  onSelectFile,
  fileUploadProgress,
}) => {
  const { t } = useTranslation();

  const title = currentThread ?
    restoreMask(currentThread.title, currentThread.dlpReplaces) :
    t("thread:newThread")
  ;

  return (
    <>
      <ThreadTitle variant="h5">{title}</ThreadTitle>
      <Divider />
      <ThreadContentsWrapper>
        { currentThread && <ThreadPostListContainer currentThread={currentThread}/> }
        { showRecommendQp && <RecommendQpListContainer /> }
      </ThreadContentsWrapper>
      <MessageAreaContainer>
        <MessageInputContainer>
          <MessageFormContainer
            value={inputMessage}
            onChange={inputMessageChanged}
            onSubmit={handleSendMessage}
            onStop={handleStopGeneration}
            onGenerating={onGenerating}
            selectedFile={selectedFile}
            onSelectFile={onSelectFile}
          />
        </MessageInputContainer>
      </MessageAreaContainer>
      <UploadProgressModal progress={fileUploadProgress} />
    </>
  );
};

export default ThreadPresenter;

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AppBar, Toolbar, IconButton, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { HelpOutline, Menu  } from '@mui/icons-material';
import HeaderLogo from '../components/HeaderLogo';
import HeaderUserMenuContainer from '../containers/HeaderUserMenuContainer';
import { Outlet } from 'react-router-dom';
import { getCurrentMembership } from '../../auth/auth.type';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  onMenuButtonClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onMenuButtonClick }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const membership = getCurrentMembership(loginUser);
  const logo = membership?.team?.logo;
  const { t } = useTranslation();

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {isAuthenticated && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, display: { md: 'none' } }}
              onClick={onMenuButtonClick}
            >
              <Menu />
            </IconButton>
          )}
          <HeaderLogo logo={logo}/>
          <div style={{
            marginLeft: isDesktop ? 140 : undefined,
            flexGrow: 1,
          }}></div>
          {isDesktop && (
            // t:ユーザーマニュアル
            <Tooltip title={t("header:userManual")}>
              <IconButton
                size="large"
                component="a"
                href={t("urls:userManual")}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  marginRight: "10px",
                  color: "rgba(0, 0, 0, 0.45)",  // 若干薄くする
                }}
              >
                <HelpOutline fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {isAuthenticated && <HeaderUserMenuContainer />}
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default Header;

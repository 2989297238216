export const MB = 1024 * 1024;

export const UI_FLAG_FILE_UPLOAD_CONFIRM_KEY = "FILE_UPLOAD_CONFIRM";
export const UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW = 10;

export enum FileAnalysisStatus {
    PENDING = 'pending', // 解析待ち
    PROCESSING = 'processing', // 解析中
    DONE = 'done', // 解析完了
    FAILED = 'failed' // 解析失敗
}

export enum FileStorageType {
    GCS_UPLOAD = 'gcs_upload',
    BASE64_IMAGE = 'base64_image',
    TEXT = 'text'
}

export enum FileType {
    IMAGE = 'image', // 画像
    VIDEO = 'video', // 動画
    AUDIO = 'audio', // 音声
    DOCUMENT = 'document', // ドキュメント
    TEXT = 'text' // テキスト
}
export enum FileAnalysisFailureReason {
    PAGE_NUM_LIMIT_EXCEEDED = 'page_num_limit_exceeded', // ページ数上限超過
    DURATION_LIMIT_EXCEEDED = 'duration_limit_exceeded', // 動画・音声の長さ上限超過
}


export const UploadableTxtMimeTypes = [
  "text/plain",
  "text/csv",
  "text/markdown",
  "application/json",
  "application/yaml",
  "application/x-yaml",
];

export const UploadableImageMimeTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const UploadableVideoMimeTypes = [
  "video/mp4",
  "video/webm",
  "video/mkv",
  "video/quicktime",
];

export const UploadableAudioMimeTypes = [
  "audio/mpeg",
  "audio/wav",
  "audio/webm",
  // "audio/mp4",
  "audio/opus",
  "audio/aac",
  "audio/flac",
  "audio/pcm",
  "audio/x-m4a"
];

export const UploadableDocumentMimeTypes = [
  "application/pdf",
];

export const UploadableTxtMaxMb = 1;
export const UploadableImageMaxMb = 5;
export const UploadableVideoMaxMb = 100;
export const UploadableAudioMaxMb = 100;
export const UploadableDocumentMaxMb = 20;

export const UploadableNotTextMaxMbForStarter = 5;

export const MimeTypeToName = {
  // 画像
  "image/jpeg": "JPEG",
  "image/jpg": "JPG",
  "image/png": "PNG",
  "image/webp": "WEBP",
  // 動画
  "video/mp4": "MP4",
  "video/webm": "WEBM",
  "video/mkv": "MKV",
  "video/quicktime": "MOV",
  // 音声
  "audio/mpeg": "MP3",
  "audio/mpga": "MPGA",
  "audio/wav": "WAV",
  "audio/webm": "WEBM",
  "audio/x-m4a": "M4A",
  "audio/opus": "OPUS",
  "audio/aac": "AAC",
  "audio/flac": "FLAC",
  "audio/pcm": "PCM",
  // 文章
  "application/pdf": "PDF",
  // テキスト
  "text/plain": "TXT",
  "text/csv": "CSV",
  "text/markdown": "Markdown",
  "application/json": "JSON",
  "application/yaml": "YAML",
  "application/x-yaml": "YAML",
};

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DebugI18nPage () {
  const { t, i18n } = useTranslation()

  return (
    <Box sx={{ paddingTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      {t('common:test')}

      <div>選択言語：{i18n.language === 'en' ? 'English' : '日本語'}</div>
      <button
        type='button'
        title='change language'
        onClick={() => i18n.changeLanguage('en')}
      >
        英語
      </button>
      <button
        type='button'
        title='change language'
        onClick={() => i18n.changeLanguage('ja')}
      >
        日本語
      </button>
    </Box>
  );
}

export default DebugI18nPage;

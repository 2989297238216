import React from 'react';
import {
  Divider,
  Typography} from '@mui/material';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { TeamWithSubscription } from '../../team/team.type';
import SharedLatestUsageStatisticsPresenter from './SharedLatestUsageStatisticsPresenter';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';

const LatestUsageStatisticsPresenter: React.FC<{
  team: TeamWithSubscription,
}> = ({
  team
}) => {
  const { t } = useTranslation();
  const isStarter = team.currentSubscription.plan.type == PlanType.STARTER;
  return (
    <>
      {/* t:最新の利用状況 */}
      <AdminPageTitle title={t("usage:latestUsage.title")} />
      <Divider/>
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px', whiteSpace: 'pre-line' }}>
        { isStarter ?
          // t:Starterプランを開始してからの利用状況を確認いただけます。
          //   (有料プランにアップグレード後は、最新1ヶ月分の利用状況が表示されるページとなります)
          t("usage:latestUsage.starter.notice") :
          // t:最新の1ヶ月分の利用状況を確認いただけます。1ヶ月ごとにリセットされるため、ご注意ください。
          //   (対象期間は契約開始日を基準に算出しています)
          t("usage:latestUsage.paid.notice")
        }
        </Typography>
        <SharedLatestUsageStatisticsPresenter
            team={team} hideCreditInfo={isStarter}
          />
      </AdminContentsWrapper>
    </>
  );
};

export default LatestUsageStatisticsPresenter;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorModal } from '../generic/errorModal.slice';
import { showToast } from '../generic/toast.slice';
import { MembershipGroup, MembershipGroupCreate, MembershipGroupList, MembershipGroupUpdate } from './membership-group.type';
import i18next from 'i18next';

interface MembershipGroupState {
  membershipGroups: MembershipGroup[];
  num: number;
}

const initialState: MembershipGroupState = {
  membershipGroups: [],
  num: -1,  // 負の数は未読込とする
};

export const createMembershipGroup = createAsyncThunk(
  'membership-group/createMembershipGroup',
  async (data: MembershipGroupCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.post<MembershipGroup>('/membership-groups', data);
      // t:グループ「{{name}}」を作成しました
      dispatch(showToast({message: i18next.t('membershipGroup:toast.create', { name: data.name }), severity: 'success'}));
      return response.data;
    } catch (err) {
      console.debug("/membership-groups error", err)
      captureException(err);

      dispatch(showUnknownErrorModal());
      return rejectWithValue(i18next.t('common:message.unknownError'));
    }
  }
);

export const updateMembershipGroup = createAsyncThunk(
  'membership-group/updateMembershipGroup',
  async (data: MembershipGroupUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put<MembershipGroup>(`/membership-groups/${data.id}`, data);
      // t:グループ情報を更新しました
      dispatch(showToast({message: i18next.t('membershipGroup:toast.update'), severity: 'success'}));
      return response.data;
    } catch (err) {
      captureException(err);

      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const deleteMembershipGroup = createAsyncThunk(
  'membership-group/deleteMembershipGroup',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/membership-groups/${id}`);
      // t:グループを削除しました
      dispatch(showToast({message: i18next.t('membershipGroup:toast.delete'), severity: 'success'}));
      return id;
    } catch (err) {
      captureException(err);

      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);

const membershipSlice = createSlice({
  name: 'membership-group',
  initialState,
  reducers: {
    setMembershipGroups: (state, action: PayloadAction<MembershipGroup[]>) => {
      state.membershipGroups = action.payload;
    },
    appendMembershipGroups: (state, action: PayloadAction<MembershipGroupList>) => {
      const membershipGroups = action.payload.membershipGroups;
      // idが同じものは追加しない
      membershipGroups.forEach((group) => {
        if (!state.membershipGroups.some((m) => m.id === group.id)) {
          state.membershipGroups.push(group);
        }
      });
      // numの更新
      state.num = action.payload.num;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMembershipGroup.fulfilled, (state, action) => {
      const index = state.membershipGroups.findIndex(group => group.id === action.payload.id);
      if (index !== -1) {
        state.membershipGroups[index] = action.payload;
      } else {
        state.membershipGroups.unshift(action.payload);
        state.num++;
      }
    });
    builder.addCase(updateMembershipGroup.fulfilled, (state, action) => {
      const index = state.membershipGroups.findIndex(group => group.id === action.payload.id);
      if (index !== -1) {
        state.membershipGroups[index] = action.payload;
      }
    });
    builder.addCase(deleteMembershipGroup.fulfilled, (state, action) => {
      state.membershipGroups = state.membershipGroups.filter(group => group.id !== action.payload);
      state.num--;
    });
  },
});

export const {
  setMembershipGroups,
  appendMembershipGroups,
} = membershipSlice.actions;

export default membershipSlice.reducer;

import React from 'react';
import { List, ListItemIcon, ListItemText, IconButton, Divider, ListItem, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { QuickPromptSetWithQpList } from '@/components/features/quickPrompt/quickPrompt.type';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { useTranslation } from 'react-i18next';

interface Props {
  // クイックプロンプトセットの一覧 (既にソートされてる前提)
  sortedQuickPromptSets: QuickPromptSetWithQpList[];
  bookmarkedQuickPromptIds: string[];
  handleAddBookmark: (quickPromptId: string) => void;
  handleRemoveBookmark: (quickPromptId: string) => void;
}

const SvQpListPresenter: React.FC<Props> = ({
  sortedQuickPromptSets,
  bookmarkedQuickPromptIds,
  handleAddBookmark,
  handleRemoveBookmark,
}) => {
  const { t } = useTranslation();

  const DynamicIcon = ({ iconName }: { iconName?: string }) => {
    const IconComponent = iconName && iconName in Icons ?
      Icons[iconName as keyof typeof Icons] :
      Icons.InfoOutlined
    ;
    return <IconComponent />;
  };

  const qpSets = sortedQuickPromptSets;

  return <>
    {/* t:お気に入りクイックプロンプト管理 */}
    <SupervisorPageTitle title={t("quickPrompt:supervisor.favoriteManagement.title")} />
    <Divider />
    <SupervisorContentsWrapper>
      {/* t:チームのデフォルトのお気に入りクイックプロンプトを設定します。
         新しくチームに招待されたユーザーはここで設定したクイックプロンプトがお気に入りに設定されます。 */}
      <Typography variant='caption' style={{ whiteSpace: 'pre-line' }}>
        {t("quickPrompt:supervisor.favoriteManagement.description")}
      </Typography>
      <List disablePadding sx={{width: "300px", marginTop: "30px", marginBottom: "30px"}}>
        {qpSets.map((set) => (
          <React.Fragment key={set.id}>
            <ListItem dense>
              <ListItemIcon>
                <DynamicIcon iconName={set.icon} />
              </ListItemIcon>
              <ListItemText primary={set.name} />
            </ListItem>
            {set.quickPrompts.map((qp) => (
              <ListItem
                dense
                sx={{ pl: 4, pr: 1, display: 'flex', alignItems: 'center' }}
              >
                <ListItemIcon>
                  <DynamicIcon iconName={qp.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={qp.name}
                />
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    bookmarkedQuickPromptIds.includes(qp.id)
                      ? handleRemoveBookmark(qp.id)
                      : handleAddBookmark(qp.id);
                  }}
                >
                  {bookmarkedQuickPromptIds.includes(qp.id)
                    ? <Icons.Star sx={{ color: 'primary.light' }} />
                    : <Icons.StarBorder />
                  }
                </IconButton>
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    </SupervisorContentsWrapper>
  </>;
};

export default SvQpListPresenter;

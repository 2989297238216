import i18next from 'i18next';
import { fromZonedTime } from 'date-fns-tz';

const LOCALE_JA = 'ja-JP';
const LOCALE_EN = 'en-US';

export function joinPath(...paths: string[]): string {
    if (paths.length === 0) return '';

    // パスを結合
    const combined = paths.join('/');

    // URLのスキームを保護するための正規表現
    const schemeRegex = /^https?:\/\//;

    // スキームを除外してからスラッシュの正規化を行う
    const normalized = combined.replace(schemeRegex, '').replace(/\/+/g, '/');

    // スキームがあれば再度追加
    const resultWithScheme = combined.match(schemeRegex) ? combined.match(schemeRegex)![0] + normalized : normalized;

    // 先頭がスラッシュで始まる場合、またはURLの場合はスラッシュを保持
    const preserveLeadingSlash = paths[0].startsWith('/') || paths[0].startsWith('http://') || paths[0].startsWith('https://');
    const preserveTrailingSlash = paths[paths.length - 1].endsWith('/');

    // 先頭のスラッシュを処理
    let result = preserveLeadingSlash ? resultWithScheme : resultWithScheme.replace(/^\//, '');

    // 末尾のスラッシュを処理
    result = preserveTrailingSlash ? result : result.replace(/\/$/, '');

    return result;
}

export function getLocales(): string {
    let locales = LOCALE_EN;
    if (i18next.language == "ja") {
        locales = LOCALE_JA;
    }
    return locales;
}

function formatDate(date: string | Date | undefined, options: Intl.DateTimeFormatOptions): string {
    if (!date) return '';

    // サーバーからの日時文字列が日本時間である場合、タイムゾーンを明示
    // 'Asia/Tokyo'を指定してUTCに変換
    const dateObj = fromZonedTime(date, 'Asia/Tokyo');
    if (isNaN(dateObj.getTime())) return '';

    // 日本語ロケールの場合、月を2桁にする
    const locales = getLocales();
    if (locales == LOCALE_JA && options.month) {
        options.month = '2-digit';
    }
    // タイムゾーン指定を削除してPCのタイムゾーンを使用
    const { timeZone: _, ...optionsWithoutTimeZone } = options;

    return dateObj.toLocaleString(locales, optionsWithoutTimeZone);
}

export function displayTimestamp(date?: string | Date): string {
    return formatDate(date, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}

export function displayDate(date?: string | Date): string {
    return formatDate(date, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    });
}

export function displayMonth(date?: string | Date): string {
    return formatDate(date, {
        year: 'numeric',
        month: 'long'
    });
}

export function displayMonthShort(date?: string | Date): string {
    return formatDate(date, {
        year: 'numeric',
        month: 'short'
    });
}

export function displayMonthDay(date?: string | Date): string {
    return formatDate(date, {
        month: 'short',
        day: '2-digit'
    });
}

export function formatCsvDateStr(date: Date): string {
    const locales = getLocales();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    if (locales === LOCALE_JA) {
        return `${year}/${month}/${day}`;
    }
    return `${month}/${day}/${year}`;
}

export function formatApiDateStr(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function removeDecimalPart(value: string | undefined, digits: number = 0): string {
    if (!value) return '';

    if (digits === 0) {
        return value.split('.')[0];
    }
    const regex = new RegExp(`(\\d+\\.\\d{${digits}})\\d*`);
    return value.replace(regex, '$1');
}

/**
 * Decimal文字列の小数点以下部分を調整する
 *
 * - 1未満の場合は、小数点2桁まで表示
 * - 10未満の場合は、小数点1桁まで表示
 * - それ以外の場合は、整数のみ表示
 */
export function formatDecimal(value: string | undefined): string {
    if (!value) return '';

    const num = parseFloat(value);
    if (num < 1) {
        return num.toFixed(2);
    } else if (num < 10) {
        return num.toFixed(1);
    } else {
        return Math.round(num).toString();
    }
}

/**
 * 引数の文字列が指定文字数より長い場合は「…」をつける
 */
export function truncate(str: string, length: number): string {
    if (str.length <= length) return str;
    return str.slice(0, length) + '…';
}

/**
 * スレッド詳細ページか判定する
 */
export function isThreadDetailPage(): boolean {
    const currentUrl = window.location.pathname;
    const threadUrlPattern = /\/threads\/[a-zA-Z0-9]+/;
    return threadUrlPattern.test(currentUrl);
}


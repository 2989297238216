import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfirmDialog } from '../../../ui/dialogs/ConfirmDialog'; // ConfirmDialogのパスを適切に設定してください
import { useTranslation } from 'react-i18next';

export const APIUnauthorizedHandler: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    const handleUnauthorized = () => {
      setDialogOpen(true);
    };

    // イベントリスナーを登録
    window.addEventListener("unauthorized", handleUnauthorized);

    // コンポーネントのアンマウント時にイベントリスナーを解除
    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, []);

  const handleConfirm = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        open={dialogOpen}
        // t:ログイン状態エラー
        title={t("auth:loginStateError")}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        // t:再ログイン
        confirmButtonText={t("auth:reLogin")}
        // t:キャンセル
        cancelButtonText={t("common:button.cancel")}
      >
        {/* t:ログイン状態が失われました。再度ログインしてください。 */}
        {t("auth:loginStateLostPleaseLoginAgain")}
      </ConfirmDialog>
      <Outlet />
    </>
  );
};

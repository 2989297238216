import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import { News } from '../news.type';
import {
  CircularProgress,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { displayTimestamp } from '@/lib/utils';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguageLabel } from '@/components/features/setting/setting.utils';

interface NewsListPresenterProps {
  newsList: News[];
  onScroll: () => void;
  onDelete: (news: News) => void;
  hasMore: boolean;
}

const SvNewsListPresenter: React.FC<NewsListPresenterProps> = ({
  newsList,
  onScroll,
  onDelete,
  hasMore,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* t:お知らせ管理 */}
      <SupervisorPageTitle title={t("news:supervisor.management")} />
      <Divider />
      <SupervisorContentsWrapper id="scrollableDiv">
        <Box display="flex" justifyContent="flex-start" mb={2}>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/supervisor/news/new"
          >
            {/* t:新規作成 */}
            {t("common:button.new")}
          </Button>
        </Box>
        <InfiniteScroll
          dataLength={newsList.length}
          next={onScroll}
          hasMore={hasMore}
          loader={<CircularProgress />}
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("news:supervisor.list.columns.id")}</TableCell>
                  <TableCell>
                    {/* t:タイトル */}
                    {t("news:supervisor.list.columns.title")}
                  </TableCell>
                  <TableCell>
                    {/* t:言語 */}
                    {t("news:supervisor.list.columns.language")}
                  </TableCell>
                  <TableCell>
                    {/* t:タイプ */}
                    {t("news:supervisor.list.columns.type")}
                  </TableCell>
                  <TableCell>
                    {/* t:公開日時 */}
                    {t("news:supervisor.list.columns.pubDate")}
                  </TableCell>
                  <TableCell>
                    {/* t:作成日時 */}
                    {t("common:info.createdAt")}
                  </TableCell>
                  <TableCell>
                    {/* t:重要？ */}
                    {t("news:supervisor.list.columns.isImportant")}
                  </TableCell>
                  <TableCell>
                    {/* t:非表示？ */}
                    {t("news:supervisor.list.columns.isHidden")}
                  </TableCell>
                  <TableCell>
                    {/* t:操作 */}
                    {t("common:info.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newsList.map((news) => (
                  <TableRow key={news.id}>
                    <TableCell>{news.id}</TableCell>
                    <TableCell>
                      <NavLink to={`/supervisor/news/${news.id}/edit`}>
                        {news.title}
                      </NavLink>
                    </TableCell>
                    <TableCell>
                      {/* t:内部コンテンツ/外部リンク */}
                      {getLanguageLabel(news.language)}
                    </TableCell>
                    <TableCell>
                      {/* t:内部コンテンツ/外部リンク */}
                      {news.type === 'internal' ? t('news:supervisor.list.types.internal') : t('news:supervisor.list.types.external')}
                    </TableCell>
                    <TableCell>{displayTimestamp(news.publishedAt)}</TableCell>
                    <TableCell>{displayTimestamp(news.createdAt)}</TableCell>
                    <TableCell>
                      {news.isImportant &&
                        // t:重要
                        t('news:supervisor.list.labels.important')}
                    </TableCell>
                    <TableCell>
                      {news.isHidden &&
                        // t:非表示
                        t('news:supervisor.list.labels.hidden')}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        component={NavLink}
                        to={`/supervisor/news/${news.id}/edit`}
                        sx={{ mr: 1 }}
                      >
                        {/* t:編集 */}
                        {t("common:button.edit")}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onDelete(news)}
                      >
                        {/* t:削除 */}
                        {t("common:button.delete")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvNewsListPresenter;

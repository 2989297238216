import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsLoading } = useLoading();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const logout = useCallback(async () => {
    setIsLoading(true)
    try {
      await apiAxios.post('/auth/logout');
    } catch (error) {
      // エラーを無視
    }
    dispatch({ type: 'RESET_STORE' });
    setIsLoading(false)
    // t:ログアウトしました
    showToast(t('auth:loggedOut'), 'success');
    navigate('/login');
  }, [navigate, dispatch]);

  return logout;
};

import { removeDecimalPart } from "@/lib/utils";
import { TFunction } from "i18next";

export const getSpecialCreditLabel = (t: TFunction, val?: string) : string | null => {
    if (val === undefined || val === null) {
        // t:制限はありません
        return t("usage:specialCredit.noLimit");
    }
    if (removeDecimalPart(val) === "0") {
        // t:クレジットの利用を許可されていません
        return t("usage:specialCredit.notAllowed");
    }
    return null;
}

import { displayMonthDay, displayMonthShort, formatCsvDateStr } from "@/lib/utils";
import { UnitType } from "./report.type";


export const getDateStrForGraph = (targetOn: string, unitType: UnitType): string => {
    if (unitType == UnitType.WEEK) {
        // 月曜〜日曜の文字列を返す
        const date = new Date(targetOn);
        const day = date.getDay();
        const diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);
        const monday = new Date(date.setDate(diffToMonday));
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return `${displayMonthDay(monday)} - ${displayMonthDay(sunday)}`;
    }
    if (unitType == UnitType.MONTH) {
        // 2024/01/31なら2024/01を返す
        return displayMonthShort(targetOn);
    }

    // 2024-01-31なら01/31を返す
    return displayMonthDay(targetOn);
};

export const getCsvTermFrom = (targetOn: string, unitType: UnitType): string => {
    if (unitType == UnitType.WEEK) {
        // 月曜を返す
        const date = new Date(targetOn);
        const day = date.getDay();
        const diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);
        const monday = new Date(date.setDate(diffToMonday));
        return formatCsvDateStr(monday);
    }
    if (unitType == UnitType.MONTH) {
        // 2024-01-31なら2024/01/01を返す
        const day1 = new Date(targetOn);
        day1.setDate(1);
        return formatCsvDateStr(day1)
    }

    return formatCsvDateStr(new Date(targetOn));
}


export const getCsvTermTo = (targetOn: string, _: UnitType): string => {
    // そのままの日付を返す
    return formatCsvDateStr(new Date(targetOn));
}
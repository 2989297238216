import React, { useState } from 'react';
import { EditorContent, Editor } from "@tiptap/react";
import styled from '@emotion/styled';
import MentionButton from '../components/MessageForm/MentionButton';
import { Box, IconButton, Toolbar } from '@mui/material';
import theme from '@/theme';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import FileUploadButton from '../components/MessageForm/FIleUploadButton';
import AttachedFileInfo from '../components/AttachedFileInfo';
import { FileUpload } from '../../file/file.type';
import { updateUiMembershipFlag } from '../../auth/auth.slice';
import { RootState, useAppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { getUiFlagValue } from '../../auth/auth.type';
import { UI_FLAG_FILE_UPLOAD_CONFIRM_KEY, UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW } from '../../file/file.constant';
import FileWarningModal from '../components/MessageForm/FileWarningModal';
import { useTranslation } from 'react-i18next';

const MessageToolbar = styled(Toolbar)`
  padding: 0;
  min-height: 48px !important;
  justify-content: space-between; /* アイテムを両端に配置 */
`;

const IconButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const EditorArea = styled.div<{ hasFile: boolean }>`
  position: relative;
  width: 100%;

  .toolbar {
    display: flex;
    align-items: center;
    padding: 4px;

    button {
      margin-right: 4px;
      padding: 4px 8px;
      border: none;
      background: none;
      cursor: pointer;

      &.is-active {
        background: #eee;
      }
    }
  }

  .ProseMirror {
    border: 1px solid #ccc;
    font-size: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    cursor: text;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding: 16px 14px;
    padding-right: 40px;

    // 最大縦幅
    max-height: 60vh;
    overflow: auto;

    // 折り返しされるようにする
    max-width: 100%;
    word-wrap: break-word;
    white-pace: break-space;
    overflow-wrap: break-word;
    line-break: anywhere;
    word-break: break-all;
    overflow-wrap: break-word;
    overflow-wrap: anywhere;

    // FileInfo分の下パッディングを追加
    padding-bottom: ${props => (props.hasFile ? '90px' : '16px')};
  }

  // pタグはmarginなし
  .ProseMirror p{
    margin: 0;
  }

  // プレイスホルダーの表示
  .ProseMirror p.is-editor-empty:first-of-type::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  // メンションの装飾
  .ProseMirror span[data-type="mention"] {
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }

  // ダミーの見た目
  .DummyProseMirror {
    background-color: ${theme.palette.grey[100]};
  }

`;

const TextAreaLike = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 5px;
`;

const LoadingText = styled.span`
  color: ${theme.palette.grey[600]};
  @keyframes blink {
    0% { content: attr(data-content); }
    25% { content: attr(data-content) "."; }
    50% { content: attr(data-content) ".."; }
    75% { content: attr(data-content) "..."; }
    100% { content: attr(data-content); }
  }
  &::before {
    content: attr(data-content);
    animation: blink 1.5s steps(4, end) infinite;
  }
`;

const AttachedFileInfoContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 15px;
  margin: 0 auto;
`;

interface MessageFormPresenterProps {
  editor: Editor;
  onSubmit: () => void;
  onStop: () => void;
  onGenerating: boolean;
  hideSubmitButton: boolean;
  disableGeneratingMessage: boolean;
  attachableFile: boolean;
  selectedFile?: File | FileUpload;
  onSelectFile: (file: File | undefined) => void;
}

const MessageFormPresenter: React.FC<MessageFormPresenterProps> = ({
  editor,
  onSubmit,
  onStop,
  onGenerating,
  hideSubmitButton,
  disableGeneratingMessage,
  attachableFile,
  selectedFile,
  onSelectFile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [openFileWarning, setOpenFileWarning] = useState(false);
  const [dontShowAgainFileWarning, setDontShowAgainFileWarning] = useState(false);
  const fileUploadConfirm = getUiFlagValue(loginUser, UI_FLAG_FILE_UPLOAD_CONFIRM_KEY);

  const onFileRemove = () => {
    onSelectFile(undefined);
  };

  const onSend = () => {
    if (selectedFile && fileUploadConfirm !== UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW) {
      setOpenFileWarning(true);
    } else {
      onSubmit();
    }
  }

  const onCloseFileWarning = () => {
    setOpenFileWarning(false);
  }

  const onConfirmFileWarning = () => {
    if (dontShowAgainFileWarning) {
      dispatch(updateUiMembershipFlag({
        key: UI_FLAG_FILE_UPLOAD_CONFIRM_KEY,
        value: UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW,
      }));
    }

    setOpenFileWarning(false);
    onSubmit();
  }

  return (
    <EditorArea hasFile={!!selectedFile}>
      <MessageToolbar disableGutters>
        <IconButtons>
          <MentionButton editor={editor}/>
          {attachableFile &&
            <FileUploadButton onFileSelect={onSelectFile} currentFile={selectedFile} />
          }
        </IconButtons>
      </MessageToolbar>
      <TextAreaLike>
        {
          (onGenerating && !disableGeneratingMessage) ?
          <Box className="ProseMirror DummyProseMirror">
              <p className="is-empty is-editor-empty">
                <LoadingText data-content={t("thread:messageForm.underProcessing")} />
              </p>
          </Box>:
          <EditorContent editor={editor} />
        }
        {!hideSubmitButton && <ButtonWrapper>
          {
            onGenerating ?
            <IconButton color="primary" onClick={onStop}>
              <StopIcon  />
            </IconButton> :
            <IconButton color="primary" onClick={onSend}>
              <SendIcon  />
            </IconButton>
          }
        </ButtonWrapper>}
        {
          selectedFile &&
          <AttachedFileInfoContainer>
            <AttachedFileInfo file={selectedFile} onRemove={onFileRemove} />
          </AttachedFileInfoContainer>
        }
      </TextAreaLike>
      <FileWarningModal
         open={openFileWarning}
         onClose={onCloseFileWarning}
         onConfirm={onConfirmFileWarning}
         dontShowAgain={dontShowAgainFileWarning}
         setDontShowAgain={setDontShowAgainFileWarning}
      />
    </EditorArea>
  );
};

export default MessageFormPresenter;

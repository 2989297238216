/** @jsxImportSource @emotion/react */
import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { SuggestionKeyDownProps } from '@tiptap/suggestion'
import { List, ListItemButton } from '@mui/material';
import { css } from '@emotion/react';
import { styled } from '@mui/system';
import AIModelListItem from '@/components/features/aiModel/components/AIModelListItem';
import { MentionItem } from '../../containers/MessageForm/suggestion';
import LastMentionedAIModelsListItem from '@/components/features/aiModel/components/LastMentionedAIModelsListItem';
import { Editor } from '@tiptap/react';
import { useTranslation } from 'react-i18next';

interface MentionListProps {
  items: MentionItem[];
  command: (item: { id: string; label: string }) => void;
  editor: Editor,
}
export interface MentionListRef {
  onKeyDown: (props: SuggestionKeyDownProps ) => boolean;
}
const mentionListStyle = css`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow-y: auto;
  padding: 8px 0;
`;

const ResponsiveList = styled(List)(() => ({
  'maxHeight': "75vh",
}));

const mentionItemStyle = css`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const selectedMentionItemStyle = css`
  ${mentionItemStyle};
  background-color: #e0e0e0;
  font-weight: bold;
`;

const MentionList = forwardRef<MentionListRef, MentionListProps>((props, ref) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      if (item.aiModelConst) {
        props.command({
          id: item.aiModelConst.id,
          label: item.aiModelConst.name,
        });
      }
      if (item.aiModelConsts) {
        let chain = props.editor.chain().undo();
        for (const aiModelConst of item.aiModelConsts) {
          chain = chain.insertContent({
            type: "mention",
            attrs: {
              id: aiModelConst.id,
              label: aiModelConst.name
            },
          }).insertContent(" ");
        }
        chain.focus().run();
      }
    }
  };

  const upHandler = () => {
    setSelectedIndex(prevIndex => {
      const newIndex = (prevIndex + props.items.length - 1) % props.items.length;
      scrollToItem(newIndex);
      return newIndex;
    });
  };

  const downHandler = () => {
    setSelectedIndex(prevIndex => {
      const newIndex = (prevIndex + 1) % props.items.length;
      scrollToItem(newIndex);
      return newIndex;
    });
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  const scrollToItem = (index: number) => {
    const list = listRef.current;
    if (list) {
      const item = list.children[index] as HTMLElement;
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.clientHeight;
        const listTop = list.scrollTop;
        const listBottom = listTop + list.clientHeight;

        if (itemTop < listTop) {
          list.scrollTop = itemTop;
        } else if (itemBottom > listBottom) {
          list.scrollTop = itemBottom - list.clientHeight;
        }
      }
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }
      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }
      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }
      return false;
    },
  }));

  // 全てのAIモデルが同じタグを持っているか
  const isAllAiModelHasSameTags = props.items.length > 0 &&
    props.items.every((item) => {
      if (item.aiModelConst) {
        const c = item.aiModelConst;

        const firstAiModelConstItem = props.items.find(item => item.aiModelConst);
        if (firstAiModelConstItem && firstAiModelConstItem.aiModelConst) {
          const firstAiModelConst = firstAiModelConstItem.aiModelConst;
          return (
            c.tagsT(t).length === firstAiModelConst.tagsT(t).length &&
            c.tagsT(t).every(tag => firstAiModelConst.tagsT(t).includes(tag))
          );
        }

      }
      return false;
    }
    );

  return (
    <ResponsiveList css={mentionListStyle} ref={listRef}>
      {props.items.map((item, index) => {
        if (item.aiModelConsts) {
          return (
            <ListItemButton
              key={'lastMentioned' + index}
              onClick={() => selectItem(index)}
              css={index === selectedIndex ? selectedMentionItemStyle : mentionItemStyle} >
              <LastMentionedAIModelsListItem
                aiModelConsts={item.aiModelConsts}
              />
            </ListItemButton>
          )
        }
        if (item.aiModelConst) {
          const aiModel = item.aiModelConst;
          return (
            <ListItemButton
              key={aiModel.id}
              onClick={() => selectItem(index)}
              css={index === selectedIndex ? selectedMentionItemStyle : mentionItemStyle} >
              <AIModelListItem
                aiModel={aiModel}
                isAllAiModelHasSameTags={isAllAiModelHasSameTags}
              />
            </ListItemButton>
          );
        }
      })}
    </ResponsiveList>
  );
});

export default MentionList;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useTranslation } from 'react-i18next';
import { z } from '@/lib/zod';
import i18next from 'i18next';
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  TextField,
  Button,
  FormControl,
  Box,
  FormLabel,
  Divider,
  Typography,
  FormHelperText,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import { getLanguageLabel } from '../setting.utils';
import apiAxios from '@/lib/axios';
import { useToast } from '../../generic/hooks/useToast';
import { setUserInfo } from '../../auth/auth.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';

export const inputSchema = z.object({
  // UI言語
  language: z.enum(["en", "ja"]),
  // 生成AI言語
  aiLanguage: z.enum(["en", "ja", "other"]),
  // その他言語
  aiLanguageOther: z.string().max(25).optional().nullable(),
}).superRefine((data, ctx) => {
  if (data.aiLanguage === "other" && !data.aiLanguageOther) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18next.t('common:validation.required'),
      path: ["aiLanguageOther"],
    });
  }
});

export type Input = z.infer<typeof inputSchema>


const LocaleSetting: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { setUnknownErrorModal } = useErrorModal();

  const { register, control, handleSubmit, watch, formState: { errors } } = useForm<Input>({
    resolver: zodResolver(inputSchema),
    defaultValues: {
      language: loginUser?.language,
      aiLanguage: loginUser?.aiLanguage,
      aiLanguageOther: loginUser?.aiLanguageOther,
    },
  });

  if (!loginUser) {
    return null;
  }

  const onSubmit = async (data: Input) => {
    setIsLoading(true);
    if (loginUser) {
      try {
        const res = await apiAxios.put('/setting/locale', data);
        dispatch(setUserInfo(res.data))
        // t:チーム情報を更新しました。
        showToast(t('setting:locale.message.updateSuccess'), 'success')
        i18n.changeLanguage(data.language);
      } catch (err) {
        captureException(err);
        setUnknownErrorModal();
        console.error("put /setting/locale error", err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  return <>
    {/* t:機能設定 */}
    <AdminPageTitle title={t("setting:locale.pageTitle")} />
    <Divider />
    <AdminContentsWrapper>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
        {/* t:UI表示言語や生成AIとやり取りする際の言語を設定できます。 */}
        {t("setting:locale.pageDescription")}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>

        <Box mb={4}>
          <FormControl error={!!errors.language}>
            {/* t:言語 */}
            <FormLabel id="type-radio-buttons-group">{t("setting:locale.column.uiLanguage")}</FormLabel>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  aria-labelledby="type-radio-buttons-group"
                  value={field.value || ''}
                  onChange={(event) => field.onChange(event.target.value)}
                  displayEmpty
                >
                  <MenuItem value="en">{getLanguageLabel('en')}</MenuItem>
                  <MenuItem value="ja">{getLanguageLabel('ja')}</MenuItem>
                </Select>
              )}
            />
            {errors.language && <FormHelperText error>{errors.language.message}</FormHelperText>}
            <FormHelperText sx={{ml:0}}>
              {/* t:メニューやボタン、項目名などを表示に使う言語を設定します。 */}
              {t("setting:locale.column.uiLanguageDescription")}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={4}>
          <FormControl error={!!errors.aiLanguage}>
            {/* t:言語 */}
            <FormLabel id="type-radio-buttons-group">{t("setting:locale.column.aiLanguage")}</FormLabel>
            <Controller
              name="aiLanguage"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  aria-labelledby="type-radio-buttons-group"
                  value={field.value || ''}
                  onChange={(event) => field.onChange(event.target.value)}
                  displayEmpty
                >
                  <MenuItem value="en">{getLanguageLabel('en')}</MenuItem>
                  <MenuItem value="ja">{getLanguageLabel('ja')}</MenuItem>
                  <MenuItem value="other">{t("setting:locale.column.aiLanguageOther")}</MenuItem>
                </Select>
              )}
            />
            {watch('aiLanguage') === 'other' && (
              <Box mt={1}>
                <TextField
                  // t:その他言語
                  {...register('aiLanguageOther')}
                  error={!!errors.aiLanguageOther}
                  helperText={errors.aiLanguageOther?.message}
                />
              </Box>
            )}
            {errors.aiLanguage && <FormHelperText error>{errors.aiLanguage.message}</FormHelperText>}
            <FormHelperText sx={{ml:0}}>
              {/* t:生成AIとのやり取りで使う言語を設定します。 */}
              {t("setting:locale.column.aiLanguageDescription")}
            </FormHelperText>
          </FormControl>
        </Box>


        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={24} /> : null}
        >
          {/* t:保存 */}
          {t("common:button.save")}
        </Button>
      </form>
    </AdminContentsWrapper>
  </>;
}

export default LocaleSetting;
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import { SvTeam } from '../team.type';
import {
  CircularProgress,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { displayTimestamp } from '@/lib/utils';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import SvTeamFormModal from '../components/SvTeamFormModal';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { getPlanTypeLabel } from '@/components/features/subscription/subscription.constant';

interface SvTeamListPresenterProps {
  teams: SvTeam[];
  onScroll: () => void;
  hasMore: boolean;

  // 検索関係
  keyword: string;
  setKeyword: (keyword: string) => void;
  doSearch: () => void;
  clearSearchCondition: () => void;
}

const SvTeamListPresenter: React.FC<SvTeamListPresenterProps> = ({
  teams,
  onScroll,
  hasMore,
  keyword,
  setKeyword,
  doSearch,
  clearSearchCondition,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return null;
  }

  return (
    <>
      {/* t:チーム管理 */}
      <SupervisorPageTitle title={t("teams:teamList.title")} />
      <Divider />
      <SupervisorContentsWrapper id="scrollableDiv">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsModalOpen(true)}
          >
            {/* t:新規登録 */}
            {t("teams:common.create")}
          </Button>
          <Box display="flex" alignItems="center">
            <TextField
              // t:キーワード検索
              label={t("teams:teamList.keywordSearch")}
              variant="outlined"
              size="small"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
            />
            <IconButton onClick={doSearch}>
              <SearchIcon />
            </IconButton>
            <IconButton onClick={clearSearchCondition}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <InfiniteScroll
          dataLength={teams.length}
          next={onScroll}
          hasMore={hasMore}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("teams:teamList.columns.id")}</TableCell>
                  {/* t:チーム名 */}
                  <TableCell>{t("teams:teamList.columns.teamName")}</TableCell>
                  {/* t:会社名 */}
                  <TableCell>{t("teams:teamList.columns.companyName")}</TableCell>
                  {/* t:プライマリドメイン */}
                  <TableCell>{t("teams:teamList.columns.primaryDomain")}</TableCell>
                  {/* t:プラン */}
                  <TableCell>{t("teams:common.plan")}</TableCell>
                  {/* t:現在の期間 */}
                  <TableCell>{t("teams:teamList.columns.currentPeriod")}</TableCell>
                  {/* t:ライセンス */}
                  <TableCell>{t("teams:common.license")}</TableCell>
                  {/* t:作成者 */}
                  <TableCell>{t("teams:teamList.columns.creator")}</TableCell>
                  {/* t:作成日時 */}
                  <TableCell>{t("teams:teamList.columns.createdAt")}</TableCell>
                  {/* t:メモ */}
                  <TableCell style={{ width: '200px'}}>{t("teams:common.memo")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((team) => (
                  <TableRow key={team.id}>
                    <TableCell>{team.id}</TableCell>
                    <TableCell style={{ maxWidth: '200px'}}>
                      <NavLink to={`/supervisor/teams/${team.id}`}>
                        {team.name}
                      </NavLink>
                    </TableCell>
                    <TableCell style={{ maxWidth: '200px'}}>
                      {team.companyName}
                    </TableCell>
                    <TableCell>{team.primaryDomain}</TableCell>
                    <TableCell>
                      {getPlanTypeLabel(t, team.currentSubscription.plan.type)}
                      { !!team.scheduledSubscription && <>
                        <br/>
                        {/* t:変更予約あり */}
                        <span style={{color:'red', fontSize: '0.75em'}}>{t("teams:teamList.changeReserved")}</span>
                      </>}
                    </TableCell>
                    <TableCell>
                      {team.currentSubscription.latestContractTerm[0]}
                      〜
                      {team.currentSubscription.latestContractTerm[1]}
                    </TableCell>
                    <TableCell>
                      {team.currentSubscription.currentLicense?.amount}
                      { !!team.currentSubscription.scheduledLicense && <>
                        <br/>
                        {/* t:変更予約あり */}
                        <span style={{color:'red', fontSize: '0.75em'}}>{t("teams:teamList.changeReserved")}</span>
                      </>}
                    </TableCell>
                    <TableCell>
                      { team.creator ?
                        <>
                          {team.creator.name}<br/>
                          <Typography variant='caption'>({team.creator.email})</Typography>
                        </>:
                        // t:ユーザー削除済
                        t("teams:teamList.userDeleted")
                      }
                    </TableCell>
                    <TableCell>{displayTimestamp(team.createdAt)}</TableCell>
                    <TableCell style={{fontSize: '0.75rem', whiteSpace: 'pre-wrap'}}>{team.memo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </SupervisorContentsWrapper>
      <SvTeamFormModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default SvTeamListPresenter;

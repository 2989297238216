import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembershipGroup } from '../membership-group.slice';
import { AppDispatch, RootState } from '@/store';
import { getCurrentSubscription, isAdmin } from '@/components/features/auth/auth.type';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { ResponsiveTableActionFooter, ResponsiveTableCell, ResponsiveTableRow, ResponsiveTableRowHeader } from '@/components/ui/ResponsiveComponents';
import { displayTimestamp } from '@/lib/utils';
import { MembershipGroup } from '../membership-group.type';
import MembershipGroupFormModal from '../components/MembershipGroupFormModal';
import { useTranslation, Trans } from 'react-i18next';

interface MembershipGroupListPresenterProps {
  membershipGroups: MembershipGroup[];
  membershipGroupNum: number;
  onScroll: () => void;
  hasMore: boolean;
}

const MembershipGroupListPresenter: React.FC<MembershipGroupListPresenterProps> = ({
  membershipGroups,
  membershipGroupNum,
  onScroll,
  hasMore,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMembershipGroup, setSelectedMembershipGroup] = useState<MembershipGroup | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return null;
  }
  const currentSub = getCurrentSubscription(loginUser);
  if (!currentSub) {
    return null;
  }

  const handleAddClick = () => {
    setSelectedMembershipGroup(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (membershipGroup: MembershipGroup) => {
    setSelectedMembershipGroup(membershipGroup);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    {/* t:本当に削除しますか？\n\n※ グループを削除しても所属メンバーは削除されません。 */}
    if (window.confirm(t('membershipGroup:delete.confirm'))) {
      await dispatch(deleteMembershipGroup(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* t:グループ管理 */}
      <AdminPageTitle title={t("membershipGroup:management.title")} />
      <Divider />
      <AdminContentsWrapper id="scrollableDiv">
        <Typography variant="body2" color="textSecondary" gutterBottom style={{ whiteSpace: 'pre-line' }}>
          {/* t:グループを追加・編集・削除できます。
              この機能を使うことで、メンバーをグループ単位で管理することができます。
              各メンバーをグループに追加したい場合は、メンバー管理画面から行ってください。 */}
          <Trans i18nKey="membershipGroup:management.description" />
        </Typography>

        <Box sx={{ mt:'15px', mb: '25px' }}>
          <Tooltip title={null}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                style={{ marginRight: '20px' }}
              >
                {/* t:追加 */}
                {t("common:button.add")}
              </Button>
            </span>
          </Tooltip>
        </Box>
        {membershipGroupNum === 0 ? (
          <Typography variant="caption" align="left" color="textSecondary">
            {/* t:グループはまだ追加されていません。追加ボタンから設定を追加しましょう。 */}
            <Trans i18nKey="membershipGroup:management.noGroups" />
          </Typography>
        ) : (
          <InfiniteScroll
            dataLength={membershipGroups.length}
            next={onScroll}
            hasMore={hasMore}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <CircularProgress />
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <TableContainer component={Paper}>
              <Table>
                {!isSmallScreen && (
                  <TableHead>
                    <TableRow>
                      {/* t:名前 */}
                      <TableCell>{t("common:info.name")}</TableCell>
                      {/* t:説明 */}
                      <TableCell>{t("membershipGroup:info.description")}</TableCell>
                      {/* t:作成日時 */}
                      <TableCell>{t("common:info.createdAt")}</TableCell>
                      {/* t:更新日時 */}
                      <TableCell>{t("common:info.updatedAt")}</TableCell>
                      {/* t:操作 */}
                      <TableCell>{t("common:info.actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {membershipGroups.map((group) => (
                    <ResponsiveTableRow key={group.id}>
                      <ResponsiveTableRowHeader>{group.name}</ResponsiveTableRowHeader>
                      {/* t:説明 */}
                      <ResponsiveTableCell data-label={t("membershipGroup:info.description")}>{group.description}</ResponsiveTableCell>
                      {/* t:作成日時 */}
                      <ResponsiveTableCell data-label={t("common:info.createdAt")}>{displayTimestamp(group.createdAt)}</ResponsiveTableCell>
                      {/* t:更新日時 */}
                      <ResponsiveTableCell data-label={t("common:info.updatedAt")}>{displayTimestamp(group.updatedAt)}</ResponsiveTableCell>
                      <ResponsiveTableActionFooter>
                        {isAdmin(loginUser) && (
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEditClick(group)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {isAdmin(loginUser) && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteClick(group.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ResponsiveTableActionFooter>
                    </ResponsiveTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        )}
      </AdminContentsWrapper>
      <MembershipGroupFormModal
        open={isModalOpen}
        handleClose={handleModalClose}
        defaultValues={selectedMembershipGroup || {
          name: "",
          description: ""
        }}
      />
    </>
  );
};

export default MembershipGroupListPresenter;

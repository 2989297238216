import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorModal } from '../generic/errorModal.slice';
import { News } from './news.type';
import i18next from 'i18next';
import { Language } from '../setting/setting.constant';

interface NewsState {
  newsList: News[];
  loading: boolean;
  error: string | null;
  currentNews: News | null;
}

const initialState: NewsState = {
  newsList: [],
  loading: false,
  error: null,
  currentNews: null,
};

export const fetchNews = createAsyncThunk<
  News[],
  { limit?: number; offset?: number, lang?: Language } | undefined,
  { rejectValue: string }
>(
  'news/fetchNews',
  async (params = {}, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.get<News[]>('/news', {
        params: {
          limit: params.limit,
          offset: params.offset,
          lang: params.lang,
        },
      });
      return response.data;
    } catch (err) {
      console.debug("/news error", err);
      captureException(err);
      dispatch(showUnknownErrorModal());
      // t:お知らせの取得に失敗しました
      return rejectWithValue(i18next.t("news:fetchError"));
    }
  }
);

export const fetchNewsById = createAsyncThunk(
  'news/fetchNewsById',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.get<News>(`/news/${id}`);
      return response.data;
    } catch (err) {
      console.debug(`/news/${id} error`, err);
      captureException(err);
      dispatch(showUnknownErrorModal());
      // t:お知らせの取得に失敗しました
      return rejectWithValue(i18next.t("news:fetchError"));
    }
  }
);

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.newsList = action.payload;
    },
    appendNews: (state, action: PayloadAction<News[]>) => {
      const newNews = action.payload.filter(
        (news) => !state.newsList.some((existingNews) => existingNews.id === news.id)
      );
      state.newsList = [...state.newsList, ...newNews];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.loading = false;
        state.newsList = action.payload;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(fetchNewsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNews = action.payload;
      })
      .addCase(fetchNewsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
  },
});

export const {
    setNews,
    appendNews,
} = newsSlice.actions;

export default newsSlice.reducer;

import React from 'react';
import {
  Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UploadProgressModalProps {
  progress?: string;
}

const UploadProgressModal: React.FC<UploadProgressModalProps> = ({
  progress,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!!progress}>
      {/* t:ファイルアップロード中 */}
      <DialogTitle>{t('file:uploadingFileTitle')}</DialogTitle>
      <DialogContent>
        {progress}<br/><br/>
        {/* t:※ ファイルのアップロード・解析には数分程度かかる場合がございます。 */}
        {t('file:uploadAnalysisTimeNotice')}
      </DialogContent>
    </Dialog>
  );
};

export default UploadProgressModal;

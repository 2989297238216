import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Tooltip,
} from '@mui/material';
import { DlpExpressionConfig } from '../dlp.type';
import { createExpressionConfig, updateExpressionConfig } from '../dlp.slice';
import { AppDispatch } from '@/store';
import { DlpPreventType } from '../dlp.constant';
import theme from '@/theme';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';

interface ExpressionConfigFormModalProps {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  defaultValues: DlpExpressionConfig;
}

const ExpressionConfigFormModal: React.FC<ExpressionConfigFormModalProps> = ({
  open,
  handleClose,
  isEdit,
  defaultValues,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, watch, reset, control, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const preventType = watch('preventType');
  const { t } = useTranslation();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (data: DlpExpressionConfig) => {
    if (isEdit) {
      await dispatch(updateExpressionConfig(data));
    } else {
      await dispatch(createExpressionConfig(data));
    }
    handleClose();
  };

  const replaceWordRules = {
    // t:置換ワードは必須です
    required: preventType === DlpPreventType.REPLACE ? t('dlp:replaceWordRequired') : false,
    // t:置換ワードは{{max}}文字以内で入力してください
    maxLength: { value: 50, message: t('dlp:replaceWordMaxLength', {max: 50}) },
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {/* t:ブロッカー設定更新 / ブロッカー設定追加 */}
      <DialogTitle>{isEdit ? t('dlp:updateBlockerSetting') : t('dlp:addBlockerSetting')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
            <WaTextField
              // t:設定名
              label={t("dlp:settingName")}
              variant="outlined"
              fullWidth
              {...register('name', {
                // t:設定名は必須です
                required: t('dlp:settingNameRequired'),
                // t:設定名は{{max}}文字以内で入力してください
                maxLength: { value: 50, message: t('dlp:settingNameMaxLength', {max:50}) },

              })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormControl>

          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.expression)}>
            <WaTextField
              // t:対象ワード
              label={t("dlp:targetWord")}
              variant="outlined"
              fullWidth
              {...register('expression', {
                // t:対象ワードは必須です
                required: t('dlp:targetWordRequired'),
                // t:対象ワードは{{max}}文字以内で入力してください
                maxLength: { value: 50, message: t('dlp:targetWordMaxLength', {max: 50}) },
              })}
              error={!!errors.expression}
              helperText={errors.expression?.message}
            />
          </FormControl>

          <FormControl component="fieldset" error={Boolean(errors.preventType)} style={{ marginTop: '20px' }}>
            {/* t:動作 */}
            <FormLabel component="legend">{t('dlp:action')}</FormLabel>
            <Controller
              name="preventType"
              control={control}
              // t:動作の選択は必須です
              rules={{ required: t('dlp:actionSelectionRequired') }}
              render={({ field }) => (
                <RadioGroup row {...field} >
                  {/* t:対象ワードを送信できなくなります。 */}
                  <Tooltip title={t('dlp:tooltip.expression.prohibit')}>
                    {/* t:禁止 */}
                    <FormControlLabel value={DlpPreventType.PROHIBIT} control={<Radio />} label={t('dlp:prohibit')} />
                  </Tooltip>
                  {/* t:対象ワードを送信する際に警告を表示します。 */}
                  <Tooltip title={t('dlp:tooltip.expression.warn')}>
                    {/* t:警告 */}
                    <FormControlLabel value={DlpPreventType.WARN} control={<Radio />} label={t('dlp:warn')} />
                  </Tooltip>
                  {/* t:対象ワードを別のワードに差し替えて生成AIモデルに送信します。 */}
                  <Tooltip title={t('dlp:tooltip.expression.replace')}>
                    {/* t:置換 */}
                    <FormControlLabel value={DlpPreventType.REPLACE} control={<Radio />} label={t('dlp:replace')} />
                  </Tooltip>

                </RadioGroup>
              )}
            />
            {errors.preventType && <FormHelperText>{errors.preventType.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" style={{ marginTop: '20px' }} error={Boolean(errors.replaceWord)}>
            <WaTextField
              // t:置換ワード
              label={t("dlp:replaceWord")}
              variant="outlined"
              fullWidth
              disabled={preventType !== DlpPreventType.REPLACE}
              {...register('replaceWord', replaceWordRules)}
              error={!!errors.replaceWord}
              helperText={errors.replaceWord?.message}
            />
            <FormHelperText style={{ color: preventType === DlpPreventType.REPLACE ? "inherit" : theme.palette.grey[500] }}>
              {/* t:どのようなワードに置換するかを入力してください。禁止しているワードがどのような種類のものなのかを指定すると、置換後も生成AIが適切に動作しやすくなります。(例：ワード「WorkAIzer」 → 置換文字「サービス名」) */}
              {t("dlp:replaceWordHelperText")}
            </FormHelperText>
          </FormControl>

          <DialogActions style={{ marginTop: '20px' }}>
            {/* t:キャンセル */}
            <Button onClick={handleClose}>{t("common:button.cancel")}</Button>
            {/* t:保存 */}
            <Button type="submit" color="primary">{t("common:button.save")}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExpressionConfigFormModal;

import React, { useEffect } from 'react';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Divider, Grid, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    hbspt: any; // `any`型を使用していますが、より具体的な型定義に置き換えることが可能です。
  }
}
const UpgradeContactForm: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: t("subscription:upgradeContact.hubSpotForm.portalId"),
          formId: t("subscription:upgradeContact.hubSpotForm.formId"),
          target: '#hubSpotForm'
        });
      }
    });
  }, []);

  return (
    <>
      {/* t:アップグレードのお問い合わせ */}
      <AdminPageTitle title={t("subscription:upgradeContact.title")} />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', whiteSpace: 'pre-line' }}>
          {/* t:アップグレードに関するお問い合わせは以下のフォームよりお願いいたします。
             お問い合わせいただいた内容については、担当者よりご連絡いたします。 */}
          {t("subscription:upgradeContact.description")}
        </Typography>
        <Typography variant="caption" color="textSecondary" style={{ display:"block", marginBottom: '25px' }}>
          {/* t:※ フォームが表示されない場合はページを再読み込みしてください */}
          {t("subscription:upgradeContact.formLoadingNote")}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div id="hubSpotForm" className="hubSpotForm"></div>
          </Grid>
        </Grid>
      </AdminContentsWrapper>
    </>
  );
};

export default UpgradeContactForm;

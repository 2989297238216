import React, { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import { IChangeEvent } from '@rjsf/core';
import { QuickPromptWithVersion } from '../quickPrompt.type';
import { ThreadContentsWrapper, ThreadTitle } from '../../thread/presenters/ThreadPresenter';
import theme from '@/theme';
import SelectAiModelCodes from '../components/SelectAiModelCodes';
import { FileUpload } from '../../file/file.type';
import TextareaWithToolbar from '../components/TextareaWithToolbar';
import { getFormSchema, getFormSchemaTitle, getTextareaWithToolbarKey, getUiSchema } from '../quickPrompt.utils';
import { RootState, useAppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { getUiFlagValue } from '../../auth/auth.type';
import { UI_FLAG_FILE_UPLOAD_CONFIRM_KEY, UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW } from '../../file/file.constant';
import { updateUiMembershipFlag } from '../../auth/auth.slice';
import FileWarningModal from '../../thread/components/MessageForm/FileWarningModal';
import UploadProgressModal from '../../file/components/UploadProgressModal';
import { useTranslation } from 'react-i18next';

const FormArea = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-bottom: 70px;

  .qp-form-section-header {
    h5 {
      font-size: 1.0em;
    }
  }

  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }

  .MuiGrid-item:has(.qp-form-lg-4) {
    ${() => theme.breakpoints.up('md')} {
      flex-basis: 33%;
    }
  }
`;

const FixedButtonContainer = styled(Box)<{ optionopened: string | undefined }>`
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  position: relative;
  ${({ optionopened }) =>
    optionopened === 'true' &&
    `
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${theme.palette.background.paper};
    padding: 16px;

    ${theme.breakpoints.up('md')} {
      left: 275px;
      right: 240px;
    }
  `}
`;

const FixedButtonContainer2 = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }
`;

const TrySendHint = styled(Typography)`
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: rgb(229, 246, 253);
  padding: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  filter: drop-shadow(0 6px 3px rgba(0, 0, 0, 0.1));
  animation: fadeInOut 4s ease-in-out infinite;
  margin-bottom: 30px;
  color: ${theme.palette.primary.main};
  font-weight: bold;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -15px;
    right: 20px;
    border-style: solid;
  }

  &::before {
    border-width: 16px 16px 0 16px;
    border-color: rgb(229, 246, 253) transparent transparent transparent;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  }

  &::after {
    border-width: 15px 15px 0 15px;
    border-color: rgb(229, 246, 253) transparent transparent transparent;
    bottom: -14px;
  }

  @keyframes fadeInOut {
    0% {
      transform: translateY(-5px);
    }
    10% {
      transform: translateY(0);
    }
    90% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
`;

interface QpFormLoadingProps {
  loading: true;
}

interface QpFormLoadedProps {
  loading: false;
  qp: QuickPromptWithVersion;
  formData: object;
  initialFile?: FileUpload;
  selectedFile: File | FileUpload | undefined;
  onSelectFile: (file: File | undefined) => void;
  onGenerating: boolean;
  optionOpened: boolean;
  showTrySend: boolean;
  hideSendButton: boolean;
  handleFormChange: (data: IChangeEvent) => void;
  handleSendMessage: (data: IChangeEvent, e: React.FormEvent) => void;
  fileUploadProgress?: string;
}

type QpFormPresenterProps = QpFormLoadingProps | QpFormLoadedProps;


const QpFormPresenter: React.FC<QpFormPresenterProps> = (props) => {
  const dispatch = useAppDispatch();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [openFileWarning, setOpenFileWarning] = useState(false);
  const [dontShowAgainFileWarning, setDontShowAgainFileWarning] = useState(false);
  const fileUploadConfirm = getUiFlagValue(loginUser, UI_FLAG_FILE_UPLOAD_CONFIRM_KEY);
  const [pendingData, setPendingData] = useState<IChangeEvent | null>(null);
  const [pendingEvent, setPendingEvent] = useState<React.FormEvent | null>(null);
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }


  const {
    qp,
    formData,
    initialFile,
    selectedFile,
    onSelectFile,
    onGenerating,
    optionOpened,
    showTrySend,
    handleFormChange,
    handleSendMessage,
    fileUploadProgress,
  } = props;

  const onSubmit = (data: IChangeEvent, e: React.FormEvent) => {
    const formData = data.formData;

    const key = getTextareaWithToolbarKey(qp);
    if (key && !formData[key] && !selectedFile) {
      const label = getFormSchemaTitle(qp, key);
      // t:{{label}}は必須です
      alert(t('quickPrompt:form.validation.required', { label }));
      return;
    }

    if (selectedFile && fileUploadConfirm !== UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW) {
      setPendingData(data);
      setPendingEvent(e);
      setOpenFileWarning(true);
    } else {
      handleSendMessage(data, e);
    }
  }

  const onCloseFileWarning = () => {
    setOpenFileWarning(false);
  }

  const onConfirmFileWarning = () => {
    if (dontShowAgainFileWarning) {
      dispatch(updateUiMembershipFlag({
        key: UI_FLAG_FILE_UPLOAD_CONFIRM_KEY,
        value: UI_FLAG_FILE_UPLOAD_CONFIRM_VAL_DONT_SHOW,
      }));
    }

    setOpenFileWarning(false);
    if (pendingData && pendingEvent) {
      handleSendMessage(pendingData, pendingEvent);
    }
  }


  return (
    <>
      <ThreadTitle variant="h5">{qp.name}</ThreadTitle>
      <Divider />
      <ThreadContentsWrapper>
        <FormArea>
          <Form
            schema={getFormSchema(qp)}
            uiSchema={getUiSchema(qp, initialFile)}
            validator={validator}
            onChange={handleFormChange}
            onSubmit={onSubmit}
            formData={formData}
            widgets={{
              textareaWithToolbar: TextareaWithToolbar,
              selectAiModelCodes: SelectAiModelCodes,
            }}
            formContext={{ handleFileChange: onSelectFile }}
          >
            {!props.hideSendButton ?
              <FixedButtonContainer optionopened={optionOpened ? optionOpened.toString() : undefined}>
                <FixedButtonContainer2>
                  {showTrySend && (
                    <TrySendHint variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      {/* t:サンプル入力が用意されています。
                         そのまま送信ボタンを押して、機能を体験してみましょう。 */}
                      {t("quickPrompt:recommendedList.trySendHint")}
                    </TrySendHint>
                  )}
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={onGenerating}
                    endIcon={
                      onGenerating ? (
                        <CircularProgress size={24} color="secondary" />
                      ) : (
                        <SendIcon />
                      )
                    }
                  >
                    {/* t:送信 */}
                    {t('common:button.send')}
                  </Button>
                </FixedButtonContainer2>
              </FixedButtonContainer> :
              <Box></Box>
            }
          </Form>
        </FormArea>
      </ThreadContentsWrapper>
      <FileWarningModal
         open={openFileWarning}
         onClose={onCloseFileWarning}
         onConfirm={onConfirmFileWarning}
         dontShowAgain={dontShowAgainFileWarning}
         setDontShowAgain={setDontShowAgainFileWarning}
      />
      <UploadProgressModal progress={fileUploadProgress} />
    </>
  );
};

export default QpFormPresenter;

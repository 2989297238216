import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import { ResReports } from '../report.api';
import { GraphType } from '../report.constant';
import { UnitType } from '../report.type';
import { getDateStrForGraph } from '../report.utils';
import { useTranslation } from 'react-i18next';

interface Props {
  report: ResReports;
  selectedGraph: GraphType;
  unitType: UnitType;
}

const ReportTimelineChart: React.FC<Props> = ({ report, selectedGraph, unitType }) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    switch (selectedGraph) {
      case 'useCount':
        return report.teamUsageReports.map(r => ({
          date: getDateStrForGraph(r.targetOn, unitType),
          threadNum: r.threadNum,
          postCount: r.postCount,
          aiGenerateCount: r.aiGenerateCount,
        }));
      case 'chars':
        return report.teamUsageReports.map(r => ({
          date: getDateStrForGraph(r.targetOn, unitType),
          inputChars: r.inputCharsToAi,
          outputChars: r.outputChars,
          total: r.inputCharsToAi + r.outputChars,
        }));
      case 'credit':
        return report.teamUsageReports.map(r => ({
          date: getDateStrForGraph(r.targetOn, unitType),
          inputCredit: Number(r.inputCredit).toFixed(2),
          outputCredit: Number(r.outputCredit).toFixed(2),
          total: Number(r.totalCredit).toFixed(2),
        }));
      case 'memberNum':
        return report.teamMembershipNumReports.map(r => ({
          date: getDateStrForGraph(r.targetOn, unitType),
          memberNum: r.memberNum,
        }));
      case 'fileNum':
        return report.teamUsageReports.map(r => ({
          date: getDateStrForGraph(r.targetOn, unitType),
          imageCount: r.inputImageCountInView,
          audioCount: r.inputAudioCountInView,
          videoCount: r.inputVideoCountInView,
          documentCount: r.inputDocumentCountInView,
        }));
    }
  }, [report, selectedGraph]);

  const lines = useMemo(() => {
    switch (selectedGraph) {
      case 'useCount':
        return (
          <>
            {/* t:スレッド作成 */}
            <Bar dataKey="threadNum" name={t("reports:common.threadCreation")} fill="#FF6347" />
            {/* t:メッセージ投稿 */}
            <Bar dataKey="postCount" name={t("reports:common.messagePost")} fill="#4682B4" />
            {/* t:AIの回答 */}
            <Bar dataKey="aiGenerateCount" name={t("reports:common.aiResponse")} fill="#3CB371" />
          </>
        );
      case 'chars':
        return (
          <>
            {/* t:出力 */}
            <Bar stackId="a" dataKey="outputChars" name={t("reports:common.outputOnly")} fill="#3CB371" />
            {/* t:入力 */}
            <Bar stackId="a" dataKey="inputChars" name={t("reports:common.inputOnly")} fill="#FF6347" />
          </>
        );
      case 'credit':
        return (
          <>
            {/* t:出力 */}
            <Bar stackId="a" dataKey="outputCredit" name={t("reports:common.outputOnly")} fill="#3CB371" />
            {/* t:入力 */}
            <Bar stackId="a" dataKey="inputCredit" name={t("reports:common.inputOnly")} fill="#FF6347" />
          </>
        );
      case 'memberNum':
        {/* t:メンバー数 */}
        return <Bar dataKey="memberNum" name={t("reports:common.memberCount")} fill="#4682B4" />;
      case 'fileNum':
        return (
          <>
            {/* t:画像 */}
            <Bar dataKey="imageCount" name={t("reports:chart.labels.image")} fill="#FF6347" />
            {/* t:音声 */}
            <Bar dataKey="audioCount" name={t("reports:chart.labels.audio")} fill="#4682B4" />
            {/* t:PDF */}
            <Bar dataKey="documentCount" name={t("reports:chart.labels.pdf")} fill="#3CB371" />
          </>
        );
    }
  }, [selectedGraph]);

  const CustomTooltip = ({ active, payload, label }: { active?: boolean, payload?: any[], label?: string }) => {
    if (active && payload && payload.length) {
      const isStack = ['chars', 'credit'].includes(selectedGraph);
      const items = isStack ? payload.slice().reverse() : payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <p className="label" style={{ margin: 0, marginBottom:"10px", }}>{`${label}`}</p>
          {isStack && (
            // t:合計：{{total}}
            <p className="total" style={{ margin: 0, marginBottom: "5px" }}>{t('reports:chart.tooltip.total', { total: payload[0].payload.total })}</p>
          )}
          {items.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color, margin: 0, marginBottom: "5px" }}>
              {/* t:{{name}}：{{value}} */}
              {t('reports:chart.tooltip.item', { name: entry.name, value: entry.value })}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <XAxis dataKey="date" />
        <YAxis allowDecimals={selectedGraph !== 'memberNum'} />
        <Tooltip content={<CustomTooltip />} />
        {lines}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportTimelineChart;

import PickerPage from "./picker";
import { TiptapPage } from "./tiptap";
import { ScrollPage } from "./scroll";
import { QPPage } from "./qp";
import DummyLoginPage from "./dummy-login";
import SentryPage from "./sentry";
import PartsBox from "./parts-box";
import ApiErrorPage from "./api-error";
import { Link, Route } from "react-router-dom";
import SlidePage from "./slide";
import ListDriveFilesPage from "./list-drive-files";
import ShowFileContentPage from "./show-file-content";
import DebugMarkdown from "./markdown";
import { DebugFileUploadPage } from "./file-upload";
import DebugI18nPage from "./i18n";

const debugRoutes = [
  { path: 'dummy-login', element: <DummyLoginPage /> },
  { path: "picker", element: <PickerPage /> },
  { path: "tiptap", element: <TiptapPage /> },
  { path: "scroll", element: <ScrollPage /> },
  { path: "qp", element: <QPPage /> },
  { path: "sentry", element: <SentryPage /> },
  { path: "parts-box", element: <PartsBox /> },
  { path: "api-error", element: <ApiErrorPage /> },
  { path: "slide", element: <SlidePage /> },
  { path: "list-drive-files", element: <ListDriveFilesPage /> },
  { path: "show-file-content", element: <ShowFileContentPage /> },
  { path: "markdown", element: <DebugMarkdown /> },
  { path: "file-upload", element: <DebugFileUploadPage /> },
  { path: "i18n", element: <DebugI18nPage />}
];

export default function DebugRouting() {
  return (
    <Route path="/debug">
      <Route path="" element={<div style={{padding:10, paddingTop: 100}}>
        debug menu
        {debugRoutes.map(({path}) => (
          <div key={path}>
            <Link to={path}>{path}</Link>
          </div>
        ))}
      </div>} />
      {debugRoutes.map(({path, element}) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Route>
  )
}

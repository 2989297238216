import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import apiAxios from '@/lib/axios';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';
import { getLanguageLabel } from '../setting.utils';

interface SelectDisplayLanguageProps {
  slim?: boolean;
}

const SelectDisplayLanguage: React.FC<SelectDisplayLanguageProps> = ({ slim }) => {
  const { i18n } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  const lang = i18n.language;
  if (lang != "en" && lang != "ja") {
    i18n.changeLanguage("en");
  }

  const handleLanguageChange = async (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value as string;
    i18n.changeLanguage(newLanguage);
    if (loginUser) {
      try {
        await apiAxios.put('/setting/locale', { language: newLanguage });
      } catch (error) {
        console.error('言語設定の更新に失敗しました', error);
      }
    }
  };

  return (
    <FormControl variant={slim ? "standard" : "outlined"} size="small" style={{ minWidth: 100 }}>
      <Select
        value={i18n.language}
        onChange={handleLanguageChange}
        sx={slim ? {
          '& .MuiSelect-select': {
            padding: '7px 24px 4px 8px',
          },
        }: {}}
      >
        <MenuItem value="en">{getLanguageLabel('en')}</MenuItem>
        <MenuItem value="ja">{getLanguageLabel('ja')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectDisplayLanguage;

import { TFunction } from "i18next";

export enum MembershipRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  NORMAL = 'normal'
}

export const getMembershipRoleLabel = (t: TFunction, role: MembershipRole): string => {
  switch (role) {
    case MembershipRole.SUPER_ADMIN:
      // t:特権管理者
      return t("membership:roleLabel.superAdmin");
    case MembershipRole.ADMIN:
      // t:管理者
      return t("membership:roleLabel.admin");
    case MembershipRole.NORMAL:
      // t:一般
      return t("membership:roleLabel.normal");
    default:
      return '';
  }
};

export const getMembershipRoleDescription = (t: TFunction, role: MembershipRole): string => {
  switch (role) {
    case MembershipRole.SUPER_ADMIN:
      // t:通常機能と管理機能を使え、また他の管理者を追加することができます。
      return t("membership:roleDescription.superAdmin");
    case MembershipRole.ADMIN:
      // t:通常機能と管理機能を使えます。ただし他の管理者は追加できません。
      return t("membership:roleDescription.admin");
    case MembershipRole.NORMAL:
      // t:基本機能のみを使えます。管理機能を使うことはできません。
      return t("membership:roleDescription.normal");
    default:
      return '';
  }
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { Box, Button, Divider, Typography } from '@mui/material';
import { displayDate, displayMonth, displayTimestamp, removeDecimalPart } from '@/lib/utils';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import SvTeamFormModal from '../components/SvTeamFormModal';
import { getSpecialCreditLabel } from '@/components/features/usage/usage.util';
import { useTranslation } from 'react-i18next';
import { getPlanTypeLabel } from '@/components/features/subscription/subscription.constant';

const SvTeamSummaryContainer: React.FC = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  if (!team) {
    return null;
  }

  return (
    <>
      {/* t:サマリー */}
      <SupervisorPageTitle title={t("teams:teamSummary.title")} />
      <Divider />
      <SupervisorContentsWrapper>

        <Box sx={{ display: 'flex', flexDirection: 'row', border: '1px dashed grey', p: 2 }}>
          <Box sx={{ flex: 1, borderRight: '1px dashed grey', pr: 2 }}>
            <Typography variant="h6" gutterBottom>
              {/* t:基本情報 */}
              {t("teams:teamSummary.basicInfo.title")}
              <Button
                variant="text"
                color="primary"
                style={{ marginLeft: '20px' }}
                onClick={() => setIsModalOpen(true)}
              >
                {/* t:編集 */}
                {t("common:button.edit")}
              </Button>
            </Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:チームID */}
              {t("teams:teamSummary.basicInfo.teamId")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.id}</Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:チーム名 */}
              {t("teams:teamSummary.basicInfo.teamName")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.name}</Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:会社名 */}
              {t("teams:teamSummary.basicInfo.companyName")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.companyName}</Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:ドメイン */}
              {t("teams:teamSummary.basicInfo.domain")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.primaryDomain}</Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:Salesforce商談URL */}
              {t("teams:teamSummary.basicInfo.salesforceUrl")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              { team.salesforceUrl &&
                <a href={team.salesforceUrl} target="_blank">
                  {team.salesforceUrl}
                </a>
              }
            </Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:登録日時 */}
              {t("teams:teamSummary.basicInfo.registrationDate")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{displayTimestamp(team.createdAt)}</Typography>

            <Typography variant="caption" gutterBottom>
              {/* t:登録ユーザー */}
              {t("teams:teamSummary.basicInfo.registrationUser")}
            </Typography>
            <Box sx={{ mb: 2 }}>
              {team.creator ?
                <>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {team.creator.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ({team.creator.email})
                  </Typography>
                </> :
                <Typography variant="body1">
                  {/* t:不明、または、削除済 */}
                  {t("teams:teamSummary.basicInfo.unknown")}
                </Typography>
              }
            </Box>

            <Typography variant="caption" gutterBottom>
              {/* t:メモ */}
              {t("teams:common.memo")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap'}}>{team.memo}</Typography>
          </Box>

          <Box sx={{ flex: 1, pl: 2 }}>
            <Box sx={{ borderBottom: '1px dashed grey', pb: 2 }}>
              <Typography variant="h6" gutterBottom>
                {/* t:特権管理者 */}
                {t("teams:teamSummary.superAdmins.title")}
              </Typography>
              <Box>
                {team.memberships.map((membership) => (
                  <Box key={membership.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      {membership.user.name ? membership.user.name : <Typography variant="caption">{/* t:招待中 */}{t("teams:teamSummary.superAdmins.invited")}</Typography>}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ({membership.user.email})
                    </Typography>
                  </Box>
                ))}
                {/* t:※ 最大5人まで表示 */}
                <Typography variant="caption">{t("teams:teamSummary.superAdmins.maxDisplay")}</Typography>
              </Box>

            </Box>
            <Box sx={{ borderBottom: '1px dashed grey', pb: 2, pt: 2  }}>
              <Typography variant="h6" gutterBottom>
                {/* t:契約内容 */}
                {t("teams:teamSummary.contract.title")}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {/* t:プラン */}
                {t("teams:common.plan")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {getPlanTypeLabel(t, team.currentSubscription.plan.type)}
                {!!team.scheduledSubscription && (
                  <Typography variant="caption" color="error" component="span">
                    {/* t: (変更予約あり) */}
                    {t("teams:teamSummary.contract.changeReserved")}
                  </Typography>
                )}
              </Typography>

              <Typography variant="caption" gutterBottom>
                {/* t:現在の期間 */}
                {t("teams:teamSummary.contract.currentPeriod")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
              </Typography>

              <Typography variant="caption" gutterBottom>
                {/* t:ライセンス数 */}
                {t("teams:common.license")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team.currentSubscription.currentLicense?.amount}
                {!!team.currentSubscription.scheduledLicense && (
                  <Typography variant="caption" color="error" component="span">
                    {/* t: (変更予約あり) */}
                    {t("teams:teamSummary.contract.changeReserved")}
                  </Typography>
                )}
              </Typography>
            </Box>

            <Box sx={{ pt: 2 }}>
              <Typography variant="h6" gutterBottom>
                {/* t:最新の利用状況 */}
                {t("teams:teamSummary.latestUsage.title")}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {/* t:対象月 */}
                {t("teams:teamSummary.latestUsage.targetMonth")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {displayMonth(team.currentSubscription.latestMonthTerm[0])}&nbsp;&nbsp;
                <Typography variant="caption" >
                  {/* t:{{startOn}} 〜 {{endOn}} */}
                  {t("teams:teamSummary.latestUsage.period", {
                    startOn: displayDate(team.currentSubscription.latestMonthTerm[0]),
                    endOn: displayDate(team.currentSubscription.latestMonthTerm[1])
                  })}
                </Typography>
              </Typography>

              <Typography variant="caption" gutterBottom>
                {/* t:クレジット利用率 */}
                {t("teams:teamSummary.latestUsage.creditUsageRate")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {/* t:{{rate}}% */}
                { removeDecimalPart(team.creditUsageRate, 3) }%
              </Typography>

              <Typography variant="caption" gutterBottom>
                {/* t:残りクレジット */}
                {t("teams:teamSummary.latestUsage.remainingCredit")}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {
                  getSpecialCreditLabel(t, team.creditRemaining) ||
                  <>
                    {removeDecimalPart(team.creditRemaining)}
                    &nbsp;&nbsp;／&nbsp;&nbsp;
                    {removeDecimalPart(team.quota.totalCredit)}
                  </>
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </SupervisorContentsWrapper>
      <SvTeamFormModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        defaultValues={team}
      />
    </>
  );
};

export default SvTeamSummaryContainer;

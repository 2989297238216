import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import { Button, CircularProgress, FormControl } from '@mui/material';
import styled from '@emotion/styled';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';

const schema = z.object({
  name: z.string().nonempty().max(100),
});

type FormData = z.infer<typeof schema>;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
`;

const StyledTextField = styled(WaTextField)`
  width: 100%;

  .MuiOutlinedInput-root {
    borderRadius: 8px;
  }
`;

interface TeamFormFieldsProps {
  defaultValues?: FormData;
  onSubmit: SubmitHandler<FormData>;
  isLoading: boolean;
  submitButtonText: string;
}

const TeamFormFields: React.FC<TeamFormFieldsProps> = ({
  defaultValues,
  onSubmit,
  isLoading,
  submitButtonText,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
        <StyledTextField
          // t:チーム名
          label={t("team:form.teamName")}
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={24} /> : null}
      >
        {submitButtonText}
      </Button>
    </StyledForm>
  );

};

export default TeamFormFields;

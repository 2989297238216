import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentSubscription } from '../../auth/auth.type';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Box, Divider, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { displayDate } from '@/lib/utils';
import { getPlanTypeLabel, PlanType } from '../subscription.constant';
import { useTranslation } from 'react-i18next';

const CurrentSubscriptionDetail: React.FC = () => {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  if (!currentSub) {
    return;
  }
  const term = currentSub.latestContractTerm

  return (
    <>
      {/* t:現在の契約内容 */}
      <AdminPageTitle title={t("subscription:currentSubscription.title")} />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
          {/* t:現在の契約内容を確認できます。 */}
          {t("subscription:currentSubscription.description")}
        </Typography>
        <Box mb={4}>
          <Typography variant="caption" gutterBottom>
            {/* t:プラン */}
            {t("subscription:currentSubscription.plan")}
          </Typography>
          <Typography variant="body1">
            {getPlanTypeLabel(t, currentSub.plan.type)}
          </Typography>
          <br/>
          { currentSub.plan.type !== PlanType.STARTER && <>
            <Typography variant="caption" gutterBottom>
              {/* t:現在の期間 */}
              {t("subscription:currentSubscription.currentPeriod")}
            </Typography>
            <Typography variant="body1">
              {/* t:{{startDate}} ~ {{endDate}} */}
              {t('subscription:currentSubscription.periodFormat', { startDate: displayDate(term[0]), endDate: displayDate(term[1]) })}
            </Typography>
            <br/>
            <Typography variant="caption" gutterBottom>
              {/* t:ライセンス数 */}
              {t("subscription:currentSubscription.licenseCount")}
            </Typography>
            <Typography variant="body1">
              {/* t:{{amount}}ライセンス */}
              {t('subscription:currentSubscription.licenseCountFormat', { amount: currentSub.currentLicense.amount })}
            </Typography>
          </>}

        </Box>
      </AdminContentsWrapper>
    </>
  );
};

export default CurrentSubscriptionDetail;

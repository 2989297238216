import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Tooltip,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { MembershipCreate, MembershipUpdate } from '../membership.type';
import { createMembership, updateMembership } from '../membership.slice';
import { AppDispatch } from '@/store';
import { MembershipRole, getMembershipRoleDescription, getMembershipRoleLabel } from "../membership.constant";
import { isSuperAdmin } from '@/components/features/auth/auth.type';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { MembershipGroup } from '../../membership-group/membership-group.type';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';


type MembershipSave = MembershipCreate | MembershipUpdate;

interface MembershipFormModalProps {
  open: boolean;
  handleClose: () => void;
  defaultValues: MembershipSave;
  membershipGroups: MembershipGroup[];
}

const MembershipFormModal: React.FC<MembershipFormModalProps> = ({
  open,
  handleClose,
  defaultValues,
  membershipGroups,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if(!loginUser) {
    return null;
  }

  // 編集可否か
  const isEdit = 'id' in defaultValues;

  const onSubmit = async (data: MembershipSave) => {
    setIsLoading(true);
    try {
      if (isEdit) {
        await dispatch(updateMembership(data as MembershipUpdate));
      } else {
        await dispatch(createMembership(data as MembershipCreate));
      }
    }
    catch (error) {
      console.error('MembershipFormModal onSubmit error:', error);
    }
    finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const roleOptions = isSuperAdmin(loginUser)
    ? Object.values(MembershipRole).map((role) => ({
        label: getMembershipRoleLabel(t, role),
        value: role,
        tooltip: getMembershipRoleDescription(t, role)
      }))
    : [
        {
          label: getMembershipRoleLabel(t, MembershipRole.NORMAL),
          value: MembershipRole.NORMAL,
          tooltip: getMembershipRoleDescription(t, MembershipRole.NORMAL)
        },
      ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {/* t:メンバー更新/メンバー追加 */}
      <DialogTitle>{isEdit ? t("membership:form.title.edit") : t("membership:form.title.add")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isEdit && (
            <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.emails)}>
              <WaTextField
                // t:メールアドレス
                label={t("membership:form.label.email")}
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('emails', {
                  // t:メールアドレスは必須です
                  required: t("membership:form.validation.email.required"),
                  validate: (value: string) => {
                    const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

                    const invalidEmails = emails.filter(email => !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)));
                    if (invalidEmails.length > 0) {
                      // t:正しいメールアドレス形式で入力してください
                      return t("membership:form.validation.email.invalid");
                    }
                    const longUsernames = emails.filter(email => {
                      const [username] = email.split('@');
                      return username.length > 37;
                    });
                    if (longUsernames.length > 0) {
                      // t:ユーザー名(@の左の部分)は{{max}}文字以内で入力してください
                      return t("membership:form.validation.email.username_length", {max: 37});
                    }
                    return true;
                  },
                })}
                error={!!errors.emails}
                helperText={errors.emails?.message}
              />
              {/* t:※ カンマ区切りで複数入力可能です。 */}
              <FormHelperText>{t("membership:form.helper.email_multiple")}</FormHelperText>
            </FormControl>
          )}
          { membershipGroups.length > 0 &&
            <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.groupId)} style={{ marginTop: '20px' }}>
              {/* t:所属グループ */}
              <FormLabel component="legend">{t("membership:form.label.group")}</FormLabel>
              <Controller
                name="groupId"
                control={control}
                defaultValue={defaultValues.groupId || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                  >
                    {/* t:未所属 */}
                    <MenuItem value="">{t("membership:form.group.unassigned")}</MenuItem>
                    {membershipGroups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          }
          {isSuperAdmin(loginUser) && (
            <FormControl component="fieldset" error={Boolean(errors.role)} style={{ marginTop: '20px' }}>
              {/* t:ロール */}
              <FormLabel component="legend">{t("membership:form.label.role")}</FormLabel>
              <Controller
                name="role"
                control={control}
                // t:ロールの選択は必須です
                rules={{ required: t("membership:form.validation.role.required") }}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {roleOptions.map((option) => (
                      <Tooltip title={option.tooltip} key={option.value} >
                        <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                      </Tooltip>
                    ))}
                  </RadioGroup>
                )}
              />
              {errors.role && <FormHelperText>{errors.role.message}</FormHelperText>}
            </FormControl>
          )}
          <DialogActions style={{ marginTop: '20px' }}>
            {/* t:キャンセル */}
            <Button onClick={handleClose}>{t("common:button.cancel")}</Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {/* t:保存 */}
              {isLoading ? <CircularProgress size={24} /> : t("common:button.save")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipFormModal;

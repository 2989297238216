import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import {
  Grid,
  Button,
  Box,
  Divider,
  Alert,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// V3をつけている理由 (ref) https://github.com/date-fns/date-fns/issues/3638#issuecomment-2164467791
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ja } from 'date-fns/locale/ja';
import { enUS } from 'date-fns/locale/en-US';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { formatApiDateStr } from '@/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentSubscription } from '../../auth/auth.type';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const schema = z.object(
  {
    fromDate: z.date(),
    toDate: z.date(),
    term: z.string().optional().nullable(),
  }
)
.superRefine((data, ctx) => {
  const today = new Date();
  if (data.fromDate > today || data.toDate > today) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:未来の日付は選択できません
      message: i18next.t('vault:csvDownload.error.futureDate'),
      path: ['term'],
    });
  }

  if (data.fromDate > data.toDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:期間Fromは期間To以前である必要があります
      message: i18next.t('vault:csvDownload.error.invalidDateRange'),
      path: ['term'],
    });
  }

  const diffTime = Math.abs(data.toDate.getTime() - data.fromDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays > 31) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:期間は1ヶ月以内でご指定ください。
      message: i18next.t('vault:csvDownload.error.periodTooLong'),
      path: ['term'],
    });
  }
});

type FormData = z.infer<typeof schema>;

interface Props {
  title: string,
  description: string,
  description2?: string,
  onDownload: (fromDate: string, toDate: string) => void;
}

const SharedCSVDownloadPresenter: React.FC<Props> = ({ title, description, description2, onDownload }) => {
  const { t, i18n } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1, new Date().getDate() + 1)),
      toDate: new Date(new Date().setDate(new Date().getDate())),
    },
  });

  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = currentSub?.plan.type === PlanType.STARTER


  const showError = () => {
    const message = errors.fromDate?.message || errors.toDate?.message || errors.term?.message;
    return message && (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Alert severity="error">
          {message}
        </Alert>
      </Box>
    );
  }

  const onSubmit = async (data: FormData) => {
    onDownload(formatApiDateStr(data.fromDate), formatApiDateStr(data.toDate));
  };

  let locale = enUS;
  if (i18n.language == 'ja') {
    locale = ja;
  }

  return <>
    <AdminPageTitle title={title} />
    <Divider />
    <AdminContentsWrapper>
      { isStarter && <Box sx={{mb: 2}}>
        <Typography variant="caption" color="warning.main" style={{ whiteSpace: 'pre-line' }}>
          {/*
            t: ※ Starterプランではお試しでVault機能が利用可能となっております。
               有料化の際はBusinessプランから本機能をご利用いただけます。是非ご検討ください。
          */}
          {t("vault:starterPlan.notice")}
        </Typography>
      </Box>}

      <Typography variant="body2" color="textSecondary">
        {description}
        {description2 && <>
            <br/>{description2}
        </>}
      </Typography>

      <Box sx={{mt: 5, maxWidth: 400}}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      // t:期間From
                      label={t('vault:csvDownload.form.dateFrom')}
                      {...field}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: (!!errors.fromDate || !!errors.term),
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      // t:期間To
                      label={t('vault:csvDownload.form.dateTo')}
                      {...field}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: (!!errors.toDate || !!errors.term),
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {showError()}
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                {/* t:CSVダウンロード */}
                {t('vault:csvDownload.form.submit')}
              </Button>
            </Box>
          </form>
        </LocalizationProvider>
      </Box>
    </AdminContentsWrapper>
  </>;
};

export default SharedCSVDownloadPresenter;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { RightSideBarWrapper } from '@/components/ui/StyledComponents';
import { TeamWithSubscription } from '../../team/team.type';
import { ThreadWithUsage } from '../thread.type';
import { displayTimestamp, formatDecimal } from '@/lib/utils';
import LatestNewsPanel from '../../news/components/LatestNewsPanel';
import { useTranslation } from 'react-i18next';

const calcUsageStartDateStr : string = import.meta.env.VITE_CALC_USAGE_START_DATE;
const calcUsageStartDate : Date = new Date(calcUsageStartDateStr);

interface Props {
  thread?: ThreadWithUsage | null;
  team: TeamWithSubscription;
  isUserAdmin: boolean;
}

const ThreadRightSidebarPaidPlanPresenter: React.FC<Props> = ({thread}) => {
  const { t } = useTranslation();

  if (!thread) {
    return (
      <RightSideBarWrapper
        sx={{
          paddingLeft: "10px !important",
          paddingRight: "10px !important",
          overflowY: "auto",
          maxHeight: "100vh"
        }}
      >
        <Box mb={4}>
          <LatestNewsPanel />
        </Box>
      </RightSideBarWrapper>
    );
  }

  // スレッドを作成したのが集計開始以前の場合は
  // 統計情報を表示しない
  if (new Date(thread.createdAt) < calcUsageStartDate) {
    return <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="body2" color="text.secondary">
          {/* t:正式リリース前に作成されたスレッドのため、詳細情報の表示はできません。 */}
          {t("thread:errors.legacyThread")}
        </Typography>
      </Box>
    </RightSideBarWrapper>;
  }

  const usage = thread.usage;

  return (
    <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          {/* t:スレッド情報 */}
          {t("thread:info.title")}
        </Typography>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            {/* t:あなたの投稿 */}
            {t("thread:info.yourPosts")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* t:{{postCount}}回 */}
            {t('thread:info.postCount', { postCount: usage.postCount })}<br/>
            {/* t:(合計{{inputCharsInView}}文字) */}
            {t('thread:info.totalInputChars', { inputCharsInView: usage.inputCharsInView })}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            {/* t:AIによる回答 */}
            {t("thread:info.aiResponses")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* t:{{aiGenerateCount}}回 */}
            {t('thread:info.aiGenerateCount', { aiGenerateCount: usage.aiGenerateCount })}<br/>
            {/* t:(合計{{outputChars}}文字) */}
            {t('thread:info.totalOutputChars', { outputChars: usage.outputChars })}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            {/* t:消費クレジット */}
            {t("thread:info.consumedCredits")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* t:{{totalCredit}} クレジット */}
            {t('thread:info.totalCredits', { totalCredit: formatDecimal(usage.totalCredit) })}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            {/* t:作成日 */}
            {t("thread:info.creationDate")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayTimestamp(thread.createdAt)}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            {/* t:更新日 */}
            {t("thread:info.updateDate")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayTimestamp(thread.updatedAt)}
          </Typography>
        </Box>
      </Box>
    </RightSideBarWrapper>
  );
};

export default ThreadRightSidebarPaidPlanPresenter;


import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FileWarningModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dontShowAgain: boolean;
  setDontShowAgain: (value: boolean) => void;
}> = ({ open, onClose, onConfirm, dontShowAgain, setDontShowAgain }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {/* t:ファイル送信の確認 */}
        {t("thread:fileWarning.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
          {/*
            t: 送信前に添付ファイルの内容を今一度ご確認ください。
               個人情報や機密情報が意図せず含まれていないかを十分にご確認いただき、問題がなければご送信ください。
          */}
          {t("thread:fileWarning.content")}
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox checked={dontShowAgain} onChange={(e) => setDontShowAgain(e.target.checked)} />}
          // t:次回から表示しない
          label={t("thread:fileWarning.dontShowAgain")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {/* t:キャンセル */}
          {t("common:button.cancel")}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {/* t:OK */}
          {t("common:button.OK")}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default FileWarningModal;
import React from 'react';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import { RightSideBarWrapper } from '@/components/ui/StyledComponents';
import { TeamWithSubscription } from '../../team/team.type';
import { PLAN_UPGRADE_URL } from '@/common/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  team: TeamWithSubscription;
  isUserAdmin: boolean;
}

const ThreadRightSidebarStarterPresenter: React.FC<Props> = ({team, isUserAdmin}) => {
  const { t } = useTranslation();
  const quota = team.quota;
  const usage = team.usage;
  const currentCount = usage.aiGenerateCount;
  const maxCount = quota.maxAiGenerateCount || 30;
  const usagePercentage = (usage.aiGenerateCount / maxCount) * 100;

  return (
    <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          {/* t:現在のプラン */}
          {t("thread:starterPlan.title")}
        </Typography>
        <Typography variant="subtitle1" color="primary" paragraph>
          {/* t:スタータープラン */}
          {t("thread:starterPlan.planName")}
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            {/* t:利用回数：{{currentCount}} / {{maxCount}} */}
            {t('thread:starterPlan.usage', { currentCount, maxCount })}
          </Typography>
          <LinearProgress variant="determinate" value={usagePercentage} />
          {currentCount >= maxCount && (
            <Typography variant="body2" color="error" mt={1}>
              {/* t:スタータープランの利用可能回数に達しました */}
              {t("thread:starterPlan.limitReached")}
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Typography variant="body2" color="text.secondary">
            {/* t:スタータープランの制限： */}
            {t("thread:starterPlan.restrictions.title")}
          </Typography>
          <ul style={{ paddingLeft: 25 }}>
            <li>
              <Typography variant="body2" color="text.secondary">
                {/* t:{{maxCount}}回まで利用可能 */}
                {t('thread:starterPlan.restrictions.maxUsage', { maxCount })}
              </Typography>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    {/* t:ドメイン単位 */}
                    {t("thread:starterPlan.restrictions.domainBased")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    {/* t:複数のメンションは、その分だけカウント */}
                    {t("thread:starterPlan.restrictions.multiMentionCount")}
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                {/* t:1回あたり{{maxInputCharsLength}}文字まで送信可 */}
                {t('thread:starterPlan.restrictions.charLimit', { maxInputCharsLength: quota.maxInputCharsLength })}
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mt={3}>
          {isUserAdmin ? (
            <>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {/* t:もっと沢山使いたい方、より高度なAIを利用したい方は、プランのアップグレードご検討ください。 */}
                {t("thread:starterPlan.upgrade.adminMessage")}
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                href={PLAN_UPGRADE_URL}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  whiteSpace: 'pre-line',
                }}
              >
                {/* t:アップグレードに関する\nお問い合わせはこちら */}
                {t("thread:starterPlan.upgrade.buttonText")}
              </Button>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {/* t:もっと沢山使いたい方、より高度なAIを利用したい方は、管理者にお問い合わせください。 */}
              {t("thread:starterPlan.upgrade.userMessage")}
            </Typography>
          )}
        </Box>
      </Box>

      {/* <TipsContainer /> */}
    </RightSideBarWrapper>
  );
};

export default ThreadRightSidebarStarterPresenter;

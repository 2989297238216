import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

export interface ErrorModalContent {
  title: string;
  message: string;
  buttonOption?: {
    title: string;
    url: string;
  };
}

interface ErrorModalState {
  content: ErrorModalContent | null;
}

const initialState: ErrorModalState = {
  content: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showUnknownErrorModal: (state) => {
      state.content = {
        title: i18next.t('common:message.unknownError'),
        message: i18next.t('common:message.unknownErrorFull'),
      };
    },
    showErrorModal: (state, action: PayloadAction<ErrorModalContent>) => {
      state.content = action.payload;
    },
    hideErrorModal: (state) => {
      state.content = null;
    },
  },
});

export const { showUnknownErrorModal, showErrorModal, hideErrorModal } = errorSlice.actions;
export default errorSlice.reducer;

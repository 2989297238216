import React from 'react';
import { Typography, Button } from '@mui/material';
import { MessageType } from '../thread.constant';
import CommonPreModal from '@/components/ui/CommonPreModal';
import { useTranslation } from 'react-i18next';

interface ThreadPostWordReplacedInfoProps {
  body: string;
  type: MessageType;
  minimize?: boolean;
}

const ThreadPostWordReplacedInfo: React.FC<ThreadPostWordReplacedInfoProps> = ({ body, type, minimize = false }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  // 中括弧で囲まれたテキストを解析し、太文字色付きで表示するための関数
  const renderContent = (text: string) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) { // 中括弧で囲まれた部分
        return <span key={index} style={{ fontWeight: 'bold', color: 'red' }}>{'{{' + part + '}}'}</span>;
      } else {
        return part; // 通常のテキスト
      }
    });
  };

  const button = (
    <Button
      sx={minimize ? {lineHeight:1} : {}}
      variant="text"
      color="info"
      size="small"
      onClick={() => setIsOpen(true)}
    >
      { minimize ?
        // t:置換あり
        t("thread:wordReplacement.replaced") :
        type === MessageType.USER ?
        // t:生成AIに送信した内容
        t("thread:wordReplacement.sentToAI") :
        // t:生成AIから送信された内容
        t("thread:wordReplacement.receivedFromAI")
      }
    </Button>
  );

  return (
    <>
      <Typography variant="caption">
        {/* t:ワード置換あり */}
        { minimize ? button : <>{t("thread:wordReplacement.wordReplacementOccurred")}: {button} </> }
      </Typography>
      <CommonPreModal
        open={isOpen}
        // t:生成AIに送信した内容/生成AIから送信された内容
        title={type === MessageType.USER ? t("thread:wordReplacement.sentToAI") : t("thread:wordReplacement.receivedFromAI")}
        onClose={() => setIsOpen(false)}>
        {renderContent(body)}
      </CommonPreModal>
    </>
  );
};

export default ThreadPostWordReplacedInfo;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MembershipCreate, MembershipUpdate, MembershipManaged, MembershipManagedList, MemberCreateResult, MembershipReinvite } from './membership.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { ErrorModalContent, showErrorModal, showUnknownErrorModal } from '../generic/errorModal.slice';
import { AxiosError } from 'axios';
import { showToast } from '../generic/toast.slice';
import { PLAN_UPGRADE_URL } from '@/common/constants';
import { getCurrentSubscription } from '../auth/auth.type';
import { RootState } from '@/store';
import { PlanType } from '../subscription/subscription.constant';
import { addTeamMembersCount } from '../auth/auth.slice';
import i18next from 'i18next';

interface MembershipState {
  memberships: MembershipManaged[];
  num: number;
}

const initialState: MembershipState = {
  memberships: [],
  num: -1,  // -1は未読込を表す
};

export const createMembership = createAsyncThunk(
  'membership/createMembership',
  async (data: MembershipCreate, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const loginUser = state.auth.loginUser;
    const currentSub = getCurrentSubscription(loginUser);
    const isStarter = currentSub && currentSub.plan.type == PlanType.STARTER;

    try {
      const emails = data.emails.split(',').map(email => email.trim()).filter(email => email !== '');
      const formattedData = emails.map(email => ({
        email,
        role: data.role,
        group_id: data.groupId
      }));
      const response = await apiAxios.post<MemberCreateResult>('/memberships', formattedData);
      // t:「{{email}}」/{{count}}人のメンバー
      const message = emails.length == 1 ? i18next.t('membership:invite.singleMember', { email: emails[0] }) : i18next.t('membership:invite.multipleMember', { count: emails.length });
      // t:{{message}}を招待しました
      dispatch(showToast({message: i18next.t('membership:invite.success', { message }), severity: 'success'}));
      dispatch(addTeamMembersCount(response.data.createdMemberships.length))
      return response.data;
    } catch (err) {
      console.debug("/memberships error", err)
      captureException(err);

      if (err instanceof AxiosError) {
        const code = err.response?.data.errorCode;
        let modal : ErrorModalContent | undefined = undefined;
        switch (code) {
          case "membership_already_exists":
            modal = {
              // t:既に参加済のメンバーです
              title: i18next.t("membership:error.alreadyMember.title"),
              // t:既にメンバーとして参加済のメンバーがいます。
              message: i18next.t("membership:error.alreadyMember.message"),
            }
            break;
          case "trial_member_already_exceeded":
            if(isStarter) {
              modal = {
                // t:既にスターターのメンバー上限に達しています
                title: i18next.t("membership:error.starterLimitReached.title"),
                // t:スタータープランではメンバーは原則10名までとなっております。更にメンバーを追加したい場合はアップグレードをご検討ください。
                message: i18next.t("membership:error.starterLimitReached.message"),
                buttonOption: {
                  // t:アップグレードのお問い合わせ
                  title: i18next.t("membership:error.starterLimitReached.buttonTitle"),
                  url: PLAN_UPGRADE_URL,
                },
              };
            } else {
              modal = {
                // t:既にライセンス数の上限に達しています
                title: i18next.t("membership:error.licenseLimitReached.title"),
                // t:ライセンス数を超えるメンバーを招待することはできません。ライセンスの追加をご検討ください。
                message: i18next.t("membership:error.licenseLimitReached.message"),
                buttonOption: {
                  // t:ライセンス追加のお問い合わせ
                  title: i18next.t("membership:error.licenseLimitReached.buttonTitle"),
                  url: PLAN_UPGRADE_URL,
                },
              };
            }
            break;
          case "license_limit_will_exceeded":
            if(isStarter) {
              modal = {
                // t:スターターのメンバー上限です
                title: i18next.t("membership:error.starterLimitWillExceed.title"),
                // t:スタータープランではメンバーは原則10名までとなっております。更にメンバーを追加したい場合はアップグレードをご検討ください。
                message: i18next.t("membership:error.starterLimitWillExceed.message"),
                buttonOption: {
                  // t:アップグレードのお問い合わせ
                  title: i18next.t("membership:error.starterLimitWillExceed.buttonTitle"),
                  url: PLAN_UPGRADE_URL,
                },
              };
            } else {
              modal = {
                // t:ライセンスを超えたメンバー招待です
                title: i18next.t("membership:error.licenseLimitWillExceed.title"),
                // t:ライセンス数を超えるメンバーを招待することはできません。ライセンスの追加をご検討ください。
                message: i18next.t("membership:error.licenseLimitWillExceed.message"),
                buttonOption: {
                  // t:ライセンス追加のお問い合わせ
                  title: i18next.t("membership:error.licenseLimitWillExceed.buttonTitle"),
                  url: PLAN_UPGRADE_URL,
                },
              };
            }
            break;
          default:
            break;
        }
        if (modal) {
          dispatch(showErrorModal(modal));
        } else {
          dispatch(showUnknownErrorModal());
        }
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const updateMembership = createAsyncThunk(
  'membership/updateMembership',
  async (data: MembershipUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put<MembershipManaged>(`/memberships/${data.id}`, data);
      // t:メンバー情報を更新しました
      dispatch(showToast({message: i18next.t('membership:update.success'), severity: 'success'}));
      return response.data;
    } catch (err) {
      captureException(err);

      if (err instanceof AxiosError) {
        const code = err.response?.data.errorCode;
        let title : string | undefined = undefined;
        let message : string | undefined = undefined;
        switch (code) {
          case "super_admin_must_exist":
            // t:特権管理者を0人にはできません
            title = i18next.t("membership:error.superAdminRequired.title");
            // t:チームには必ず1人特権管理者がいる必要があります。そのため最後の特権管理者のロールを変更したり、削除したりはできません。
            message = i18next.t("membership:error.superAdminRequired.message")
            break;
          default:
            break;
        }
        if (title && message) {
          dispatch(showErrorModal({title, message}));
        } else {
          dispatch(showUnknownErrorModal());
        }
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const deleteMembership = createAsyncThunk(
  'membership/deleteMembership',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/memberships/${id}`);
      // t:メンバーを削除しました
      dispatch(showToast({message: i18next.t('membership:delete.success'), severity: 'success'}));
      dispatch(addTeamMembersCount(-1))
      return id;
    } catch (err) {
      captureException(err);

      if (err instanceof AxiosError) {
        const code = err.response?.data.errorCode;
        let title : string | undefined = undefined;
        let message : string | undefined = undefined;
        switch (code) {
          case "super_admin_must_exist":
            // t:特権管理者を0人にはできません
            title = i18next.t("membership:error.superAdminRequired.title");
            // t:チームには必ず1人特権管理者がいる必要があります。そのため最後の特権管理者のロールを変更したり、削除したりはできません。
            message = i18next.t("membership:error.superAdminRequired.message")
            break;
          default:
            break;
        }
        if (title && message) {
          dispatch(showErrorModal({title, message}));
        } else {
          dispatch(showUnknownErrorModal());
        }
        return rejectWithValue(err.message);
      }

      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);


export const reinviteMembership = createAsyncThunk(
  'membership/reinviteMembership',
  async (data: MembershipReinvite, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.post<MembershipManaged>(`/memberships/${data.id}/reinvite`);
      dispatch(showToast({
        // t:招待メールを再送信しました
        message: i18next.t("membership:reinvite.success"),
        severity: 'success'
      }));
      return response.data;
    } catch (err) {
      console.debug(`/memberships/${data.id}/reinvite error`, err)
      captureException(err);
      dispatch(showUnknownErrorModal());
      return rejectWithValue('An unknown error occurred');
    }
  }
);

const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    clearMemberships: (state) => {
      state.memberships = [];
      state.num = -1;
    },
    appendMemberships: (state, action: PayloadAction<MembershipManagedList>) => {
      const memberships = action.payload.memberships;
      // idが同じものは追加しない
      memberships.forEach((membership) => {
        if (!state.memberships.some((m) => m.id === membership.id)) {
          state.memberships.push(membership);
        }
      });
      // numの更新
      state.num = action.payload.num;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMembership.fulfilled, (state, action) => {
      // 新規追加
      action.payload.createdMemberships.forEach((createdMembership) => {
        state.memberships.unshift(createdMembership);
        state.num++;
      })
      // 既存メンバーへの再招待
      action.payload.reInvitedMemberships.forEach((reInvitedMembership) => {
        const index = state.memberships.findIndex(membership => membership.id === reInvitedMembership.id);
        if (index !== -1) {
          state.memberships[index] = reInvitedMembership;
        }
      })
    });
    builder.addCase (reinviteMembership.fulfilled, (state, action) => {
      const index = state.memberships.findIndex(membership => membership.id === action.payload.id);
      if (index !== -1) {
        state.memberships[index] = action.payload;
      }
    });
    builder.addCase(updateMembership.fulfilled, (state, action) => {
      const index = state.memberships.findIndex(membership => membership.id === action.payload.id);
      if (index !== -1) {
        state.memberships[index] = action.payload;
      }
    });
    builder.addCase(deleteMembership.fulfilled, (state, action) => {
      state.memberships = state.memberships.filter(membership => membership.id !== action.payload);
      state.num--;
    });
  },
});

export const {
  clearMemberships,
  appendMemberships,
} = membershipSlice.actions;

export default membershipSlice.reducer;

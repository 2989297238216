import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '@/store';
import apiAxios from '@/lib/axios';
import { setUserInfo } from '../../auth/auth.slice';
import { LoginUser } from '../../auth/auth.type';
import InfoPanel from '@/components/ui/InfoPanel';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import CommonPanel from '@/components/ui/CommonPanel';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { AxiosError } from 'axios';
import { Trans, useTranslation } from 'react-i18next';

type TeamJoinProps = {
  teamId: string;
  inviteCode: string;
};

const TeamJoin: React.FC<TeamJoinProps> = ({ teamId, inviteCode }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [agreed, setAgreed] = useState(false);
  const { setUnknownErrorModal, setErrorModal } = useErrorModal();

  const termUrl = t("urls:termPage");
  const privacyPolicyUrl = t("urls:privacyPolicyPage");

  if (!loginUser) {
    return <></>
  }

  const membership = loginUser.memberships.find((m) => m.team.id === teamId);

  if (!membership) {
    return (
      <InfoPanel
        // t:不正な招待URL
        title={t("team:join.invalidUrl.title")}
      >
        {/*
          t: ご入力いただいた招待URLは不正なURLです。
             今一度、メールで送られたURLをご確認ください。
             URLが正しい場合は、招待が解除された可能性がございます。
             あなたを招待した管理者にお問い合わせください。
        */}
        {t("team:join.invalidUrl.message")}
      </InfoPanel>
    );
  }

  const handleJoinTeam = async () => {
    try {
      await apiAxios.put(`/memberships/${membership.id}/join`, { inviteCode });
      await apiAxios.put(`/memberships/${membership.id}/current`);
      const response = await apiAxios.get<LoginUser>('/auth/profile');
      dispatch(setUserInfo(response.data));
      navigate('/teams/join/success');
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        const data = error.response.data;
        switch (data.errorCode) {
          case 'already_joined':
            setErrorModal(
              // t:既にチームに参加しています
              t("team:join.errors.alreadyJoined.title"),
              // t:既にこのチームには参加済みです。WorkAIzerを使ってみましょう。
              t("team:join.errors.alreadyJoined.message"),
              undefined,
              () => {
                window.location.href = '/';
              }
            )
            return;
          case 'approve_deadline_exceeded':
            setErrorModal(
              // t:招待メールが有効期限切れになりました
              t("team:join.errors.expiredInvite.title"),
              // t:大変恐縮ですが、操作中に招待メールが有効期限切れになりました。あなたを招待した管理者に再度招待メールを送るようお伝えください。
              t("team:join.errors.expiredInvite.message")
            )
            return;
          case 'invite_code_mismatch':
            setErrorModal(
              // t:招待リンクが不正です
              t("team:join.errors.invalidInviteCode.title"),
              // t:URLが間違っている可能性があります。URLが正しいかご確認ください。もし正しい場合は、管理者が再招待をしたことで無効化された可能性があります。メールボックスを確認し、他に招待メールが届いていないかご確認ください。それでも問題が解決しない場合は、管理者にお問い合わせください。
              t("team:join.errors.invalidInviteCode.message")
            )
            return;
        }
      }
      captureException(error);
      setUnknownErrorModal();
      // t:チーム参加エラー
      console.error(t('team:errors.joinError'), error);
    }
  };

  if (membership.isJoined) {
    return (
      <InfoPanel
        // t:チーム参加済
        title={t("team:join.alreadyJoined.title")}
      >
        {/*
          t: 既にチームに参加しています。
             <l>サービスを利用する</l>
        */}
        <Trans i18nKey="team:join.alreadyJoined.message"
          components={{l: <a href="/"/>}}
        />
      </InfoPanel>
    );
  }

  if (membership.invite?.isApproveDeadlineExceeded) {
    return (
      <InfoPanel
        // t:認証URL期限切れ
        title={t("team:join.expiredUrl.title")}
      >
        {/*
          t: 既に招待URLが有効期限切れとなっています。
             あなたを招待した管理者に再度招待メールを送るようお伝えください。
        */}
        {t("team:join.expiredUrl.message")}
      </InfoPanel>
    );
  }

  const handleAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const team = membership.team;

  return (
    <CommonPanel>
      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <Typography variant="h4" align="center" gutterBottom>
          {/* t:チーム「{{teamName}}」にようこそ */}
          {t("team:join.welcome", { teamName: team.name })}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '30px' }}>
          {/* t:参加にはサービス利用規約への同意が必要です。 */}
          {t("team:join.agreementRequired")}
        </Typography>
        <Box display="flex" alignItems="center" mb={4}>
          <FormControlLabel
            control={<Checkbox checked={agreed} onChange={handleAgree} />}
            label={
              <Box component="span" display="flex" alignItems="center">
                <Typography variant="body2">
                  {/*
                    t: <l1>利用規約</l1>と<l2>プライバシーポリシー</l2>に同意します
                  */}
                  <Trans i18nKey="team:create.agreeTerms"
                    components={{
                      l1: <Link to={termUrl} target="_blank" color="primary"/>,
                      l2: <Link to={privacyPolicyUrl} target="_blank" color="primary"/>
                    }}
                  />
                </Typography>
              </Box>
            }
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handleJoinTeam} disabled={!agreed} style={{ padding: '10px 20px' }}>
          {/* t:チームに参加する */}
          {t("team:join.joinButton")}
        </Button>
      </Box>
    </CommonPanel>
  );
};

export default TeamJoin;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/store';
import { LoginUserMembership, getJoinMemberships } from "../../auth/auth.type";
import apiAxios from '@/lib/axios';
import { setUserInfo } from '../../auth/auth.slice';
import { LoginUser } from '../../auth/auth.type';
import { Button, Card, CardContent, List, Typography } from '@mui/material';
import CommonPanel from '@/components/ui/CommonPanel';
import { getMembershipRoleLabel } from '../../membership/membership.constant';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { displayTimestamp } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

const TeamSelect: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { setUnknownErrorModal } = useErrorModal();

  useEffect(() => {
    if (loginUser) {
      const memberships = getJoinMemberships(loginUser);
      // 複数のチームに所属・参加してなければトップページへ
      if (memberships.length < 2) {
        navigate('/');
      }
    }
  }, [loginUser, navigate]);

  if (!loginUser) {
    return <></>;
  }

  const handleSelectTeam = async (membershipId: string) => {
    try {
      await apiAxios.put(`/memberships/${membershipId}/current`);
      const response = await apiAxios.get<LoginUser>('/auth/profile');
      console.debug('put /memberships/:id/current response', response)
      dispatch({ type: 'RESET_STORE' });
      dispatch(setUserInfo(response.data))
      navigate('/');
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      // t:チーム選択エラー：
      console.error(t("team:errors.selectError"), error);
    }
  };

  return (
    <CommonPanel>
      <Typography variant="h4" gutterBottom align="center">
        {/* t:チーム選択 */}
        {t("team:select.title")}
      </Typography>
      <List>
        {getJoinMemberships(loginUser).map((membership: LoginUserMembership) => (
          <Card key={membership.id} sx={{ marginBottom: 4 }}>
            <CardContent>
              <Typography variant="h6">
                {/* t:チーム名：{{teamName}} */}
                {t("team:select.teamName", { teamName: membership.team.name })}
              </Typography>
              <Typography color="textSecondary">
                {/* t:ロール：{{role}} */}
                {t("team:select.role", { role: getMembershipRoleLabel(t, membership.role) })}
              </Typography>
              <Typography color="textSecondary">
                {/* t:参加日時：{{timestamp}} */}
                {t("team:select.joinedAt", { timestamp: displayTimestamp(membership.createdAt) })}
              </Typography>
              <br/>
              <Button variant="contained" color="primary" onClick={() => handleSelectTeam(membership.id)}>
                {/* t:選択 */}
                {t("team:select.selectButton")}
              </Button>
            </CardContent>
          </Card>
        ))}
      </List>
    </CommonPanel>
  );
};

export default TeamSelect;

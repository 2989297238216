import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ja } from 'date-fns/locale/ja';
import { enUS } from 'date-fns/locale/en-US';
import { UnitType } from '../report.type';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const schema = z.object({
  unitType: z.nativeEnum(UnitType),
  termFrom: z.date(),
  termTo: z.date(),
})
.superRefine((data, ctx) => {
  const diffDays = Math.ceil(
    (data.termTo.getTime() - data.termFrom.getTime()) / (1000 * 60 * 60 * 24)
  );
  let isValid = true;
  switch (data.unitType) {
    case UnitType.DAY:
      isValid = diffDays <= 65;
      break;
    case UnitType.WEEK:
      isValid = diffDays <= 365;
      break;
    case UnitType.MONTH:
      isValid = diffDays <= 365 * 30;
      break;
  }
  if (!isValid) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:期間が長すぎます。日単位の場合は65日以内、週単位の場合は1年以内、月単位の場合は30年以内にしてください。
      message: i18next.t('reports:error.period.tooLong'),
      path: ['termFrom'],
    });
  }
  if (data.termFrom > data.termTo) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      // t:期間の指定が不正です。開始日は終了日より前にしてください。
      message: i18next.t('reports:error.period.invalid'),
      path: ['termFrom'],
    });
  }
});

type FormData = z.infer<typeof schema>;

interface Props {
  onSubmit: (data: FormData) => void;
}

const ReportConditionForm: React.FC<Props> = ({ onSubmit }) => {
  const { t, i18n } = useTranslation();
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      unitType: UnitType.DAY,
      termTo: new Date(new Date().setDate(new Date().getDate() - 1)),
      termFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    },
  });

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const unitType = watch('unitType');

  const handleUnitTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newUnitType: UnitType | null
  ) => {
    if (newUnitType !== null) {
      setValue('unitType', newUnitType);
    }
  };

  const renderDatePicker = (
    name: "termFrom" | "termTo"
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            maxDate={new Date()}
          />
        )}
      />
    );
  };

  const onSuccess = (data: FormData) => {
    onSubmit(data);
  };

  const onError = (errors: any) => {
    setErrorMessage(errors.termFrom?.message || errors.termTo?.message || '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let locale = enUS;
  if (i18n.language == 'ja') {
    locale = ja;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <form onSubmit={handleSubmit(onSuccess, onError)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {/* t:単位： */}
            <Typography>{t('reports:condition.unit')}</Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={unitType}
              exclusive
              onChange={handleUnitTypeChange}
              aria-label="unit type"
            >
              <ToggleButton value={UnitType.DAY} aria-label="day">
                {/* t:日 */}
                {t('reports:condition.unitType.day')}
              </ToggleButton>
              <ToggleButton value={UnitType.WEEK} aria-label="week">
                {/* t:週 */}
                {t('reports:condition.unitType.week')}
              </ToggleButton>
              <ToggleButton value={UnitType.MONTH} aria-label="month">
                {/* t:月 */}
                {t('reports:condition.unitType.month')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item ml={1}>
            {/* t:期間： */}
            <Typography>{t('reports:condition.period')}</Typography>
          </Grid>
          <Grid item>
            {renderDatePicker('termFrom')}
          </Grid>
          <Grid item>
            <Box>〜</Box>
          </Grid>
          <Grid item>
            {renderDatePicker('termTo')}
          </Grid>
          <Grid item ml={2}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
            >
              {/* t:表示 */}
              {t('reports:common.display')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={open} onClose={handleClose}>
        {/* t:エラー */}
        <DialogTitle>{t('common:message.unknownError')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {/* t:閉じる */}
            {t('common:button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ReportConditionForm;

import { Language } from "./setting.constant";

export const convertStrToLanguage = (str: string): Language => {
    switch (str) {
        case 'ja':
        return Language.JA;
        case 'en':
        return Language.EN;
        default:
        return Language.EN;
    }
}

export const getLanguageLabel = (lang: Language | string): string => {
    const language = typeof lang === 'string' ? convertStrToLanguage(lang) : lang;
    switch (language) {
        case Language.JA:
            return '日本語';
        case Language.EN:
            return 'English';
        default:
            return 'English';
    }
}

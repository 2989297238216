import React, { useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { ResReports } from '../report.api';
import { downloadCsvFromData } from '@/lib/csv';
import { TabType } from '../report.constant';
import { UnitType } from '../report.type';
import { getCsvTermFrom, getCsvTermTo } from '../report.utils';
import { useTranslation } from 'react-i18next';

interface Props {
  report: ResReports;
  selectedTab: TabType;
  unitType: UnitType;
}

const ReportDetailTable: React.FC<Props> = ({ report, selectedTab, unitType}) => {
  const { t } = useTranslation();
  const { csvHeaders, createCsvData } = useMemo(() => {
    switch (selectedTab) {
      case 'usage':
        return {
          csvHeaders: [
            // t:対象日From
            t("reports:dateRange.from"),
            // t:対象日To
            t("reports:dateRange.to"),
            // t:スレッド作成回数
            t("reports:dataTable.columns.threadCreationCount"),
            // t:投稿回数
            t("reports:dataTable.columns.postCount"),
            // t:AIの回答回数
            t("reports:dataTable.columns.aiResponseCount"),
            // t:総クレジット
            t("reports:dataTable.columns.totalCredits"),
            // t:入力クレジット
            t("reports:dataTable.columns.inputCredits"),
            // t:出力クレジット
            t("reports:dataTable.columns.outputCredits"),
            // t:合計文字数
            t("reports:dataTable.columns.totalCharacters"),
            // t:入力文字数
            t("reports:dataTable.columns.inputCharacters"),
            // t:出力文字数
            t("reports:dataTable.columns.outputCharacters"),
            // t:画像ファイル数
            t("reports:dataTable.columns.imageFileCount"),
            // t:音声ファイル数
            t("reports:dataTable.columns.audioFileCount"),
            // t:動画ファイル数
            t("reports:dataTable.columns.videoFileCount"),
            // t:PDFファイル数
            t("reports:dataTable.columns.pdfFileCount")
          ],
          createCsvData: () => report.teamUsageReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            getCsvTermTo(r.targetOn, unitType),
            r.threadNum.toString(),
            r.postCount.toString(),
            r.aiGenerateCount.toString(),
            r.totalCredit,
            r.inputCredit,
            r.outputCredit,
            (r.inputCharsInView + r.outputChars).toString(),
            r.inputCharsInView.toString(),
            r.outputChars.toString(),
            r.inputImageCountInView.toString(),
            r.inputAudioCountInView.toString(),
            r.inputVideoCountInView.toString(),
            r.inputDocumentCountInView.toString(),
          ]),
        };
      case 'usageDetail':
        return {
          csvHeaders: [
            // t:対象日From
            t("reports:dateRange.from"),
            // t:対象日To
            t("reports:dateRange.to"),
            // t:用途
            t("reports:dataTable.columns.usage"),
            // t:AIモデル
            t("reports:dataTable.columns.aiModel"),
            // t:AIの回答回数
            t("reports:dataTable.columns.aiResponseCount"),
            // t:総クレジット
            t("reports:dataTable.columns.totalCredits"),
            // t:入力クレジット
            t("reports:dataTable.columns.inputCredits"),
            // t:出力クレジット
            t("reports:dataTable.columns.outputCredits"),
            // t:合計文字数
            t("reports:dataTable.columns.totalCharacters"),
            // t:入力文字数
            t("reports:dataTable.columns.inputCharacters"),
            // t:出力文字数
            t("reports:dataTable.columns.outputCharacters"),
            // t:画像ファイル数
            t("reports:dataTable.columns.imageFileCount"),
            // t:音声ファイル数
            t("reports:dataTable.columns.audioFileCount"),
            // t:動画ファイル数
            t("reports:dataTable.columns.videoFileCount"),
            // t:PDFファイル数
            t("reports:dataTable.columns.pdfFileCount")
          ],
          createCsvData: () => report.teamUsageDetailReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            r.targetOn.replace(/-/g, '/'),
            r.type,
            r.aiModel.code,
            r.aiGenerateCount.toString(),
            r.totalCredit,
            r.inputCredit,
            r.outputCredit,
            (r.inputCharsInView + r.outputChars).toString(),
            r.inputCharsInView.toString(),
            r.outputChars.toString(),
            r.inputImageCountInView.toString(),
            r.inputAudioCountInView.toString(),
            r.inputVideoCountInView.toString(),
            r.inputDocumentCountInView.toString(),
          ]),
        };
      case 'memberNum':
        return {
          csvHeaders: [
            // t:対象日From
            t("reports:dateRange.from"),
            // t:対象日To
            t("reports:dateRange.to"),
            // t:利用メンバー数
            t("reports:common.memberCount")
          ],
          createCsvData: () => report.teamMembershipNumReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            r.targetOn.replace(/-/g, '/'),
            r.memberNum.toString()
          ]),
        };
      default:
        return { csvHeaders: [], createCsvData: () => [] };
    }
  }, [report, selectedTab]);

  const handleDownloadCsv = async () => {
    const csvData = createCsvData();
    await downloadCsvFromData(csvHeaders, csvData, selectedTab + 'Report.csv');
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {
            selectedTab === 'usage' ?
            // t:チーム全体の利用量の集計データです。
            t("reports:descriptions.overallUsage") :
            selectedTab === 'usageDetail' ?
            // t:各AIモデル、タイプごとの利用量の集計データです。利用があった日/週/月のみ表示されます。
            t("reports:descriptions.detailedUsage", { unit: unitType }) :
            selectedTab === 'memberNum' ?
            // t:チームの利用メンバー数の集計データです。
            t("reports:descriptions.memberCount") :
            ""
          }
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleDownloadCsv}>
          {/* t:CSVダウンロード */}
          {t("reports:dataTable.downloadCsv")}
        </Button>
      </Box>
      <TableContainer component={Paper} style={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {csvHeaders.map(header => (
                <TableCell key={header} padding="normal" style={{ whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {createCsvData().map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell key={`${i}-${j}`} padding="normal"
                     style={{ whiteSpace: 'nowrap' }}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportDetailTable;

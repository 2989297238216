import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, Box, FormControlLabel, Checkbox, Button, Avatar } from '@mui/material';
import apiAxios from '@/lib/axios';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTeamToUserInfo } from '../../auth/auth.slice';
import TeamFormFields from './TeamFormFields';
import { RootState } from '@/store';
import InfoPanel from '@/components/ui/InfoPanel';
import CommonPanel from '@/components/ui/CommonPanel';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { Trans, useTranslation } from 'react-i18next';

const TeamCreateForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUnknownErrorModal } = useErrorModal();
  const [agreed, setAgreed] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  const termUrl = t("urls:termPage");
  const privacyPolicyUrl = t("urls:privacyPolicyPage");
  const trialEntryUrl = t("urls:trialEntryForm");

  if (!loginUser) {
    return;
  }

  if (!loginUser.isServiceUsable) {
    return (
      <InfoPanel
        // t:サービスを利用できません
        title={t("team:serviceUnavailable.title")}
      >
        {/*
          t: 大変恐縮ですが、本サービスは原則「GoogleWorkspaceアカウント」をお持ちの方のみがご利用いただけます。
          GoogleWorkspaceアカウントをお持ちでない場合は、
          <Link>こちら</Link>
          よりお問い合わせください。
        */}
        <Trans i18nKey="team:serviceUnavailable.message"
          components={{l: <Link to={trialEntryUrl} target="_blank" rel="noopener" color="primary"/>}}
        />
      </InfoPanel>
    );
  }

  const handleAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const handleProceed = () => {
    setShowForm(true);
  };

  const onSubmit = async (data: { name: string }) => {
    setIsLoading(true);
    try {
      const response = await apiAxios.post('/teams', data);
      const team = response.data;
      const membership = response.data.memberships[0];
      team.memberships = undefined;
      membership.team = team;
      dispatch(addNewTeamToUserInfo(membership));
      navigate('/teams/completed');
    } catch (err) {
      captureException(err);
      setUnknownErrorModal();
      console.error('Post /teams error', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonPanel>
      {!showForm ? (
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
          <Typography variant="h4" align="center" gutterBottom>
            {/* t:チームを作成しましょう */}
            {t("team:create.title")}
          </Typography>
          <Typography variant="body1" align="center" paragraph style={{ whiteSpace: 'pre-line' }}>
            {/*
              t: WorkAIzerを利用するには、まずチームを作成する必要があります。
              チームを作成することで、メンバーを招待し、仕事仲間と生成AIを使うことができます。
            */}
            {t("team:create.description")}
          </Typography>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={handleAgree} />}
              label={
                <Box component="span" display="flex" alignItems="center">
                  <Typography variant="body2">
                    {/*
                      t: <l1>利用規約</l1>と
                      <l2>プライバシーポリシー</l2>
                      に同意します
                    */}
                    <Trans i18nKey="team:create.agreeTerms"
                      components={{
                        l1: <Link to={termUrl} target="_blank" color="primary"/>,
                        l2: <Link to={privacyPolicyUrl} target="_blank" color="primary"/>
                      }}
                    />
                  </Typography>
                </Box>
              }
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!agreed}
            onClick={handleProceed}
            sx={{ mt: 2 }}
          >
            {/* t:チーム作成へ進む */}
            {t("team:create.proceedButton")}
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" alignItems="center" p={4}>
            <Typography variant="h4" align="center" gutterBottom mb={4}>
              {/* t:チームの名前を決めましょう */}
              {t("team:create.nameTitle")}
            </Typography>

            <Typography>
              {/* t:あなたはチームの特権管理者となります */}
              {t("team:create.adminInfo")}
            </Typography>

            <Box display="flex" alignItems="center" mt={1} mb={5}>
              <Avatar alt="User" src={loginUser.picture} sx={{ width: 56, height: 56, mr: 2 }} />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" color="text.secondary">{loginUser.name}</Typography>
                <Typography variant="body2" color="text.secondary">{loginUser.email}</Typography>
              </Box>
            </Box>

            <TeamFormFields
              onSubmit={onSubmit}
              isLoading={isLoading}
              // t:登録
              submitButtonText={t("common:button.create")}
            />
          </Box>
        </>
      )}
    </CommonPanel>
  );
};

export default TeamCreateForm;

import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorModalContextProps = {
  isOpen: boolean;
  title: string;
  message: string;
  buttonOption?: {
    title: string;
    url: string;
  };
  setErrorModal: (title: string, message: string, buttonOption?: { title: string; url: string }, closeCallback?: () => void) => void;
  setUnknownErrorModal: () => void;
  closeErrorModal: () => void;
};

export const ErrorModalContext = createContext<ErrorModalContextProps>({
  isOpen: false,
  title: '',
  message: '',
  setErrorModal: () => {},
  setUnknownErrorModal: () => {},
  closeErrorModal: () => {},
});

export const ErrorModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [buttonOption, setButtonOption] = useState<{ title: string; url: string }>();
  const [onCloseCallback, setOnCloseCallback] = useState<() => void>();
  const { t } = useTranslation();

  const setErrorModal = (title: string, message: string, buttonOption?: { title: string; url: string }, onCloseCallbackArg?: () => void) => {
    setTitle(title);
    setMessage(message);
    setButtonOption(buttonOption);
    setOnCloseCallback(() => onCloseCallbackArg);
    setIsOpen(true);
  };

  const setUnknownErrorModal = () => {
    setErrorModal(
      t('common:message.unknownError'),
      t('common:message.unknownErrorFull'),
      // { title: t('お問い合わせ'), url: 'https://example.com/contact' }
    );
  }

  const closeErrorModal = () => {
    setIsOpen(false);
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  return (
    <ErrorModalContext.Provider value={{ isOpen, title, message, buttonOption, setErrorModal, setUnknownErrorModal, closeErrorModal }}>
      {children}
    </ErrorModalContext.Provider>
  );
};

import { AdminContentsWrapper } from '@/components/features/admin/components/AdminUI';
import { isSuperAdmin } from '@/components/features/auth/auth.type';
import { RootState } from '@/store';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Trans, useTranslation } from 'react-i18next';

const AdminIndexPage = () => {
  const { t } = useTranslation();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  if (!loginUser) {
    return;
  }

  return (
    <AdminContentsWrapper>
      <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-line', marginBottom: 5 }}>
        {/* t: こちらは管理者限定の機能となります。 */}
        {t("admin:top.description")}<br/>
        {
          isDesktop ?
          // t: サイドバーからメニューをご選択ください。
          t("admin:top.desktopInstruction") :
          // t: 左上のメニューアイコンから機能をご選択ください。
          t("admin:top.mobileInstruction")
        }
      </Typography>

      { isSuperAdmin(loginUser) && <>
        <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-line', marginBottom: 5, display: 'flex', alignItems: 'center' }}>
          {/* t:<icon/>がついているメニューは、特権管理者専用となっております。 */}
          <Trans i18nKey="admin:top.superAdminNote" components={{
            icon: <VerifiedUserIcon fontSize="small" sx={{ mb: '2px', color: 'green' }} />
          }}/>
        </Typography>
      </>}

      <Typography variant="subtitle1">
        {/* t: 不明点などありましたら、<l>管理者マニュアル</l>をご参照ください。 */}
        <Trans i18nKey="admin:top.manualReference" components={{
          l: <a
            href={t("urls:adminManual")}
            target="_blank"
            rel="noopener noreferrer"
          />
        }}/>
      </Typography>

    </AdminContentsWrapper>
  );
};

export default AdminIndexPage;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import QpListContainer from '../../quickPrompt/containers/QpListContainer';
import styled from "@emotion/styled";
import { History } from '@mui/icons-material';
import { LeftSideBarWrapper, LeftSidebarSubTitle } from '@/components/ui/StyledComponents';
import { useTranslation } from 'react-i18next';

const ButtonWrapper = styled.div`
  padding: 10px;
`;
const CreateThreadButton = styled(Button)`
  box-shadow: none;
  border-radius: 30px;
`;


const ThreadLeftSidebarContainer: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <LeftSideBarWrapper sx={{marginBottom: 10}}>
      <ButtonWrapper>
        <CreateThreadButton variant="contained" color="secondary"
          fullWidth onClick={ () => { navigate(`/`); onItemClick(); } }>
          {/* t:新規スレッド */}
          {t("thread:newThread")}
        </CreateThreadButton>
      </ButtonWrapper>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 2 }} onClick={ () => { navigate(`/threads`); onItemClick(); } }>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <History /> {/* アイコンをレンダリング */}
          </ListItemIcon>
          <ListItemText sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
            // t:スレッド履歴
            primary={t("thread:threadHistory")} />
        </ListItemButton>
      </List>
      <Divider sx={{ mt: 1.1, mb: 1 }}/>
      {/* t:クイックプロンプト */}
      <LeftSidebarSubTitle>{t("thread:quickPrompt")}</LeftSidebarSubTitle>
      <QpListContainer></QpListContainer>
    </LeftSideBarWrapper>
  );
};

export default ThreadLeftSidebarContainer;

import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import { DlpExpressionConfig } from '../dlp.type';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, IconButton, Divider, useMediaQuery, useTheme, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ExpressionConfigFormModal from '../components/ExpressionConfigFormModal';
import { useDispatch } from 'react-redux';
import { deleteExpressionConfig } from '../dlp.slice';
import { AppDispatch } from '@/store';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { getDlpPreventTypeLabel } from '../dlp.constant';
import { ResponsiveTableActionFooter, ResponsiveTableCell, ResponsiveTableRow, ResponsiveTableRowHeader } from '@/components/ui/ResponsiveComponents';
import { displayTimestamp } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface ExpressionConfigListPresenterProps {
  expressionConfigs: DlpExpressionConfig[];
  onScroll: () => void;
  hasMore: boolean;
}

const ExpressionConfigListPresenter: React.FC<ExpressionConfigListPresenterProps> = ({
  expressionConfigs,
  onScroll,
  hasMore,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<DlpExpressionConfig | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const handleAddClick = () => {
    setIsEdit(false);
    setSelectedConfig(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (config: DlpExpressionConfig) => {
    setIsEdit(true);
    setSelectedConfig(config);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    // t: 本当に削除しますか？
    if (window.confirm(t('common:message.confirmDelete'))) {
      await dispatch(deleteExpressionConfig(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* t:機密ワードブロッカー設定 */}
      <AdminPageTitle title={t('dlp:confidentialWordBlockerSettings')} />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px', whiteSpace: 'pre-line' }}>
          {/*
            t:ワードを追加することで、生成AIにメッセージが送られる前に、特定のワードをブロックしたり、差し替えることができます。
              生成AIに送られたくない機密度の高いワードを追加しましょう。
          */}
          {t('dlp:confidentialWordBlockerDescription')}
        </Typography>

        <Button variant="contained" color="primary" onClick={handleAddClick} style={{ marginBottom: '20px' }}>
          {/* t:新規登録 */}
          {t('common:button.new')}
        </Button>
        <div id="scrollableDiv">
          {expressionConfigs.length === 0 ? (
            // t: 機密ワードブロッカーの設定はまだ追加されていません。
            //    新規登録ボタンから設定を追加しましょう。
            <Typography variant="caption" align="left" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
              {t('dlp:noConfidentialWordBlockerSettings')}
            </Typography>
          ) : (
            <InfiniteScroll
              dataLength={expressionConfigs.length}
              next={onScroll}
              hasMore={hasMore}
              loader={
                <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                  <CircularProgress />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <TableContainer component={Paper}>
                <Table>
                  {!isSmallScreen && (
                    <TableHead>
                      <TableRow>
                        {/* t:名前 */}
                        <TableCell>{t('dlp:name')}</TableCell>
                        {/* t:タイプ */}
                        <TableCell>{t('dlp:type')}</TableCell>
                        {/* t:対象ワード */}
                        <TableCell>{t('dlp:targetWord')}</TableCell>
                        {/* t:置換ワード */}
                        <TableCell>{t('dlp:replaceWord')}</TableCell>
                        {/* t:作成日時 */}
                        <TableCell>{t('common:info.createdAt')}</TableCell>
                        {/* t:更新日時 */}
                        <TableCell>{t('common:info.updatedAt')}</TableCell>
                        {/* t:操作 */}
                        <TableCell>{t('common:info.actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {expressionConfigs.map((config) => (
                      <ResponsiveTableRow key={config.id}>
                        <ResponsiveTableRowHeader>{config.name}</ResponsiveTableRowHeader>
                        {/* タイプ */}
                        <ResponsiveTableCell data-label={t('dlp:type')}>{getDlpPreventTypeLabel(t, config.preventType)}</ResponsiveTableCell>
                        {/* 対象ワード */}
                        <ResponsiveTableCell data-label={t('dlp:targetWord')}>{config.expression}</ResponsiveTableCell>
                        {/* 置換ワード */}
                        <ResponsiveTableCell data-label={t('dlp:replaceWord')}>{config.replaceWord || ''}</ResponsiveTableCell>
                        {/* 作成日時 */}
                        <ResponsiveTableCell data-label={t('common:info.createdAt')}>{displayTimestamp(config.createdAt)}</ResponsiveTableCell>
                        {/* 更新日時 */}
                        <ResponsiveTableCell data-label={t('common:info.updatedAt')}>{displayTimestamp(config.updatedAt)}</ResponsiveTableCell>
                        <ResponsiveTableActionFooter>
                          <IconButton edge="end" aria-label={t('common:button.edit')} onClick={() => handleEditClick(config)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label={t('common:button.delete')} onClick={() => handleDeleteClick(config.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </ResponsiveTableActionFooter>
                      </ResponsiveTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          )}
        </div>
      </AdminContentsWrapper>
      <ExpressionConfigFormModal
        open={isModalOpen}
        handleClose={handleModalClose}
        isEdit={isEdit}
        defaultValues={selectedConfig || {
          id: '',
          name: '',
          expression: '',
          replaceWord: '',
          preventType: 'prohibit',
          createdAt: '',
          updatedAt: '',
        } as DlpExpressionConfig}
      />
    </>
  );
};

export default ExpressionConfigListPresenter;

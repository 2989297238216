import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentMembership, LoginUser } from './auth.type';
import { LoginUserMembership } from "./auth.type";
import { TeamUsage } from '../usage/usage.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { TeamConfig } from '../team/team.type';

interface AuthState {
  isAuthenticated: boolean;
  loginUser?: LoginUser;
  redirectUrl?: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loginUser: undefined,
  redirectUrl: undefined,
};


export const updateUiMembershipFlag = createAsyncThunk(
  'auth/updateUiMembershipFlag',
  async ({key, value}: {key: string, value: unknown}) => {
    try {
      apiAxios.put(`/ui/membership-flags/${key}`, { value });
      return {key, value};
    } catch (err) {
      console.debug("/teams error", err)
      captureException(err);
      return {key, value};
    }
  }
);


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<LoginUser>) => {
      state.loginUser = action.payload;
      state.isAuthenticated = true;
    },
    changeCurrent: (state, action: PayloadAction<LoginUserMembership>) => {
      if (!state.loginUser) {
        return;
      }
      state.loginUser.memberships = state.loginUser.memberships.map((membership) => {
        if (membership.id === action.payload.id) {
          return { ...membership, isCurrent: true };
        } else {
          return { ...membership, isCurrent: false };
        }
      });
    },
    addNewTeamToUserInfo: (state, action: PayloadAction<LoginUserMembership>) => {
      if (!state.loginUser) {
        return;
      }
      state.loginUser.memberships.push(action.payload);
    },
    updateTeamUsage: (state, action: PayloadAction<TeamUsage>) => {
      if (!state.loginUser) {
        return;
      }
      const currentMembership = getCurrentMembership(state.loginUser);
      if (currentMembership) {
        currentMembership.team.usage = action.payload;
      }
    },
    updateTeamConfig: (state, action: PayloadAction<TeamConfig>) => {
      if (!state.loginUser) {
        return;
      }
      const currentMembership = getCurrentMembership(state.loginUser);
      if (currentMembership) {
        currentMembership.team.config = action.payload;
      }
    },
    updateMentionedAiModelsAtLastThreadCreation: (state, action: PayloadAction<string[]>) => {
      if (!state.loginUser) {
        return;
      }
      const currentMembership = getCurrentMembership(state.loginUser);
      if (currentMembership) {
        currentMembership.mentionedAiModelCodesAtLastThreadCreation = action.payload;
      }
    },
    addTeamMembersCount: (state, action: PayloadAction<number>) => {
      if (!state.loginUser) {
        return;
      }
      const currentMembership = getCurrentMembership(state.loginUser);
      if (currentMembership) {
        currentMembership.team.membersCount += action.payload;
      }
    },
    clearUserInfo: (state) => {
      state.loginUser = undefined;
      state.isAuthenticated = false;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      if (action.payload != '/login') {
        state.redirectUrl = action.payload;
      }
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUiMembershipFlag.fulfilled, (state, action) => {
      const membership = getCurrentMembership(state.loginUser);
      if (membership) {
        membership.uiFlags[action.payload.key] = action.payload.value;
      }
    });
  },
});

export const {
  setUserInfo,
  changeCurrent,
  addNewTeamToUserInfo,
  updateTeamUsage,
  updateTeamConfig,
  updateMentionedAiModelsAtLastThreadCreation,
  clearUserInfo,
  addTeamMembersCount,
  setRedirectUrl,
  clearRedirectUrl,
} = authSlice.actions;

export default authSlice.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DlpExpressionConfig, DlpInfoType, DlpInfoTypeConfig } from './dlp.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorModal } from '../generic/errorModal.slice';
import { showToast } from '../generic/toast.slice';
import i18next from 'i18next';

interface DlpState {
  expressionConfigs: DlpExpressionConfig[];
  infoTypes: DlpInfoType[];
  infoTypeConfigs: DlpInfoTypeConfig[];
}

const initialState: DlpState = {
  expressionConfigs: [],
  infoTypes: [],
  infoTypeConfigs: [],
};

export const createExpressionConfig = createAsyncThunk(
  'dlp/createExpressionConfig',
  async (data: DlpExpressionConfig, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.post<DlpExpressionConfig>('/dlp/expression-configs', data);
      // t:設定を追加しました
      dispatch(showToast({message: i18next.t("dlp:configAdded"), severity: 'success'}));
      return response.data;
    } catch (err) {
      captureException(err);
      dispatch(showUnknownErrorModal());
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
      // t:不明なエラーが発生しました
      return rejectWithValue(i18next.t("common:message.unknownError"));
    }
  }
);

export const updateExpressionConfig = createAsyncThunk(
  'dlp/updateExpressionConfig',
  async (data: DlpExpressionConfig, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put<DlpExpressionConfig>(`/dlp/expression-configs/${data.id}`, data);
      // 設定を変更しました
      dispatch(showToast({message: i18next.t("dlp:configUpdated"), severity: 'success'}));
      return response.data;
    } catch (err) {
      captureException(err);
      dispatch(showUnknownErrorModal());
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
      // t:不明なエラーが発生しました
      return rejectWithValue(i18next.t("common:message.unknownError"));
    }
  }
);

export const deleteExpressionConfig = createAsyncThunk(
  'dlp/deleteExpressionConfig',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/dlp/expression-configs/${id}`);
      // t:設定を削除しました
      dispatch(showToast({message: i18next.t("dlp:configDeleted"), severity: 'success'}));
      return id;
    } catch (err) {
      captureException(err);
      dispatch(showUnknownErrorModal());
      if (err instanceof Error) {
        return rejectWithValue(err.message);
      }
      // t:不明なエラーが発生しました
      return rejectWithValue(i18next.t("common:message.unknownError"));
    }
  }
);

const dlpSlice = createSlice({
  name: 'dlp',
  initialState,
  reducers: {
    // ----------------------------------------------
    // NGワード
    // ----------------------------------------------
    setExpressionConfigs: (state, action: PayloadAction<DlpExpressionConfig[]>) => {
      state.expressionConfigs = action.payload;
    },
    appendExpressionConfigs: (state, action: PayloadAction<DlpExpressionConfig[]>) => {
      state.expressionConfigs.push(...action.payload);
    },
    addExpressionConfig: (state, action: PayloadAction<DlpExpressionConfig>) => {
      state.expressionConfigs.push(action.payload);
    },
    updateExpressionConfig: (state, action: PayloadAction<DlpExpressionConfig>) => {
      const index = state.expressionConfigs.findIndex(config => config.id === action.payload.id);
      if (index !== -1) {
        state.expressionConfigs[index] = action.payload;
      }
    },
    // ----------------------------------------------
    // 機密情報ブロック
    // ----------------------------------------------
    setInfoTypes: (state, action: PayloadAction<DlpInfoType[]>) => {
      state.infoTypes = action.payload;
    },
    setInfoTypeConfigs: (state, action: PayloadAction<DlpInfoTypeConfig[]>) => {
      state.infoTypeConfigs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createExpressionConfig.fulfilled, (state, action) => {
      state.expressionConfigs.push(action.payload);
    });
    builder.addCase(updateExpressionConfig.fulfilled, (state, action) => {
      const index = state.expressionConfigs.findIndex(config => config.id === action.payload.id);
      if (index !== -1) {
        state.expressionConfigs[index] = action.payload;
      }
    });
    builder.addCase(deleteExpressionConfig.fulfilled, (state, action) => {
      state.expressionConfigs = state.expressionConfigs.filter(config => config.id !== action.payload);
    });
  },
});

export const {
  setExpressionConfigs,
  appendExpressionConfigs,
  setInfoTypes,
  setInfoTypeConfigs,
} = dlpSlice.actions;

export default dlpSlice.reducer;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl
} from '@mui/material';
import { MembershipGroupCreate, MembershipGroupUpdate } from '../membership-group.type';
import { createMembershipGroup, updateMembershipGroup } from '../membership-group.slice';
import { AppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';
import { useTranslation } from 'react-i18next';


type MembershipGroupSave = MembershipGroupCreate | MembershipGroupUpdate;

interface MembershipGroupFormModalProps {
  open: boolean;
  handleClose: () => void;
  defaultValues: MembershipGroupSave;
}

const MembershipGroupFormModal: React.FC<MembershipGroupFormModalProps> = ({
  open,
  handleClose,
  defaultValues,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { t } = useTranslation();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if(!loginUser) {
    return null;
  }

  // 編集可否か
  const isEdit = 'id' in defaultValues;

  const onSubmit = async (data: MembershipGroupSave) => {
    if (isEdit) {
      await dispatch(updateMembershipGroup(data as MembershipGroupUpdate));
    } else {
      await dispatch(createMembershipGroup(data as MembershipGroupCreate));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {/* t:グループ更新/グループ追加 */}
      <DialogTitle>{isEdit ? t('membershipGroup:form.title.edit') : t('membershipGroup:form.title.add')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(
            <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
              <WaTextField
                // t:グループ名
                label={t('membershipGroup:groupName')}
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('name', {
                  // t:グループ名は必須です
                  required: t('membershipGroup:validation.name.required'),
                  // t:グループ名は{{max}}文字以内で入力してください
                  maxLength: { value: 100, message: t('membershipGroup:validation.name.maxLength', { max: 100 }) },
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <WaTextField
                style={{marginTop: '20px'}}
                multiline
                // t:説明
                label={t('membershipGroup:description')}
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('description', {
                  // t:グループの説明は{{max}}文字以内で入力してください
                  maxLength: { value: 500, message: t('membershipGroup:validation.description.maxLength', { max: 500 }) },
                })}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </FormControl>
          )}

          <DialogActions style={{ marginTop: '20px' }}>
            {/* t:キャンセル */}
            <Button onClick={handleClose}>{t('common:button.cancel')}</Button>
            {/* t:保存 */}
            <Button type="submit" color="primary">{t('common:button.save')}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipGroupFormModal;

import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { displayDate, displayMonth, removeDecimalPart } from '@/lib/utils';
import { TeamWithSubscription } from '../../team/team.type';
import { SvTeamDetail } from '../../supervisor/teams/team.type';
import { SvTeamUsage } from '../../supervisor/usage/usage.type';
import { getSpecialCreditLabel } from '../usage.util';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';

const SharedLatestUsageStatisticsPresenter: React.FC<{
  team: SvTeamDetail | TeamWithSubscription,
  hideCreditInfo?: boolean,
}> = ({
  team,
  hideCreditInfo = false
}) => {
  const { t } = useTranslation();
  const usage = team.usage;
  const quota = team.quota;
  const currentSub = team.currentSubscription;
  const term = currentSub.latestMonthTerm;
  const isStarter = currentSub.plan.type === PlanType.STARTER;

  const isSvTeamDetail = (team: SvTeamDetail | TeamWithSubscription): boolean => {
    // team.usage.hasOwnProperty("totalJpy")だとlint errorになるため
    // TODO とはいえここまでやってLint Error回避するなら、このerrorをdisabledにしたほうがよいのでは？
    return Object.prototype.hasOwnProperty.call(team.usage, "totalJpy");
  }

  const svUsage = usage as SvTeamUsage;

  return (
    <>
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        {/* t:対象期間 */}
        <Typography variant="h6" gutterBottom>{t("usage:latestUsage.period.label")}</Typography>
        <Grid container spacing={2}>
          {
            isStarter ?
            <>
              <Grid item xs={6}>
                {/* t:{{startDate}} 〜 */}
                <Typography variant="body1">{t('usage:latestUsage.period.from', {startDate: displayDate(term[0])})}</Typography>
              </Grid>
            </>
            :
            <>
              <Grid item xs={6}>
                {/* t:対象月 */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.period.month")}</Typography>
                <Typography variant="body1">{displayMonth(term[0])}</Typography>
              </Grid>
              <Grid item xs={6}>
                {/* t:期間 */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.period.range")}</Typography>
                {/* t:{{startDate}} 〜 {{endDate}} */}
                <Typography variant="body1">{t('usage:latestUsage.period.fromTo', {startDate: displayDate(term[0]), endDate: displayDate(term[1])})}</Typography>
              </Grid>
            </>
          }
        </Grid>
      </Paper>
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        { isStarter ?
          <>
            {/* t:投稿回数状況 */}
            <Typography variant="h6" gutterBottom>{t("usage:latestUsage.starter.title")}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* t:利用率 */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.starter.usageRate")}</Typography>
                <Typography variant="body1">{
                  quota.maxAiGenerateCount ?
                  (usage.aiGenerateCount / quota.maxAiGenerateCount * 100).toFixed(2) :
                  0.0
                }%</Typography>
              </Grid>
              <Grid item xs={6}>
                {/* t:残り回数 */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.starter.remainingCount")}</Typography>
                <Typography variant="body1">
                  { quota.maxAiGenerateCount ?
                    <>
                      {quota.maxAiGenerateCount - usage.aiGenerateCount}
                      &nbsp;&nbsp;／&nbsp;&nbsp;
                      {quota.maxAiGenerateCount}
                    </> :
                    <></>
                  }
                </Typography>
              </Grid>
            </Grid>
          </> :
          <>
            {/* t:クレジット利用状況 */}
            <Typography variant="h6" gutterBottom>{t("usage:latestUsage.paid.title")}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* t:利用率 */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.paid.usageRate")}</Typography>
                <Typography variant="body1">{removeDecimalPart(team.creditUsageRate, 3)}%</Typography>
              </Grid>
              <Grid item xs={6}>
                {/* t:残りクレジット */}
                <Typography variant="body2" color="textSecondary">{t("usage:latestUsage.paid.remainingCredit")}</Typography>
                <Typography variant="body1">
                  { getSpecialCreditLabel(t, team.creditRemaining) ||
                    <>
                      {removeDecimalPart(team.creditRemaining)}
                      &nbsp;&nbsp;／&nbsp;&nbsp;
                      {removeDecimalPart(quota.totalCredit)}
                    </>
                  }
                  </Typography>
              </Grid>
            </Grid>
          </>
        }
      </Paper>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3 }}>
            {/* t:利用回数 */}
            <Typography variant="h6" gutterBottom>{t("usage:statistics.usageCount.title")}</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    {/* t:スレッド作成回数 */}
                    <TableCell>{t("usage:statistics.usageCount.threadCreation")}</TableCell>
                    <TableCell align="right">{usage.threadNum}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* t:メッセージ投稿回数 */}
                    <TableCell>{t("usage:statistics.usageCount.messagePost")}</TableCell>
                    <TableCell align="right">{usage.postCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* t:AIからの回答回数 */}
                    <TableCell>{t("usage:statistics.usageCount.aiResponse")}</TableCell>
                    <TableCell align="right">{usage.aiGenerateCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3 }}>
            {/* t:入出力文字数 */}
            <Typography variant="h6" gutterBottom>{t("usage:statistics.characterCount.title")}</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    {/* t:合計値 */}
                    <TableCell>{t("usage:statistics.characterCount.total")}</TableCell>
                    <TableCell align="right">{usage.inputCharsToAi + usage.outputChars}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* t:生成AIに入力した文字数 */}
                    <TableCell>{t("usage:statistics.characterCount.input")}</TableCell>
                    <TableCell align="right">{usage.inputCharsToAi}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* t:生成AIが出力した文字数 */}
                    <TableCell>{t("usage:statistics.characterCount.output")}</TableCell>
                    <TableCell align="right">{usage.outputChars}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {!hideCreditInfo && <>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3 }}>
              {/* t:利用クレジット */}
              <Typography variant="h6" gutterBottom>{t("usage:statistics.creditUsage.title")}</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      {/* t:合計値 */}
                      <TableCell>{t("usage:statistics.creditUsage.total")}</TableCell>
                      <TableCell align="right">{
                        removeDecimalPart(usage.totalCredit, 3)
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* t:生成AIへの入力に使った値 */}
                      <TableCell>{t("usage:statistics.creditUsage.input")}</TableCell>
                      <TableCell align="right">{removeDecimalPart(usage.inputCredit, 3)}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* t:生成AIからの出力に使った値 */}
                      <TableCell>{t("usage:statistics.creditUsage.output")}</TableCell>
                      <TableCell align="right">{removeDecimalPart(usage.outputCredit, 3)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3 }}>
              {/* t:利用可能クレジット */}
              <Typography variant="h6" gutterBottom>{t("usage:statistics.availableCredit.title")}</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      {/* t:合計値 */}
                      <TableCell>{t("usage:statistics.availableCredit.total")}</TableCell>
                      <TableCell align="right">{
                        getSpecialCreditLabel(t, quota.totalCredit) ||
                        // t:{{credit}} / 月
                        t('usage:statistics.availableCredit.perMonth', {credit: removeDecimalPart(quota.totalCredit)})
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* t:1ライセンスあたりの利用可能クレジット */}
                      <TableCell>{t("usage:statistics.availableCredit.perLicense")}</TableCell>
                      <TableCell align="right">{
                        getSpecialCreditLabel(t, quota.licenseCredit) ||
                        // t:{{credit}} / 月
                        t('usage:statistics.availableCredit.perMonth', {credit: removeDecimalPart(quota.licenseCredit)})
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* t:ライセンス数 */}
                      <TableCell>{t("usage:statistics.availableCredit.licenseCount")}</TableCell>
                      <TableCell align="right">{quota.licenseAmount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </>}

        { isSvTeamDetail(team) && <>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                {/* t:原価(日本円) */}
                <Typography variant="h6" gutterBottom>{t("usage:statistics.costJpy.title")}</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        {/* t:合計金額 */}
                        <TableCell>{t("usage:statistics.costJpy.total")}</TableCell>
                        {/* t:{{amount}}円 */}
                        <TableCell align="right">{t('usage:statistics.costJpy.amount', {amount: removeDecimalPart(svUsage.totalJpy, 3)})}</TableCell>
                      </TableRow>
                      <TableRow>
                        {/* t:入力に使った金額 */}
                        <TableCell>{t("usage:statistics.costJpy.input")}</TableCell>
                        {/* t:{{amount}}円 */}
                        <TableCell align="right">{t('usage:statistics.costJpy.amount', {amount: removeDecimalPart(svUsage.inputJpy, 3)})}</TableCell>
                      </TableRow>
                      <TableRow>
                        {/* t:出力に使った金額 */}
                        <TableCell>{t("usage:statistics.costJpy.output")}</TableCell>
                        {/* t:{{amount}}円 */}
                        <TableCell align="right">{t('usage:statistics.costJpy.amount', {amount: removeDecimalPart(svUsage.outputJpy, 3)})}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                {/* t:原価(USドル) */}
                <Typography variant="h6" gutterBottom>{t("usage:statistics.costUsd.title")}</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        {/* t:合計金額 */}
                        <TableCell>{t("usage:statistics.costUsd.total")}</TableCell>
                        {/* t:{{amount}}ドル */}
                        <TableCell align="right">{t('usage:statistics.costUsd.amount', {amount: removeDecimalPart(svUsage.totalUsd, 3)})}</TableCell>
                      </TableRow>
                      <TableRow>
                        {/* t:入力に使った金額 */}
                        <TableCell>{t("usage:statistics.costUsd.input")}</TableCell>
                        {/* t:{{amount}}ドル */}
                        <TableCell align="right">{t('usage:statistics.costUsd.amount', {amount: removeDecimalPart(svUsage.inputUsd, 3)})}</TableCell>
                      </TableRow>
                      <TableRow>
                        {/* t:出力に使った金額 */}
                        <TableCell>{t("usage:statistics.costUsd.output")}</TableCell>
                        {/* t:{{amount}}ドル */}
                        <TableCell align="right">{t('usage:statistics.costUsd.amount', {amount: removeDecimalPart(svUsage.outputUsd, 3)})}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
};

export default SharedLatestUsageStatisticsPresenter;

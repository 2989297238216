import { Language } from "../setting/setting.constant";

export type QuickPromptSet = {
  id: string;
  officialCode?: string;
  name: string;
  icon?: string;
  language?: Language,
  createdAt: string;
  updatedAt: string;
}

export type QuickPrompt = {
  id: string;
  quickPromptSetId: string;
  officialCode?: string;
  name: string;
  description?: string;
  icon?: string;
  createdAt: string;
  updatedAt: string;
};

export type QuickPromptVersion = {
  id: string;
  formSchema: object;
  uiSchema: object;
  createdAt: string;
};

export type QuickPromptWithVersion = QuickPrompt & {
  latestVersion: QuickPromptVersion;
  targetVersion: QuickPromptVersion;
  latestInput?: object;
};

export type QuickPromptWithOptionalVersion = QuickPrompt & {
  latest_version?: QuickPromptVersion;
  target_version?: QuickPromptVersion;
  latestInput?: object;
};

export type QuickPromptWithSetAndVersion = QuickPromptWithVersion & {
  quickPromptSet: QuickPromptSet;
}

export type QuickPromptSetWithQpList = QuickPromptSet & {
  quickPrompts: QuickPromptWithOptionalVersion[];
}

export type QuickPromptVersionWithQP = QuickPromptVersion & {
  quickPrompt: QuickPrompt;
}

export type RecommendedQp = {
  qp: QuickPrompt;
  color: string;
  description: string;
}


export function getAllQuickPrompts(quickPromptSets: QuickPromptSetWithQpList[]): QuickPromptWithOptionalVersion[] {
  return quickPromptSets.flatMap(set => set.quickPrompts);
}

